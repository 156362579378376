<template>
  <div class="inline-flex flex-row gap-x-1 divide-x divide-gray-400">
    <state-span class="pl-1" v-for="state in states" :key="state" :label="state" />
  </div>
</template>

<script>
import StateSpan from "./StateSpan.vue";

export default {
  components: { StateSpan },
  props: {
    states: Array,
  },
};
</script>
