<template>
  <v-select
    class="selector"
    :options="importances"
    :value="value"
    :disabled="disabled"
    @input="(val) => $emit('input', val)"
    placeholder="Choose"
  >
    <template #option="{ label }">
      <span :style="{ color: getImprotanceColor(label) }">{{ label }}</span>
    </template>
    <template #selected-option="{ label }">
      <span :style="{ color: getImprotanceColor(label) }">{{ label }}</span>
    </template>
  </v-select>
</template>

<script>
import countryUtils from "../utils/countries";

export default {
  props: {
    value: String,
    disabled: Boolean,
    placeholder: String,
  },
  data() {
    return {
      importances: ["Primary", "Secondary", "Other"],
    };
  },
  methods: {
    getImprotanceColor(improtance) {
      return countryUtils.getCountryProfileColor(improtance);
    },
  },
};
</script>

<style></style>
