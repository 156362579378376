<template>
  <div class="w-full h-full y items-center justify-center gap-y-16" v-if="user">
    <img src="../assets/icons/no_permissions.svg" alt="" />
    <div class="y items-center text-center px-10">
      <h1 class="text-lg md:text-2xl font-semibold" :class="{ 'pb-5': canRequestPermissions }">
        You do not have permission to access this site.
      </h1>
      <button-primary
        v-if="canRequestPermissions"
        @click.native="requestPermissions"
        :loading="submitting"
        class="w-full md:w-1/2"
      >
        Request Permissions
      </button-primary>
      <p v-else class="text-xs md:text-base">
        Please wait patiently until your permission is being granted.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonPrimary from "../components/ButtonPrimary.vue";

export default {
  components: { ButtonPrimary },
  data() {
    return {
      submitting: false,
      requestSent: false,
    };
  },
  methods: {
    requestPermissions() {
      this.submitting = true;
      this.axios
        .post("permission-requests")
        .then(() => {
          this.requestSent = true;
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
  computed: {
    canRequestPermissions() {
      if (this.requestSent) return false;
      return this.user.can_request_permissions;
    },
    ...mapGetters(["user"]),
  },
};
</script>

<style></style>
