<template>
  <div class="rich-textarea">
    <div id="toolbar-container">
      <select class="ql-font">
        <option selected>EB Garamond</option>
        <option value="serif">Serif</option>
        <option value="monospace">Monospace</option>
      </select>

      <select class="ql-size">
        <option value="10px">Small</option>
        <option selected="selected">Normal</option>
        <option value="18px">Large</option>
        <option value="32px">Huge</option>
      </select>

      <button class="ql-bold"></button>
      <button class="ql-italic" value="italic"></button>
      <button class="ql-underline" value="underline"></button>
      <button class="ql-strike" value="strike"></button>

      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>

      <select class="ql-align">
        <option selected="selected"></option>
        <option value="center"></option>
        <option value="right"></option>
        <option value="justify"></option>
      </select>

      <button class="ql-link"></button>
      <button class="ql-image"></button>
    </div>
    <quill-editor :value="value" @input="(v) => $emit('input', v)" :options="quillConfig" />
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {
      quillConfig: {
        modules: {
          toolbar: "#toolbar-container",
        },
      },
    };
  },
};
</script>

<style>
.rich-textarea {
  @apply bg-white sm:text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-brand focus:border-brand shadow-sm disabled:bg-gray-100;
}

.ql-container {
  font-family: "EB Garamond", serif !important;
  @apply border-none pt-2 !important;
}

#toolbar-container {
  border: none;
  border-bottom: 1px solid #f0f0f0;
}

#toolbar-container .ql-font span[data-label="EB Garamond"]::before {
  font-family: "EB Garamond", serif !important;
}

#toolbar-container .ql-font span[data-label="Serif"]::before {
  font-family: serif;
}
#toolbar-container .ql-font span[data-label="Monospace"]::before {
  font-family: monospace;
}

#toolbar-container .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "Small";
  font-size: 10px !important;
}

#toolbar-container .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "Large";
  font-size: 18px !important;
}

#toolbar-container .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "Huge";
  font-size: 32px !important;
}
</style>
