<template>
  <thead class="overflow-y-visible">
    <!-- <tr v-if="!orderable"> -->
    <data-table-header-cell v-for="(title, index) in columns" v-bind:key="index" :title="title" />
    <slot />
    <!-- </tr>
    <tr v-else>
      <data-table-header-orderable-cell
        @click.native="order(column)"
        v-for="(column, index) in columns"
        v-bind:key="index"
        :title="column.title"
        :direction="getCellDirection(column)"
      />
      <slot />
    </tr> -->
  </thead>
</template>

<script lang="ts">
import DataTableHeaderCell from "./DataTableHeaderCell.vue";
// import DataTableHeaderOrderableCell from "./DataTableHeaderOrderableCell.vue";

export default {
  components: {
    DataTableHeaderCell,
    //  DataTableHeaderOrderableCell
  },
  props: {
    columns: {
      type: Array,
      // [{title: '', key: ''}] or [string]
      default: () => [],
    },
    orderable: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Object,
      default: () => ({
        column: null,
        direction: null,
      }),
    },
  },
  methods: {
    getCellDirection(column) {
      if (this.value.column === column.key) return this.value.direction;
      return null;
    },
    order(column) {
      if (!column.key) return;
      let orderBy = { ...this.value };
      if (this.value.column !== column.key) {
        orderBy = { column: column.key, direction: "asc" };
      } else if (this.value.direction === "asc") orderBy.direction = "desc";
      else {
        orderBy.column = null;
      }
      this.$emit("input", orderBy);
    },
  },
};
</script>

<style scoped>
thead th:first-child {
  padding-left: 20px !important;
  padding-right: 0;
}
</style>
