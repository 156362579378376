<template>
  <v-select
    class="selector"
    label="name"
    :options="countries"
    :reduce="(c) => c.id"
    :value="value"
    :disabled="disabled"
    @input="(val) => $emit('input', val)"
    :placeholder="placeholder"
  >
    <template #selected-option="{ name, profile }">
      <span :style="{ color: getCountryColor(profile) }">{{ name }}</span>
    </template>
    <template #option="{ name, profile }">
      <span :style="{ color: getCountryColor(profile) }">{{ name }}</span>
    </template>
  </v-select>
</template>

<script>
import { mapState } from "vuex";
import countryUtils from "../utils/countries";

export default {
  props: {
    value: Number,
    disabled: Boolean,
    placeholder: {
      type: String,
      default: () => "Choose",
    },
  },
  methods: {
    getCountryColor(profile) {
      return countryUtils.getCountryProfileColor(profile?.importance);
    },
  },
  computed: {
    ...mapState("data", ["countries"]),
  },
};
</script>

<style></style>
