import { serialize } from "object-to-formdata";
import axios from "axios";
import getListStore from "./list";

const listStore = getListStore("ads", {
  order_by: null,
  order_direction: null,
  search: null,
  search_scope: [],
  only_active: true,
});

const parseAdData = (ad) => {
  const parseIfObject = (i) => (typeof i === "object" ? i.name : i);
  const { cost, notes, advertiser, cities, state, positions } = ad;
  const data = {
    cost: cost && cost !== "" ? cost : 0,
    notes,
    advertiser: parseIfObject(advertiser),
    cities: !ad.country_id || ad.no_city ? [] : cities.map(parseIfObject),
    state,
    positions,
    start_date: ad.start_date,
    end_date: ad.end_date,
    last_checked_at: ad.last_checked_at,
    platform_id: ad.platform_id,
    currency_id: ad.currency_id,
    country_id: ad.country_id,
  };

  return data;
};

const store = {
  ...listStore,
  state: {
    ...listStore.state,
  },
  getters: {
    ...listStore.getters,
  },
  mutations: {
    ...listStore.mutations,
  },
  actions: {
    ...listStore.actions,
    async addAd({ dispatch }, ad) {
      const data = parseAdData(ad);
      console.log("data", data);
      return axios
        .post(
          `/ads`,
          serialize(data, {
            indices: true,
          }),
        )
        .then(() => {
          dispatch("fetchData", true);
        });
    },
    async updateAd({ dispatch }, ad, reload = false) {
      const data = parseAdData(ad);
      return axios
        .post(
          `/ads/${ad.id}?_method=PUT`,
          serialize(data, {
            indices: true,
          }),
        )
        .then(() => {
          if (reload) dispatch("fetchData", true);
        });
    },
  },
};

export default store;
