<template>
  <main>
    <div class="y w-full gap-y-5 px-0 md:px-24">
      <div class="relative w-full y md:x gap-x-3 items-end justify-end">
        <div class="w-4/12 md:static absolute top-0 left-0 pb-0 md:pb-5 x gap-x-5 items-center">
          <router-link :to="{ name: 'RecruitmentManagerMap' }">
            <i class="isax isax-global text-gray-400 text-3xl cursor-pointer" title="Map view" />
          </router-link>

          <checkbox-input
            :label="'Show only active candidates'"
            :checked="filters.only_active"
            @change="() => (filters.only_active = !filters.only_active)"
          />
        </div>
        <div class="w-full md:w-6/12 pt-3">
          <field-item :label="''" class="justify-self-end">
            <template #input>
              <div class="x border rounded-md bg-white items-center pr-3">
                <input
                  type="text"
                  v-debounce:200="(val) => searchChanged(val)"
                  :value="showAdvanceFilters ? '' : filters.search"
                  :placeholder="showAdvanceFilters ? 'Filter everywhere' : 'Search'"
                  class="shadow-none flex-1 border-none focus:outline-none"
                />
                <div class="x gap-x-2">
                  <button class="text-xs text-gray-400" @click="toggleAdvanceFilters">
                    {{ showAdvanceFilters ? "Clear advance filters" : "Advance filters" }}
                  </button>
                  <i class="isax isax-search-normal text-xl text-gray-400" />
                </div>
              </div>
            </template>
          </field-item>
        </div>
        <div class="w-3/12 x justify-end md:static absolute top-0 right-0 pb-0 md:pb-3">
          <button-primary
            @click.native="
              showAddModal = true;
              showingCopies = false;
              ignoreCopies = false;
            "
            class="h-8 w-28"
            v-if="$can('hr-add-recruits')"
            >Add new</button-primary
          >
        </div>
      </div>

      <data-table class="mt-2 table-fixed">
        <template #header>
          <selectable-columns
            v-if="showedColumns.length > 0"
            :showedColumns="showedColumns"
            :allColumns="allColumns"
            :setShowedColumns="setShowedColumns"
            :searchColumns="filters.search_scope"
            @updateSearchColumns="(columns) => (filters.search_scope = columns)"
            :orderBy="filters.order_by"
            :orderDirection="filters.order_direction"
            @updateOrder="
              (o) => {
                filters.order_by = o.orderBy;
                filters.order_direction = o.orderDirection;
              }
            "
            @updateShowedColumns="(val) => (showedColumns = val)"
          />
        </template>

        <template #after-header>
          <filter-columns
            v-if="showAdvanceFilters"
            ref="filterColumns"
            :showedColumns="showedColumns"
            :allColumns="allColumns"
            @filter="setSearchFilters"
          />
        </template>

        <template #body>
          <tr v-for="recruit in recruits" :key="recruit.id" class="hover:bg-white cursor-pointer">
            <data-table-cell v-for="column in showedColumns" :key="column" class="bg-transparent">
              <router-link
                class="text-sm"
                v-if="['ID', 'Name'].includes(column)"
                :to="{ name: 'Profile', params: { id: recruit.id } }"
                :style="{ color: column === 'Name' ? getRecruitColor(recruit) : '' }"
                :is="canUpdateRecruit(recruit) || canViewRecruit(recruit) ? 'router-link' : 'span'"
              >
                <p
                  :class="{
                    'hover:font-bold cursor-pointer whitespace-nowrap w-52': column === 'Name',
                  }"
                >
                  {{ getData(recruit, column) }}
                </p>
              </router-link>
              <v-select
                class="inside-table-selector selector"
                v-else-if="allColumns[column] == 'positions'"
                label="name"
                :placeholder="positionPlaceholder(recruit)"
                :options="
                  addingPosition
                    ? positions.filter((p) => !recruitHavePosition(recruit, p))
                    : recruit.positions
                "
                :reduce="(el) => (addingPosition ? el : recruit.positions.indexOf(el))"
                :value="recruit.selected_position"
                @input="
                  (val) =>
                    addingPosition ? createPosition(recruit, val) : selectPosition(recruit, val)
                "
                @close="addingPosition = false"
                :taggable="addingPosition"
              >
                <template #option="{ name }">
                  <div class="x justify-between">
                    <h3>
                      {{ name }}
                    </h3>
                  </div>
                </template>

                <template #list-footer>
                  <div class="w-full x items-center justify-center">
                    <button-primary
                      @click.native="addingPosition = !addingPosition"
                      v-if="canUpdateRecruit(recruit)"
                    >
                      {{ addingPosition ? "Cancel" : "Add" }}
                    </button-primary>
                  </div>
                </template>
              </v-select>
              <state-selector
                class="inside-table-selector selector"
                v-else-if="allColumns[column] == 'positions.states'"
                :value="getData(recruit, column)"
                :disabled="
                  !canUpdateRecruit(recruit) ||
                  (recruit.selected_position !== 0 &&
                    !recruit.selected_position &&
                    recruit.positions.length !== 1)
                "
                @input="(val) => updateRecuritPosition(recruit, val)"
              />
              <v-select
                class="inside-table-selector selector"
                v-else-if="
                  ['residence_country.name', 'origin_country.name'].indexOf(allColumns[column]) > -1
                "
                label="name"
                placeholder="None"
                :options="countries"
                :reduce="(company) => company.id"
                :value="
                  allColumns[column] === 'residence_country.name'
                    ? recruit.residence_country_id
                    : recruit.origin_country_id
                "
                @input="(ev) => setData(recruit, column, ev)"
                :clearable="false"
                :disabled="!canUpdateRecruit(recruit)"
              />
              <v-select
                class="inside-table-selector selector"
                v-else-if="allColumns[column] == 'gender'"
                label="name"
                placeholder="None"
                :options="genders"
                :reduce="(company) => company.value"
                :value="getData(recruit, column)"
                @input="(ev) => setData(recruit, column, ev)"
                :clearable="false"
                :disabled="!canUpdateRecruit(recruit)"
              />
              <v-select
                class="inside-table-selector selector"
                v-else-if="
                  [
                    'employment_type.name',
                    'platform.name',
                    'education.name',
                    'experience.name',
                    'contact_holder.name',
                    'ad.name',
                  ].includes(allColumns[column])
                "
                placeholder="None"
                label="name"
                :reduce="(i) => i.id"
                :options="
                  allColumns[column] === 'employment_type.name'
                    ? employmentTypes
                    : allColumns[column] === 'platform.name'
                    ? platforms
                    : allColumns[column] === 'education.name'
                    ? education
                    : allColumns[column] === 'experience.name'
                    ? experiences
                    : allColumns[column] === 'contact_holder.name'
                    ? contactHolders
                    : ads
                "
                :value="
                  allColumns[column] === 'employment_type.name'
                    ? recruit.employment_type_id
                    : allColumns[column] === 'platform.name'
                    ? recruit.platform_id
                    : allColumns[column] === 'education.name'
                    ? recruit.education_id
                    : allColumns[column] === 'experience.name'
                    ? recruit.experience_id
                    : allColumns[column] === 'contact_holder.name'
                    ? recruit.contact_holder_id
                    : recruit.ad_id
                "
                @input="(ev) => setData(recruit, column, ev)"
                :clearable="false"
                :disabled="!canUpdateRecruit(recruit)"
              />
              <v-select
                v-else-if="allColumns[column] == 'stacks'"
                placeholder="Choose"
                :options="stacks"
                :taggable="true"
                label="name"
                class="inside-table-selector selector md:flex-1"
                multiple
                :value="recruit.stacks"
                :closeOnSelect="false"
                @input="(val) => setData(recruit, column, val)"
                :disabled="!canUpdateRecruit(recruit)"
              />
              <v-select
                v-else-if="allColumns[column] == 'languages'"
                placeholder="Choose"
                :options="languages"
                label="name"
                class="inside-table-selector selector md:flex-1"
                multiple
                :value="recruit.languages"
                :redcue="(i) => i.id"
                :closeOnSelect="false"
                @input="(val) => setData(recruit, column, val)"
                :disabled="!canUpdateRecruit(recruit)"
              />
              <p v-else-if="allColumns[column] == 'desired_salary'">
                <input
                  :value="getData(recruit, column)"
                  @change="(ev) => setData(recruit, column, ev.target.value)"
                  type="number"
                  min="1"
                  max="99"
                  class="py-1 border-none text-right"
                  style="padding-right: 0 !important"
                  :disabled="!canUpdateRecruit(recruit)"
                />
                <span v-if="recruit.desired_salary">
                  {{
                    recruit.currency && (recruit.currency.code || recruit.currency.symbol)
                      ? recruit.currency.symbol
                        ? recruit.currency.symbol
                        : recruit.currency.code
                      : "€"
                  }}
                </span>
              </p>

              <date-picker
                v-else-if="['start_on', 'application_date'].includes(allColumns[column])"
                :value="getData(recruit, column)"
                @input="(ev) => setData(recruit, column, ev)"
                :clearable="false"
                valueType="format"
                format="YYYY-MM-DD"
                class="w-full table-date-picker"
                :disabled="!canUpdateRecruit(recruit)"
              />

              <date-picker
                v-else-if="allColumns[column] == 'updated_at'"
                :clearable="false"
                type="datetime"
                valueType="format"
                format="YYYY-MM-DD HH:mm"
                class="w-full table-date-picker"
                :value="getData(recruit, column)"
                @input="(ev) => setData(recruit, column, ev)"
                :disabled="!canUpdateRecruit(recruit)"
              />

              <input
                v-else
                :value="getData(recruit, column)"
                @change="(ev) => setData(recruit, column, ev.target.value)"
                :type="
                  ['age', 'week_capacity'].indexOf(allColumns[column]) > -1 ? 'number' : 'text'
                "
                :min="allColumns[column] === 'age' ? 18 : 1"
                :max="allColumns[column] === 'age' ? 100 : 99"
                class="py-1 border-none"
                :disabled="!canUpdateRecruit(recruit)"
              />
            </data-table-cell>
            <data-table-cell>
              <i
                v-if="canUpdateRecruit(recruit)"
                @click="confirmDeleteRecurit(recruit)"
                class="isax isax-trash pl-2 cursor-pointer"
                title="delete"
              />
            </data-table-cell>
          </tr>
        </template>
      </data-table>
      <infinite-loader
        :disabled="settings.reachedEnd || showingCopies"
        :loading="settings.loading"
        @loadMore="fetchData"
      />
    </div>
    <transition name="popup">
      <alert-merge
        v-if="copies.length > 0 && !ignoreCopies"
        class="absolute z-tooltip bottom-0 md:bottom-5 right-0 md:right-5"
        :number="copies.length"
        :hideIngore="!showingCopies"
        :hideShow="showingCopies"
        @show="showingCopies = true"
        @ignore="
          showingCopies = false;
          ignoreCopies = true;
        "
      />
    </transition>
    <transition name="popup">
      <add-new-recruit v-if="showAddModal" v-show="!showingCopies" @close="showAddModal = false" />
    </transition>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import FieldItem from "../components/FieldItem.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import DataTable from "../components/DataTable.vue";
import DataTableCell from "../components/DataTableCell.vue";
import AddNewRecruit from "../components/AddNewRecruit.vue";
import alerts from "../utils/alerts";
import utils from "../utils/utils";
import InfiniteLoader from "../components/InfiniteLoader.vue";
import AlertMerge from "../components/AlertMerge.vue";
import SelectableColumns from "../components/SelectableColumns.vue";
import CheckboxInput from "../components/CheckboxInput.vue";
import "vue2-datepicker/index.css";
import StateSelector from "../components/StateSelector.vue";
import FilterColumns from "../components/FilterColumns.vue";

export default {
  components: {
    FieldItem,
    ButtonPrimary,
    DataTable,
    DataTableCell,
    AddNewRecruit,
    InfiniteLoader,
    AlertMerge,
    SelectableColumns,
    CheckboxInput,
    DatePicker,
    StateSelector,
    FilterColumns,
  },
  data() {
    return {
      columns: [
        "ID",
        "Name",
        "Phone",
        "Job Title",
        "Other Information",
        "Creation Date",
        "Age",
        "Actions",
        "Platform",
      ],
      showAddModal: false,
      genders: [
        {
          name: "Male",
          value: "m",
        },
        {
          name: "Female",
          value: "f",
        },
      ],
      showingCopies: false,
      ignoreCopies: false,
      addingPosition: false,
      showAdvanceFilters: false,
    };
  },
  mounted() {
    this.fetchData(true);
    this.fetchShowedColumns();
    if (this.countries.length < 1) this.fetchCountries();
    if (this.currencies.length < 1) this.fetchCurrencies();
    if (this.languages.length < 1) this.fetchLanguages();
    if (this.education.length < 1) this.fetchEducation();
    if (this.platforms.length < 1) this.fetchPlatforms();
    if (this.experiences.length < 1) this.fetchExperiences();
    if (this.stacks.length < 1) this.fetchStacks();
    if (this.employmentTypes.length < 1) this.fetchEmploymentTypes();
    if (this.recuritStates.length < 1) this.fetchRecuritStates();
    this.fetchPositions();
    if (this.contactHolders.length < 1) this.fetchContactHolders();
    if (this.ads.length < 1) this.fetchAds();
  },
  methods: {
    getData(recruit, column) {
      if (column === "ID") return recruit.id;
      if (column === "Gender") return recruit.gender === "m" ? "Male" : "Female";
      const postionColumns = this.allColumns[column].split(".");
      if (postionColumns[0] === "positions") {
        if (postionColumns.length === 1) return recruit.positions;
        return this.getFromKey(
          recruit,
          `positions.${
            recruit.selected_position >= 1 ? recruit.selected_position : 0
          }.pivot.${postionColumns.slice(1).join(".")}`,
        );
      }
      return this.getFromKey(recruit, this.allColumns[column]);
    },
    setData(recruit, column, val) {
      if (column === "ID") return;
      if (this.allColumns[column]) {
        const postionColumns = this.allColumns[column].split(".");
        if (postionColumns[0] === "positions") {
          this.setFromKey(
            recruit,
            `positions.${
              recruit.selected_position >= 1 ? recruit.selected_position : 0
            }.pivot.${postionColumns.slice(1).join(".")}`,
            val,
          );
        } else {
          let col = this.allColumns[column] ?? column;

          const colWithObject = col.split(".");
          if (colWithObject.length === 2) {
            col = `${colWithObject[0]}_id`;
          }

          this.setFromKey(recruit, col, val);
        }
        this.updateRecurit(recruit);
      }
    },
    getFromKey(data, key) {
      return this.fromKey(data, key);
    },
    setFromKey(data, key, val) {
      this.fromKey(data, key, val);
    },
    fromKey(data, key, set = null) {
      return utils.fromKey(data, key, set);
    },
    createPosition(recurit, val) {
      recurit.positions.push(recurit.positions.length > 0 ? val : { name: val });
      this.selectPosition(recurit, recurit.positions.length - 1);
      this.updateRecurit(recurit);
    },
    async deletePosition(recurit, id) {
      const confirmed = await alerts.showConfirm({
        title: "Delete Position",
        message: "Are you sure you want to delete this position?",
      });
      if (!confirmed) return;
      const index = recurit.positions.findIndex((p) => p.id === id);
      recurit.positions.splice(index, 1);
      if (recurit.positions.length > 0)
        this.selectPosition(recurit, index === 0 ? index + 1 : index - 1);
      this.recruits = JSON.parse(JSON.stringify(this.recruits));
    },
    selectPosition(recruit, position) {
      Object.assign(recruit, {
        selected_position: position,
      });
      this.recruits = JSON.parse(JSON.stringify(this.recruits));
    },
    positionPlaceholder(recurit) {
      if (recurit.positions.length === 0) return "Add Position";
      let placeholder = recurit.positions[0].name;
      if (recurit.positions.length > 1) placeholder += `, (+${recurit.positions.length - 1})`;
      return placeholder;
    },
    updateRecurit(recruit) {
      const { name, age, gender, notes, referral, stacks, languages, email, phone } = recruit;
      this.axios
        .put(`/recruits/${recruit.id}`, {
          name,
          age,
          application_date: recruit.application_date,
          currency_id: recruit.currency_id,
          desired_salary: recruit.desired_salary,
          education_id: recruit.education_id,
          employment_type_id: recruit.employment_type_id,
          experience_id: recruit.experience_id,
          origin_country_id: recruit.origin_country_id,
          residence_country_id: recruit.residence_country_id,
          gender,
          notes,
          referral,
          email,
          phone,
          stacks: stacks.map((i) => (typeof i === "object" ? i.name : i)),
          languages: languages.map((i) => i.id),
          platform_id: recruit.platform_id,
          start_on: recruit.start_on,
          week_capacity: recruit.week_capacity,
          positions: recruit.positions.map((p) => ({
            name: typeof p === "object" ? p.name : p,
            states: p.pivot.states,
          })),
          contact_holder_id: recruit.contact_holder_id,
          ad_id: recruit.ad_id,
        })
        .then((resp) => {
          const rec = resp.data.data;
          rec.selected_position = recruit.selected_position;
          const idx = this.recruits.findIndex((r) => rec.id === r.id);
          this.recruits[idx] = rec;
          this.recruits = JSON.parse(JSON.stringify(this.recruits));
        });
    },
    updateRecuritPosition(recruit, states) {
      const position =
        recruit.positions[recruit.positions.length === 1 ? 0 : recruit.selected_position];
      this.axios
        .put(`/recruits/${recruit.id}/positions/${position.id}/states`, {
          states,
        })
        .then(() => {
          position.pivot.states = states;
          this.recruits = JSON.parse(JSON.stringify(this.recruits));
        });
    },
    async confirmDeleteRecurit(recruit) {
      const confirmed = await alerts.showConfirm({
        title: "Delete Recruit",
        message: "Are you sure you want to delete this recruit?",
      });
      if (!confirmed) return;

      this.deleteRecurit(recruit).then(() => {
        alerts.showMessage("Recruit deleted successfully");
      });
    },
    toggleAdvanceFilters() {
      this.filters.search = null;
      this.filters.filters = [];
      this.showAdvanceFilters = !this.showAdvanceFilters;
    },
    searchChanged(value) {
      if (this.showAdvanceFilters) {
        this.$refs.filterColumns.setFilter("anywhere", value);
      } else {
        this.filters.search = value;
      }
    },
    setSearchFilters(filters) {
      this.filters.filters = Object.keys(filters).map((key) => ({
        key,
        search: filters[key],
      }));
    },
    recruitHavePosition(recruit, position) {
      return recruit.positions.some((p) => {
        return p.name === position;
      });
    },
    canUpdateRecruit(recruit) {
      if (recruit.user_id === this.user.id) return this.canUpdateOwn;
      return this.canUpdateOthers;
    },
    canViewRecruit(recruit) {
      return this.canView || recruit.user_id === this.user.id;
    },
    getRecruitColor(recruit) {
      if (!recruit.origin_country && !recruit.residence_country) return "#333333";

      const country = recruit.origin_country ?? recruit.residence_country;
      if (!country.profile) return "#333333";

      switch (country.profile.importance) {
        case "Primary":
          return "#ff6700";
        case "Secondary":
          return "#117C00";
        case "Other":
          return "#5A83D5";
        default:
          return "#bbbbbb";
      }
    },
    ...mapActions(["deleteRecurit"]),
    ...mapActions("recruits", ["fetchData"]),
    ...mapActions("recruitmentManagerColumns", ["fetchShowedColumns"]),
    ...mapMutations("recruitmentManagerColumns", ["setShowedColumns"]),
    ...mapActions("data", [
      "fetchRecuritStates",
      "fetchCountries",
      "fetchPositions",
      "fetchLanguages",
      "fetchEducation",
      "fetchPlatforms",
      "fetchExperiences",
      "fetchStacks",
      "fetchEmploymentTypes",
      "fetchCurrencies",
      "fetchContactHolders",
      "fetchAds",
    ]),
  },
  computed: {
    showedColumns: {
      get() {
        return this.$store.state.recruitmentManagerColumns.showedColumns;
      },
      set(val) {
        this.setShowedColumns(val);
      },
    },
    recruits: {
      get() {
        if (this.showingCopies) return this.copies;
        return this.$store.getters["recruits/data"];
      },
      set(val) {
        this.$store.commit("recruits/setData", val);
      },
    },
    canUpdateOwn() {
      return this.$store.getters["auth/can"]("hr-update-own-recruits");
    },
    canUpdateOthers() {
      return this.$store.getters["auth/can"]("hr-update-others-recruits");
    },
    canView() {
      return this.$store.getters["auth/can"]("hr-view-recruits");
    },
    ...mapGetters(["user"]),
    ...mapGetters("recruits", ["filters", "settings", "copies"]),
    ...mapGetters("recruitmentManagerColumns", ["allColumns"]),
    ...mapState("data", [
      "recuritStates",
      "countries",
      "positions",
      "currencies",
      "languages",
      "education",
      "platforms",
      "experiences",
      "stacks",
      "employmentTypes",
      "contactHolders",
      "ads",
    ]),
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        if (Object.keys(val).length > 0) this.fetchData(true);
      },
    },
  },
};
</script>

<style>
.inside-table-selector {
  box-shadow: none !important;
  background: transparent !important;
}
.inside-table-selector .vs__search,
.table-selector .vs__search {
  @apply border-none px-3 py-1;
}
.inside-table-selector .vs__dropdown-toggle,
.table-selector .vs__dropdown-toggle {
  @apply border-none;
}
.inside-table-selector .vs__dropdown-toggle,
.inside-table-selector .vs__search {
  background: transparent !important;
}
.table-selector .vs__search {
  opacity: 0;
}
.table-selector .vs__dropdown-menu {
  margin-top: 5px;
}
.inside-table-selector .vs__search {
  font-size: 14px;
}
.inside-table-selector .vs__actions,
.table-date-picker .mx-icon-calendar {
  opacity: 0;
}
.table-date-picker input {
  border: none;
  box-shadow: none;
}

.selector .vs__selected-options .state-selected-item {
  white-space: nowrap;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selector .vs__selected-options .state-selected-item:not(:first-child) span {
  border-left: 0.5px solid #333333;
  padding: 0 5px;
}

.selected-for-search {
  --vs-selected-color: #ff6700;
}

table tr th:not(:first-child) {
  @apply border-b-2;
  border-bottom-width: 3px;
}
table tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}
table input {
  background: transparent !important;
}
</style>
