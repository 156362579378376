<template>
  <div class="y md:x gap-x-4 gap-y-4 mt-2 rounded-md">
    <div class="y gap-y-2 py-3 md:w-4/12 lg:w-3/12 2xl:w-2/12">
      <div
        v-for="(tab, index) in tabs"
        v-bind:key="'t-' + index"
        @click="changeTab(tab)"
        class="x gap-x-2 px-4 py-2 sm:pl-3 items-center text-gray-400 cursor-pointer hover:opacity-80 transition-all"
        :class="{
          'font-bold text-brand bg-white rounded-md': tab.key === value,
          'text-red-500': tabHasError(tab),
        }"
      >
        <slot name="tab" :tab="tab">
          <i v-if="tab.icon" class="isax" :class="tab.icon" />
          <span :class="{ 'text-brand': tab.key === value, 'text-red-500': tabHasError(tab) }">
            {{ tab.label }}
          </span>
          <error-icon v-if="tabHasError(tab)" />
        </slot>
      </div>
    </div>

    <div class="tab-body bg-white flex-1 y rounded-md shadow-sm">
      <div class="flex-1 p-4">
        <slot />
      </div>
      <div class="w-full x py-2 px-4 justify-end gap-x-2">
        <slot name="buttons" />
      </div>
    </div>
  </div>
</template>

<script>
import ErrorIcon from "./ErrorIcon.vue";

export default {
  components: { ErrorIcon },
  props: {
    tabs: {
      type: Array,
      // [{label: '', key: '', icon: ''}]
      default: () => [],
    },
    value: {
      type: String,
      default: () => null,
    },
  },
  methods: {
    changeTab(tab) {
      this.$emit("input", tab.key);
    },
    tabHasError(tab) {
      return tab.isError && tab.isError();
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style>
@media only screen and (min-width: 768px) {
  .tab-body {
    min-height: 80vh;
  }
}
body {
  @apply bg-gray-100 !important;
}
</style>
