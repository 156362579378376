<template>
  <data-table-header>
    <draggable v-model="columns" group="list" :filter="'#id-column'" tag="tr" :move="onDragMove">
      <data-table-header-cell colspan="1" v-for="(column, index) in showedColumns" :key="column">
        <div v-if="column == 'ID'" id="id-column" class="text-center pb-4">
          <icon-index class="inline-block" />
        </div>
        <div
          v-else
          class="x gap-x-2 items-center relative bg-white mb-4"
          style="box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16)"
        >
          <div
            class="absolute left-5 w-1/2 h-full right-28 z-dropdown cursor-pointer"
            @click="selectForSearch(allColumns[showedColumns[index]])"
          ></div>
          <icon-drag class="cursor-pointer absolute z-dropdown left-3" />
          <sort-icons
            class="cursor-pointer absolute right-8 z-fixed"
            :direction="orderBy == allColumns[showedColumns[index]] ? orderDirection : null"
            @sort="(direction) => setSort(allColumns[showedColumns[index]])"
          />
          <v-select
            :clearable="false"
            v-model="showedColumns[index]"
            @input="setShowedColumns(showedColumns)"
            :options="getColumnOptions(showedColumns[index])"
            class="selector table-selector flex-1"
            :class="{
              'selected-for-search': selectedForSearch(allColumns[showedColumns[index]]),
            }"
          >
            <template #open-indicator="{ attributes }">
              <div class="x gap-x-2 items-center">
                <span class="cursor-pointer" v-bind="attributes">
                  <icon-select width="12" height="6" />
                </span>
              </div>
            </template>
          </v-select>
        </div>
      </data-table-header-cell>
    </draggable>
  </data-table-header>
</template>

<script>
import draggable from "vuedraggable";
import IconDrag from "../assets/icons/IconDrag.vue";
import IconIndex from "../assets/icons/IconIndex.vue";
import IconSelect from "../assets/icons/IconSelect.vue";
import DataTableHeader from "./DataTableHeader.vue";
import DataTableHeaderCell from "./DataTableHeaderCell.vue";
import SortIcons from "./SortIcons.vue";

export default {
  components: {
    draggable,
    DataTableHeader,
    DataTableHeaderCell,
    IconIndex,
    IconDrag,
    SortIcons,
    IconSelect,
  },
  props: {
    showedColumns: [Array, Object],
    allColumns: [Array, Object],
    searchColumns: {
      type: Array,
      default: () => [],
    },
    setShowedColumns: Function,
    orderBy: String,
    orderDirection: String,
    isSelectable: Function,
  },
  data() {
    return {
      columns: [],
    };
  },
  mounted() {
    this.columns = this.showedColumns;
  },
  methods: {
    getColumnOptions(column) {
      return Object.keys(this.allColumns).filter(
        (c) => c !== "ID" && (!this.showedColumns.includes(c) || c === column),
      );
    },
    onDragMove(e) {
      return e.related.childNodes[0].id !== "id-column";
    },
    selectedForSearch(column) {
      const idx = this.searchColumns.indexOf(column);
      return idx !== -1;
    },
    selectForSearch(column) {
      if (this.isSelectable && !this.isSelectable(column)) return;
      const idx = this.searchColumns.indexOf(column);
      const columns = [...this.searchColumns];
      if (idx === -1) {
        columns.push(column);
      } else {
        columns.splice(idx, 1);
      }
      this.$emit("updateSearchColumns", columns);
    },
    setSort(column) {
      let { orderBy, orderDirection } = this;

      if (orderBy !== column) {
        orderBy = column;
        orderDirection = "asc";
      } else if (orderDirection === "asc") orderDirection = "desc";
      else {
        orderBy = null;
        orderDirection = null;
      }

      this.$emit("updateOrder", {
        orderBy,
        orderDirection,
      });
    },
  },
  watch: {
    columns(val) {
      if (val.length > 0) {
        this.$emit("updateShowedColumns", val);
      }
    },
  },
};
</script>

<style></style>
