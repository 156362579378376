<template>
  <div>
    <b>{{ log.user.name }}</b> changed position <b>{{ log.position }}</b>
    state from
    <state-list v-if="log.from.length > 0" class="p-1 bg-gray-100 rounded-lg" :states="log.from" />
    <span v-else>None</span>
    to
    <state-list v-if="log.to.length > 0" class="p-1 bg-gray-100 rounded-lg" :states="log.to" />
    <span v-else>None</span> on <b>{{ log.created_at }}</b
    >.
  </div>
</template>

<script>
import StateList from "./StateList.vue";

export default {
  components: {
    StateList,
  },
  props: {
    log: Object,
    // user: Object,
    // position: String,
    // from: Array,
    // to: Array,
    // created_at: String,
  },
};
</script>
