<template>
  <button
    class="px-3 py-2 text-sm rounded-md border capitalize bg-transparent disabled:bg-opacity-70 hover:bg-white hover:text-orange"
    :style="{ color }"
  >
    <slot />
  </button>
</template>

<script lang="ts">
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>
