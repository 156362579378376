import axios from "axios";
import getListStore from "./list";

const listStore = getListStore("countries/scout", {
  order_by: null,
  order_direction: null,
  search: null,
  search_scope: [],
});

const store = {
  ...listStore,
  state: {
    ...listStore.state,
  },
  getters: {
    ...listStore.getters,
  },
  mutations: {
    ...listStore.mutations,
  },
  actions: {
    ...listStore.actions,
    async updateCountry({ dispatch }, { country, reload = false }) {
      const { id, importance, cities, recruiters } = country;
      return axios
        .put(`/countries/${id}`, {
          importance,
          cities,
          recruiters,
        })
        .then(() => {
          if (reload) dispatch("fetchData", true);
        });
    },
    deleteCountry({ dispatch }, country) {
      return axios.delete(`/countries/${country.id}`).then(() => {
        dispatch("fetchData", true);
      });
    },
  },
};

export default store;
