const getCountryProfileColor = (importance) => {
  switch (importance) {
    case "Primary":
      return "#ff6700";
    case "Secondary":
      return "#117C00";
    case "Other":
      return "#5A83D5";
    default:
      return "#bbbbbb";
  }
};

export default {
  getCountryProfileColor,
};
