<template>
  <main>
    <div class="y w-full gap-y-5 px-0 md:px-24">
      <div class="relative w-full y md:x gap-x-3 items-end justify-end">
        <div class="w-3/12 md:static absolute top-0 left-0 pb-0 md:pb-5"></div>
        <div class="w-full md:w-6/12 pt-3">
          <field-item :label="''" class="justify-self-end">
            <template #input>
              <div class="x border rounded-md bg-white items-center pr-3">
                <input
                  type="text"
                  v-debounce:200="(val) => (filters.search = val)"
                  :placeholder="'Search'"
                  class="shadow-none flex-1 border-none focus:outline-none"
                />
                <i class="isax isax-search-normal text-xl text-gray-400" />
              </div>
            </template>
          </field-item>
        </div>
        <div class="w-3/12 x justify-end md:static absolute top-0 right-0 pb-0 md:pb-3">
          <button-primary class="h-8 w-28" @click.native="showAddInvitation = true"
            >Invite</button-primary
          >
        </div>
      </div>

      <data-table class="mt-2 table-fixed">
        <template #header>
          <selectable-columns
            :showedColumns="showedColumns"
            :allColumns="allColumns"
            :setShowedColumns="setShowedColumns"
            :searchColumns="filters.search_scope"
            @updateSearchColumns="(columns) => (filters.search_scope = columns)"
            :orderBy="filters.order_by"
            :orderDirection="filters.order_direction"
            @updateOrder="
              (o) => {
                filters.order_by = o.orderBy;
                filters.order_direction = o.orderDirection;
              }
            "
            @updateShowedColumns="(val) => (showedColumns = val)"
            :isSelectable="(col) => !permissionsColumns.includes(col)"
          />
        </template>

        <template #body>
          <tr
            v-for="user in [...invitations, ...permissions]"
            :key="isInvitation ? '-' + user.id : user.id"
            class="hover:bg-white cursor-pointer"
          >
            <data-table-cell
              v-for="column in showedColumns"
              :key="column"
              class="bg-transparent"
              :style="{
                color: !isEditable(user) ? '#bbbbbb' : '',
              }"
            >
              <router-link
                class="text-sm"
                v-if="column === 'ID' || column === 'Name'"
                :to="{
                  name: isInvitation(user) ? 'PermissionInvitation' : 'PermissionProfile',
                  params: { id: user.id },
                }"
                :class="{
                  'hover:font-bold cursor-pointer whitespace-nowrap w-52': column === 'Name',
                }"
                >{{ getData(user, column) }}
                <div
                  v-if="column === 'Name' && isInvitation(user)"
                  class="ml-1 rounded-lg px-1 py-0.5 text-xs inline-block bg-orange w-min text-white"
                >
                  invitation
                </div>
              </router-link>
              <toggle-button
                v-else-if="permissionsColumns.includes(allColumns[column])"
                :value="getData(user, column)"
                @input="() => setData(user, column, !getData(user, column))"
                class="py-2"
                :disabled="!isEditable(user)"
              />
              <date-picker
                v-else-if="['created_at', 'updated_at'].includes(allColumns[column])"
                :value="getData(user, column)"
                @input="(val) => setData(user, column, val)"
                type="datetime"
                valueType="format"
                format="YYYY-MM-DD HH:mm"
                :clearable="false"
                class="w-full table-date-picker"
                :disabled="!isEditable(user)"
              />
              <input
                v-else
                :value="getData(user, column)"
                @change="(ev) => setData(user, column, ev.target.value)"
                class="py-1 border-none"
                :disabled="!isEditable(user)"
              />
            </data-table-cell>
            <data-table-cell>
              <i
                @click="confirmDelete(user)"
                class="isax isax-trash pl-2 cursor-pointer"
                title="delete"
              />
            </data-table-cell>
          </tr>
        </template>
      </data-table>
      <infinite-loader
        :disabled="settings.reachedEnd"
        :loading="settings.loading"
        @loadMore="fetchData"
      />
    </div>
    <transition name="popup">
      <invitation-add-new
        v-if="showAddInvitation"
        @close="showAddInvitation = false"
        class="absolute z-fixed bottom-5 right-5"
      />
    </transition>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DatePicker from "vue2-datepicker";
import FieldItem from "../components/FieldItem.vue";
import DataTable from "../components/DataTable.vue";
import DataTableCell from "../components/DataTableCell.vue";
import InfiniteLoader from "../components/InfiniteLoader.vue";
import SelectableColumns from "../components/SelectableColumns.vue";
import utils from "../utils/utils";
import alerts from "../utils/alerts";
import { isEditable } from "../utils/permissionsManager";
import ToggleButton from "../components/ToggleButton.vue";
import "vue2-datepicker/index.css";
import InvitationAddNew from "../components/InvitationAddNew.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";

export default {
  components: {
    FieldItem,
    DataTable,
    DataTableCell,
    InfiniteLoader,
    SelectableColumns,
    ToggleButton,
    DatePicker,
    InvitationAddNew,
    ButtonPrimary,
  },
  data() {
    return {
      orderBy: null,
      orderDirection: null,
      searchScope: [],
      showAddInvitation: false,
    };
  },
  mounted() {
    this.fetchData(true);
    this.fetchInvitations();
    this.fetchShowedColumns();
    if (this.abilities.length < 1 || this.roles.length < 1) {
      this.fetchPermissions();
    }
  },
  methods: {
    getData(user, column) {
      if (column === "ID") return user.id;
      return this.getFromKey(user, this.allColumns[column]);
    },
    setData(user, column, value) {
      if (column === "ID") return;
      this.setFromKey(user, this.allColumns[column], value);
      console.log("column", column);
      console.log("value", value);
      console.log("user", user);

      if (this.isInvitation(user)) {
        console.log("user", user);
        this.updateInvitations({ invitation: user }).then((res) => {
          const { invitation } = res.data.data;
          const idx = this.invitations.findIndex((u) => u.id === user.id);
          if (idx > -1) {
            this.invitations[idx].updated_at = invitation.updated_at;
          }
        });
      } else if (this.permissionsColumns.includes(this.allColumns[column])) {
        this.updateUserPermissions({ user });
      } else {
        this.updateUser({ user }).then((res) => {
          const usr = res.data.data;
          const idx = this.permissions.findIndex((u) => u.id === user.id);
          if (idx > -1) {
            this.permissions[idx].updated_at = usr.updated_at;
          }
        });
      }
    },
    async confirmDelete(user) {
      const label = this.isInvitation(user) ? "invitation" : "profile";
      const confirmed = await alerts.showConfirm({
        title: "Delete User",
        message: `Are you sure you want to delete this ${label}?`,
      });
      if (!confirmed) return;

      if (this.isInvitation(user)) {
        this.deleteInvitations({ invitation: user });
      } else {
        this.deleteUser({ user }).then(() => {
          this.fetchData(true);
        });
      }
    },
    getFromKey(data, key) {
      return this.fromKey(data, key);
    },
    setFromKey(data, key, val) {
      this.fromKey(data, key, val);
    },
    fromKey(data, key, set = null) {
      return utils.fromKey(data, key, set);
    },
    isEditable(user) {
      return isEditable(user);
    },
    isInvitation(user) {
      return user.is_invitation;
    },
    ...mapActions("permissions", [
      "fetchData",
      "updateUserPermissions",
      "updateUser",
      "parseUser",
      "deleteUser",
    ]),
    ...mapActions("invitations", {
      fetchInvitations: "fetchData",
      updateInvitations: "update",
      deleteInvitations: "delete",
    }),
    ...mapActions("data", ["fetchPermissions"]),
    ...mapActions("permissionsColumns", ["fetchShowedColumns"]),
    ...mapMutations("permissionsColumns", ["setShowedColumns"]),
  },
  computed: {
    permissions: {
      get() {
        return this.$store.state.permissions.data;
      },
    },
    showedColumns: {
      get() {
        return this.$store.state.permissionsColumns.showedColumns;
      },
      set(val) {
        this.setShowedColumns(val);
      },
    },
    ...mapGetters("permissionsColumns", ["allColumns"]),
    ...mapGetters("permissions", ["filters", "settings", "permissionsColumns"]),
    ...mapGetters("invitations", {
      invitations: "data",
    }),
    ...mapGetters("data", ["abilities", "roles"]),
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        if (Object.keys(val).length > 0) this.fetchData(true);
      },
    },
  },
};
</script>

<style></style>
