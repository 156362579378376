<template>
  <main>
    <div class="w-full md:px-40 pt-5 y gap-y-5 items-center">
      <div class="w-full x gap-x-5 items-center">
        <div class="w-max x items-center text-gray-400">
          <router-link :to="{ name: 'Ads' }" class="pt-0.5">
            <i
              class="isax isax-arrow-left-3 cursor-pointer text-sm 2xl:text-2xl sm:text-xl hover:text-brand mr-2"
            />
          </router-link>
          <span class="whitespace-nowrap text-xs 2xl:text-xl sm:text-base">
            Ad Manager - Profile
          </span>
        </div>
        <div class="flex-1 bg-gray-200" style="height: 1.5px"></div>
      </div>

      <div class="w-full" v-if="!loading">
        <ad-form ref="form" v-model="item" :edit="true" style="overflow-y: hidden !important" />
        <div class="x w-full items-end justify-end gap-x-4">
          <button-primary @click.native="submit" class="md:w-24">Save</button-primary>
          <button-secondary class="text-xs md:w-24 bg-white" :color="'#000'"
            >Cancel</button-secondary
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import alerts from "../utils/alerts";
import AdForm from "../components/AdForm.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import ButtonSecondary from "../components/ButtonSecondary.vue";

export default {
  components: { AdForm, ButtonPrimary, ButtonSecondary },
  data() {
    const item = {
      start_date: null,
      end_date: null,
      cost: 0,
      recruits_count: 0,
      last_checked_at: null,
      notes: null,
      platform_id: null,
      advertiser: null,
      cities: [],
      currency_id: null,
      country_id: null,
      state: null,
      positions: [],
      no_city: false,
    };

    return {
      item,
      errors: {},
      validator: null,
      loading: true,
      submitting: false,
    };
  },
  mounted() {
    this.fetchAd();
  },
  methods: {
    fetchAd() {
      return this.axios
        .get(`/ads/${this.$route.params.id}`)
        .then((resp) => {
          this.item = resp.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.go(-1);
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      this.submitting = true;
      this.updateAd(this.item).finally(() => {
        this.submitting = false;
      });
    },
    async confirmDeleteRecurit() {
      const confirmed = await alerts.showConfirm({
        title: "Delete Ad",
        message: "Are you sure you want to delete this ad?",
      });
      if (!confirmed) return;

      this.deleteRecurit(this.item).then(() => {
        this.back();
      });
    },
    // ...mapActions(["deleteRecurit"]),
    ...mapActions("ads", ["fetchData", "updateAd"]),
  },
};
</script>

<style></style>
