<template>
  <div class="mx-auto w-full py-4 px-6 flex-1 y gap-y-2 overflow-y-scroll">
    <div class="y xl:x gap-x-6">
      <field-item :label="'Advertiser'" :required="true">
        <template #input>
          <validated-select :errors="errors ? errors['advertiser'] : []">
            <v-select
              placeholder="Choose"
              :options="advertisers"
              :taggable="true"
              class="selector md:flex-1"
              label="name"
              :value="value.advertiser"
              :closeOnSelect="false"
              @input="(val) => update('advertiser', val)"
            />
          </validated-select>
        </template>
      </field-item>

      <field-item :label="'Country'">
        <template #input>
          <v-select
            label="name"
            placeholder="Choose"
            :options="countries"
            :reduce="(company) => company.id"
            :value="value.country_id"
            @input="(val) => update('country_id', val)"
            class="selector md:flex-1"
          />
        </template>
      </field-item>
    </div>

    <div class="y xl:x gap-x-6">
      <field-item :label="'Platform'" :required="true">
        <template #input>
          <validated-select :errors="errors ? errors['platform_id'] : []">
            <v-select
              placeholder="Choose"
              :options="platforms"
              :taggable="true"
              class="selector md:flex-1"
              label="name"
              :reduce="(i) => i.id"
              :value="value.platform_id"
              :closeOnSelect="false"
              @input="(val) => update('platform_id', val)"
            />
          </validated-select>
        </template>
      </field-item>

      <div class="y gap-y-1 flex-1 mb-2">
        <field-item :label="'City'">
          <template #input>
            <v-select
              placeholder="Choose"
              :options="cities"
              :taggable="true"
              class="selector md:flex-1"
              label="name"
              multiple
              :value="value.cities"
              :closeOnSelect="false"
              @input="(val) => update('cities', val)"
              :disabled="value.country_id === null || value.no_city"
            />
          </template>
        </field-item>

        <checkbox-input
          :checked="value.no_city"
          @change="(val) => update('no_city', !value.no_city)"
          :label="'City / area is not specified for this ad'"
        />
      </div>
    </div>

    <div class="y xl:x gap-x-6">
      <div class="y xl:x md:flex-1 gap-x-4">
        <field-item :label="'Positions'" :required="true">
          <template #input>
            <validated-select :errors="errors ? errors['positions'] : []">
              <v-select
                :options="positions"
                label="name"
                class="selector md:flex-1"
                :value="value.positions"
                :closeOnSelect="false"
                :taggable="true"
                multiple
                @input="(val) => update('positions', val)"
              />
            </validated-select>
          </template>
        </field-item>
      </div>

      <div class="y xl:x gap-x-6 flex-1">
        <field-item :label="'Start'">
          <template #input>
            <date-picker
              :clearable="true"
              valueType="format"
              format="YYYY-MM-DD"
              class="w-full disabled:bg-gray-100"
              :value="value.start_date"
              @input="(val) => update('start_date', val)"
            ></date-picker>
          </template>
        </field-item>

        <field-item :label="'End'">
          <template #input>
            <date-picker
              :clearable="true"
              valueType="format"
              format="YYYY-MM-DD"
              class="w-full disabled:bg-gray-100"
              :value="value.end_date"
              @input="(val) => update('end_date', val)"
            ></date-picker>
          </template>
        </field-item>
      </div>
    </div>

    <div class="y xl:x gap-x-6">
      <div class="y xl:x gap-x-6 flex-1">
        <field-item :label="'Cost'">
          <template #input>
            <validated-input
              :errors="errors ? errors['cost'] : []"
              class="md:flex-1"
              :type="'number'"
              :min="0"
              :value="value.cost"
              @input="(val) => update('cost', val)"
            />
          </template>
        </field-item>

        <field-item :label="'Currency'">
          <template #input>
            <v-select
              label="name"
              placeholder="Choose"
              :options="currencies"
              :reduce="(c) => c.id"
              class="selector md:flex-1"
              :value="value.currency_id"
              @input="(val) => update('currency_id', val)"
            />
          </template>
        </field-item>
      </div>

      <div class="y xl:x gap-x-6 flex-1">
        <field-item :label="'Applicants'" v-if="edit">
          <template #input>
            <validated-input
              class="md:flex-1"
              :type="'number'"
              :min="0"
              :value="value.recruits_count"
              :disabled="true"
            />
          </template>
        </field-item>

        <field-item :label="'Last cheked'">
          <template #input>
            <date-picker
              :clearable="true"
              valueType="format"
              format="YYYY-MM-DD"
              class="w-full disabled:bg-gray-100"
              :value="value.last_checked_at"
              @input="(val) => update('last_checked_at', val)"
            ></date-picker>
          </template>
        </field-item>
      </div>
    </div>

    <field-item :label="'Notes'">
      <template #input>
        <validated-input
          :isTextarea="true"
          :value="value.notes"
          @input="(val) => update('notes', val)"
        />
      </template>
    </field-item>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import * as Validator from "validatorjs";
import FieldItem from "./FieldItem.vue";
import ValidatedInput from "./ValidatedInput.vue";
import CheckboxInput from "./CheckboxInput.vue";
import "vue2-datepicker/index.css";
import ValidatedSelect from "./ValidatedSelect.vue";

export default {
  props: {
    value: {
      Object,
      default: () => ({}),
    },
    errors: {
      Object,
      default: () => ({}),
    },
    edit: Boolean,
  },
  data() {
    return {
      validator: null,
      previewed: null,
      previewedFile: null,
      loadingFile: false,
      selectedPosition: null,
    };
  },
  async mounted() {
    if (this.platforms.length < 1) {
      this.fetchPlatforms();
    }
    this.fetchCurrencies();
    if (this.countries.length < 1) {
      this.fetchCountries();
    }
    if (this.cities.length < 1) {
      this.fetchCities();
    }
    if (this.advertisers.length < 1) {
      this.fetchAdvertisers();
    }
    if (this.adStates.length < 1) {
      this.fetchAdStates();
    }
    this.fetchPositions();
  },
  components: {
    DatePicker,
    FieldItem,
    ValidatedInput,
    CheckboxInput,
    ValidatedSelect,
  },
  methods: {
    preventEmpty(e) {
      if (e.target.value === "") {
        e.target.value = 0;
      }
    },
    async fetchCurrencies() {
      if (this.currencies.length < 1) await this.$store.dispatch("data/fetchCurrencies");
      return new Promise((resolve) => {
        if (!this.value.currency_id) {
          const euroId = this.currencies.find((c) => c.code === "EUR")?.id;
          this.update("currency_id", euroId);
        }
        resolve();
      });
    },
    update(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    async validate() {
      this.validator = new Validator(this.value, {
        advertiser: "required",
        platform_id: "required",
        positions: "required|array",
      });
      this.validator.setAttributeNames({ platform_id: "platform" });

      if (this.validator.passes()) {
        this.errors = null;
      } else this.errors = this.validator.errors.all();

      return this.validator.passes();
    },
    ...mapActions("data", [
      "fetchPlatforms",
      "fetchCountries",
      "fetchAdStates",
      "fetchCities",
      "fetchAdvertisers",
      "fetchPositions",
    ]),
  },
  computed: {
    ...mapState("data", [
      "platforms",
      "currencies",
      "countries",
      "adStates",
      "cities",
      "advertisers",
      "positions",
    ]),
  },
};
</script>

<style></style>
