<template>
  <div
    class="py-1 pl-2 text-xs pr-5 md:text-sm shadow-md rounded-md hover:bg-opacity-80 cursor-pointer transition-colors"
    :class="[active ? 'bg-orange text-white' : 'bg-white hover:bg-gray-100']"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
  },
};
</script>

<style></style>
