<template>
  <svg :width="width" :height="height" :id="id"></svg>
</template>

<script>
import * as d3 from "d3";
import { geoMercator, geoPath } from "d3-geo";

const getCountryISO3 = require("country-iso-2-to-3");

export default {
  components: {},
  props: {
    code: String,
    width: {
      type: Number,
      default: () => 250,
    },
    height: {
      type: Number,
      default: () => 250,
    },
    color: String,
  },
  mounted() {
    const svg = d3.select(`#${this.id}`);

    d3.json(
      "https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson",
    ).then((data) => {
      // eslint-disable-next-line
      const json = data.features.find((d) => {
        return d.id === getCountryISO3(this.code);
      });

      const { width, height } = this;
      const projection = geoMercator().fitSize([width, height], json);
      const path = geoPath().projection(projection);

      svg
        .selectAll("path")
        .data([json])
        .enter()
        .append("path")
        .attr("d", path)
        .style("fill", this.color);
    });
  },
  computed: {
    id() {
      return `map-${this.code}`;
    },
  },
};
</script>

<style></style>
