<template>
  <div class="py-5 y gap-y-2">
    <div
      class="relative h-52 w-full y divide-y gap-y-1 bg-white border rounded-md overflow-y-scroll px-4 py-2"
      :class="[firstTimeLoading ? 'overflow-y-hidden' : 'overflow-y-scroll']"
    >
      <state-log class="pt-1" v-for="(log, index) of data" :key="index" :log="log" />
      <infinite-loader
        :disabled="settings.reachedEnd"
        :loading="settings.loading"
        @loadMore="fetchData"
      />
      <div
        v-if="firstTimeLoading"
        class="w-full h-full inset-0 absolute bg-black bg-opacity-70 y items-center justify-center"
      >
        <i class="isax isax-refresh rotate text-xl text-white" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StateLog from "./StateLog.vue";
import InfiniteLoader from "./InfiniteLoader.vue";

export default {
  components: {
    StateLog,
    InfiniteLoader,
  },
  props: {
    recruit: Object,
  },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      this.filters.recruit_id = this.recruit.id;
      this.fetchData(true);
    },
    ...mapActions("recruitStatesLogs", ["fetchData"]),
  },
  computed: {
    firstTimeLoading() {
      return this.settings.loading && this.settings.page === 1;
    },
    ...mapGetters("recruitStatesLogs", ["data", "settings", "filters"]),
  },
};
</script>
