<template>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    class="hover:opacity-60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 33C25.8366 33 33 25.8366 33 17C33 8.16344 25.8366 1 17 1C8.16344 1 1 8.16344 1 17C1 25.8366 8.16344 33 17 33Z"
      stroke="#EB671B"
      stroke-miterlimit="10"
      :style="{ stroke: active ? '#EB671B' : '#D4D4D4' }"
    />
    <path
      d="M21.6168 25.9308C21.3236 25.7225 21.0608 25.4743 20.836 25.1935C20.5142 24.8042 20.4769 24.3499 20.4914 23.8802C20.4958 23.7373 20.637 23.7687 20.7229 23.7585C21.3398 23.6848 21.9547 23.5949 22.5629 23.4717C23.3198 23.3187 24.0681 23.1307 24.7724 22.8015C25.0471 22.6732 25.299 22.5123 25.5042 22.2847C25.7333 22.0301 25.7424 21.8672 25.5313 21.6008C25.2648 21.2649 25.1781 20.8588 25.0996 20.4562C24.9654 19.7891 24.9278 19.1064 24.9113 18.4283C24.885 17.3501 24.9023 16.2702 24.894 15.1919C24.8803 13.4262 24.8446 11.6604 24.5083 9.9198C24.3364 9.03065 24.0767 8.16858 23.6203 7.37635C22.9054 6.13287 21.833 5.45012 20.4067 5.31906C20.3677 5.31285 20.3284 5.30957 20.289 5.30925C19.8699 5.33554 19.5254 5.17309 19.1581 4.97454C18.4455 4.59 17.6592 4.4562 16.843 4.52408C15.9555 4.5919 15.0829 4.79023 14.2533 5.11266C12.8995 5.64631 11.8495 6.54252 11.0851 7.77894C10.3133 9.02791 9.95544 10.4138 9.73413 11.8445C9.55599 12.9961 9.48065 14.156 9.45279 15.3202C9.42454 16.4766 9.39746 17.6353 9.33507 18.7885C9.29152 19.5702 9.23462 20.3581 8.94582 21.1001C8.86028 21.3206 8.76611 21.536 8.61033 21.7173C8.51733 21.826 8.5142 21.9382 8.57109 22.0634C8.6633 22.2671 8.81594 22.4166 8.99605 22.5402C9.4159 22.8297 9.89069 22.9989 10.3718 23.1488C11.3429 23.4513 12.3435 23.6118 13.35 23.7326C13.5603 23.7577 13.5768 23.8476 13.5787 24.0238C13.5897 24.9039 13.0847 25.4682 12.4212 25.9296C11.6757 26.4483 10.8422 26.7984 10.001 27.1272C9.05098 27.4988 8.10219 27.8704 7.18871 28.3275C6.8854 28.4793 6.59699 28.6547 6.31055 28.8337C9.22963 31.5131 13.0481 32.9992 17.0104 32.998C20.9728 32.9969 24.7903 31.5085 27.7078 28.8274C26.6825 28.1811 25.565 27.7287 24.4416 27.2877C23.463 26.907 22.4863 26.53 21.6168 25.9308Z"
      :style="{ fill: active ? '#EB671B' : '#D4D4D4' }"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: Boolean,
  },
};
</script>

<style></style>
