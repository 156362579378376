<template>
  <main>
    <div class="w-full md:px-40 pt-5 y gap-y-5 items-center">
      <div class="w-full y gap-y-2">
        <div class="w-full x gap-x-5 items-center">
          <div class="w-max x items-center text-gray-400">
            <router-link :to="{ name: 'Home' }" class="pt-0.5">
              <i
                class="isax isax-arrow-left-3 cursor-pointer text-sm 2xl:text-2xl sm:text-xl hover:text-brand mr-2"
              />
            </router-link>
            <span class="whitespace-nowrap text-xs 2xl:text-xl sm:text-base">
              Recruitment Manager - {{ item ? item.name : "Profile" }}
            </span>
          </div>
          <div class="flex-1 bg-gray-200" style="height: 1.5px"></div>
        </div>
        <div class="x justify-end text-xs md:text-base" v-if="item.user">
          Added by: {{ item.user.name }}
        </div>
      </div>

      <div class="w-full">
        <form-tabs v-model="selectedTab" :tabs="tabs">
          <div class="w-full h-full y pt-20 items-center" v-if="loading">
            <Loading />
          </div>

          <div v-else>
            <recruit-form
              ref="form"
              v-model="item"
              :edit="true"
              style="overflow-y: hidden !important"
              v-if="!loading"
              v-show="selectedTab === 'base'"
            >
              <template #before-attachments> </template>
            </recruit-form>

            <recruit-states-logs
              v-if="selectedTab === 'logs'"
              :recruit="item"
              ref="recruitStatesLogs"
            />

            <email-sender
              class="px-6 py-4"
              v-if="selectedTab === 'send_email'"
              ref="emailForm"
              :loading="sendingEmail"
              v-model="emailData"
              @send="sendEmail"
            />

            <recruit-attachments
              :edit="true"
              v-if="selectedTab === 'attachments'"
              v-model="item.attachments"
            />
          </div>

          <template #buttons>
            <div class="y w-full px-8 md:px-0 gap-y-3 md:x justify-between py-3" v-if="!loading">
              <div>
                <button-secondary
                  @click.native="confirmDeleteRecurit"
                  class="bg-white"
                  :color="'#F12B2C'"
                  v-if="havePermission && selectedTab === 'base'"
                  >Delete Profile</button-secondary
                >
              </div>
              <div class="x gap-x-3">
                <button-primary
                  @click.native="submit"
                  :loading="submitting"
                  class="flex-1 md:w-32"
                  v-if="havePermission"
                  v-show="['base', 'attachments'].includes(selectedTab)"
                >
                  <span class="text-xs md:text-sm"> Save changes </span>
                </button-primary>

                <button-primary
                  @click.native="sendEmail"
                  :loading="sendingEmail"
                  class="flex-1 md:w-32"
                  v-show="selectedTab == 'send_email'"
                >
                  <span class="text-xs md:text-sm"> Send Email </span>
                </button-primary>

                <button-secondary
                  @click.native="back"
                  class="flex-1 text-xs md:w-32 bg-white"
                  :color="'#000'"
                  ><span class="text-xs md:text-sm">cancel</span></button-secondary
                >
              </div>
            </div>
          </template>
        </form-tabs>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { serialize } from "object-to-formdata";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import ButtonSecondary from "../components/ButtonSecondary.vue";
import RecruitForm from "../components/RecruitForm.vue";
import alerts from "../utils/alerts";
import EmailSender from "../components/EmailSender.vue";
import RecruitStatesLogs from "../components/RecruitStatesLogs.vue";
import FormTabs from "../components/FormTabs.vue";
import RecruitAttachments from "../components/RecruitAttachments.vue";
import Loading from "../components/Loading.vue";

export default {
  components: {
    RecruitForm,
    ButtonPrimary,
    ButtonSecondary,
    EmailSender,
    RecruitStatesLogs,
    FormTabs,
    RecruitAttachments,
    Loading,
  },
  data() {
    const item = {
      gender: true,
      position: {},
      positions: [],
      platform: null,
      stacks: [],
      experience: null,
      desired_salary: null,
      referral: null,
      education: null,
      week_capacity: null,
      employment_type: null,
      languages: null,
      notes: null,
      start_on: null,
      application_date: null,
      currency_id: null,
      experience_id: null,
      education_id: null,
      platform_id: null,
      attachments: [],
      haveAge: true,
      residenceCountrySameAsOrigin: false,
    };

    return {
      item,
      errors: {},
      validator: null,
      loading: true,
      submitting: false,
      emailData: {
        email: "",
        cc: "",
        subject: "",
        message: "",
        attachments: [],
      },
      sendingEmail: false,
      selectedTab: "base",
      tabs: [],
    };
  },
  mounted() {
    this.fetchRecruit();
  },
  methods: {
    fetchRecruit() {
      return this.axios
        .get(`/recruits/${this.$route.params.id}`)
        .then((resp) => {
          this.item = resp.data;
          Object.assign(this.item, {
            gender: this.item.gender === "m",
            haveAge: this.item.age !== null,
            residenceCountrySameAsOrigin: this.item.residence_same_as_origin,
            positions: this.item.positions.map((p) => p.name),
            states: new Map(this.item.positions.map((p) => [p.name, p.pivot.states])),
            stacks: this.item.stacks.map((s) => s.name),
          });
          this.emailData.email = this.item.email;
        })
        .finally(() => {
          this.loading = false;
          this.setTabs();
        });
    },
    setTabs() {
      this.tabs.push(
        ...[
          {
            label: "Base Information",
            key: "base",
            icon: "isax-info-circle",
          },
          {
            label: "Send E-mail",
            key: "send_email",
            icon: "isax-sms",
          },
          {
            label: "Logs",
            key: "logs",
            icon: "isax-note",
          },
          {
            label: "Attachments",
            key: "attachments",
            icon: "isax-document-copy",
          },
        ],
      );

      if (!this.$can("hr-send-email")) {
        this.tabs.splice(1, 1);
      }
    },
    back() {
      this.$router.go(-1);
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      const attachments = this.item.attachments.map((attachment) => ({
        id: attachment.id,
        size: attachment.pivot ? attachment.pivot.size : attachment.size,
      }));
      const data = {
        ...this.item,
        age: this.item.haveAge ? this.item.age : null,
        gender: this.item.gender ? "m" : "f",
        residence_country_id: this.item.residenceCountrySameAsOrigin
          ? this.item.origin_country_id
          : this.item.residence_country_id,
        residenceCountrySameAsOrigin: this.item.residenceCountrySameAsOrigin ? 1 : 0,
        positions: this.item.positions.map((p) => ({
          name: p,
          states: this.item.states.get(p),
        })),
        languages: this.item.languages.map((l) => (typeof l === "object" ? l.id : l)),
        attachments,
      };

      this.submitting = true;
      this.axios
        .post(
          `/recruits/${data.id}?_method=PUT`,
          serialize(data, {
            booleansAsIntegers: true,
            indices: true,
          }),
        )
        .then((resp) => {
          const item = resp.data.data;
          this.item.updated_at = item.updated_at;
          this.item.attachments = item.attachments;
          this.item.deleted_attachments = [];
          this.fetchData(true);
          this.$refs.recruitStatesLogs.reset();
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    async confirmDeleteRecurit() {
      const confirmed = await alerts.showConfirm({
        title: "Delete Recruit",
        message: "Are you sure you want to delete this recruit?",
      });
      if (!confirmed) return;

      this.deleteRecurit(this.item).then(() => {
        this.back();
        alerts.showMessage("Recruit deleted successfully");
      });
    },
    async sendEmail() {
      const isValid = await this.$refs.emailForm.validate();
      if (!isValid) return;
      this.sendingEmail = true;

      const isFile = (e) => e instanceof File;
      const attachments = this.emailData.attachments.filter(isFile);
      const savedAttachments = this.emailData.attachments
        .filter((f) => !isFile(f))
        .map((a) => a.id);

      this.axios
        .post(
          "/email",
          serialize(
            {
              ...this.emailData,
              attachments,
              saved_attachments: savedAttachments,
            },
            {
              booleansAsIntegers: true,
              indices: true,
            },
          ),
        )
        .then(() => {
          Object.assign(this.emailData, {
            email: "",
            cc: "",
            subject: "",
            message: "",
            attachments: [],
          });
        })
        .finally(() => {
          this.sendingEmail = false;
        });
    },
    filesSelected(files) {
      this.attachments.push(...files);
    },
    basicInfoHaveError() {
      return this.$refs.form?.validate();
    },
    ...mapActions(["deleteRecurit"]),
    ...mapActions("recruits", ["fetchData"]),
  },
  computed: {
    canUpdateOwn() {
      return this.$store.getters["auth/can"]("hr-update-own-recruits");
    },
    canUpdateOthers() {
      return this.$store.getters["auth/can"]("hr-update-others-recruits");
    },
    havePermission() {
      if (this.item.user_id === this.user.id) return this.canUpdateOwn;
      return this.canUpdateOthers;
    },
    ...mapGetters(["user"]),
  },
};
</script>

<style></style>
