import Vue from "vue";
import VueRouter from "vue-router";
import { has } from "lodash";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import RecruitmentManagerMap from "../views/RecruitmentManagerMap.vue";
import Scout from "../views/Scout.vue";
import ScoutMap from "../views/ScoutMap.vue";
import ScoutCountryProfile from "../views/ScoutCountryProfile.vue";
import Profile from "../views/Profile.vue";
import Ads from "../views/Ads.vue";
import AdsMap from "../views/AdsMap.vue";
import AdProfile from "../views/AdProfile.vue";
import PermissionsManager from "../views/PermissionsManager.vue";
import PermissionProfile from "../views/PermissionProfile.vue";
import EmailTemplates from "../views/EmailTemplates.vue";
import EmailTemplateProfile from "../views/EmailTemplateProfile.vue";
import NoPermissions from "../views/NoPermissions.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      roles: ["hr"],
    },
  },
  {
    path: "/recruitment-manager-map",
    name: "RecruitmentManagerMap",
    component: RecruitmentManagerMap,
    meta: {
      requiresAuth: true,
      roles: ["hr"],
    },
  },
  {
    path: "/scout",
    name: "Scout",
    component: Scout,
    meta: {
      requiresAuth: true,
      roles: ["scout"],
    },
  },
  {
    path: "/scout-map",
    name: "ScoutMap",
    component: ScoutMap,
    meta: {
      requiresAuth: true,
      roles: ["scout"],
    },
  },
  {
    path: "/scout/country-profile/:id",
    name: "ScoutCountryProfile",
    component: ScoutCountryProfile,
    meta: {
      requiresAuth: true,
      props: true,
      roles: ["scout"],
    },
  },
  {
    path: "/profile/:id",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      props: true,
      roles: ["hr"],
    },
  },
  {
    path: "/ads",
    name: "Ads",
    component: Ads,
    meta: {
      requiresAuth: true,
      roles: ["ads_manager"],
    },
  },
  {
    path: "/ads-map",
    name: "AdsMap",
    component: AdsMap,
    meta: {
      requiresAuth: true,
      roles: ["ads_manager"],
    },
  },
  {
    path: "/ads/profile/:id",
    name: "AdProfile",
    component: AdProfile,
    meta: {
      requiresAuth: true,
      props: true,
      roles: ["ads_manager"],
    },
  },
  {
    path: "/permissions",
    name: "PermissionsManager",
    component: PermissionsManager,
    meta: {
      requiresAuth: true,
      props: true,
      roles: ["permission_manager"],
    },
  },
  {
    path: "/permissions/profile/:id",
    name: "PermissionProfile",
    component: PermissionProfile,
    meta: {
      requiresAuth: true,
      props: true,
      roles: ["permission_manager"],
    },
  },
  {
    path: "/permissions/invitation/:id",
    name: "PermissionInvitation",
    component: PermissionProfile,
    meta: {
      requiresAuth: true,
      props: true,
      roles: ["permission_manager"],
    },
  },
  {
    path: "/email-templates",
    name: "EmailTemplates",
    component: EmailTemplates,
    meta: {
      requiresAuth: true,
      props: true,
      permissions: "hr-create-email-template",
    },
  },
  {
    path: "/email-templates/profile/:id",
    name: "EmailTemplateProfile",
    component: EmailTemplateProfile,
    meta: {
      requiresAuth: true,
      props: true,
      permissions: "hr-create-email-template",
    },
  },
  {
    path: "/no-permission",
    name: "NoPermissions",
    component: NoPermissions,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const haveNoAccess = () => {
    next({ name: "NoPermissions", replace: true });
  };

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next("/auth/login");
      return;
    }

    store.dispatch("fetchSyncedUser").then(() => {
      const bouncer = store.getters["auth/bouncer"];

      if (has(to.meta, "permissions") || has(to.meta, "roles")) {
        if (typeof to.meta.permissions === "function") {
          if (!to.meta.permissions(bouncer, to, from)) {
            haveNoAccess();
          }
        } else if (
          (has(to.meta, "permissions") && bouncer.cannot(to.meta.permissions)) ||
          (has(to.meta, "roles") && bouncer.isNotA(to.meta.roles))
        ) {
          haveNoAccess();
        }
      }
    });
  }

  next();
});

export default router;
