import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";
import vSelect from "vue-select";
import vueDebounce from "vue-debounce";
import { ObserveVisibility } from "vue-observe-visibility";
import VueMq from "vue-mq";
import VueQuillEditor from "vue-quill-editor";
import Quill from "quill";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "vue-select/dist/vue-select.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "./index.css";
import alerts from "./utils/alerts";
import filters from "./utils/filters";
import QuillConfigure from "./quill/configure";

store.dispatch("fetchToken");

Vue.component("v-select", vSelect);

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
    document.body.addEventListener("touchstart", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
    document.body.removeEventListener("touchstart", el.clickOutsideEvent);
  },
  stopProp(event) {
    event.stopPropagation();
  },
});
Vue.directive("observe-visibility", ObserveVisibility);

Vue.filter("getTimeAgo", filters.getTimeAgo);
Vue.filter("parseTimeToHuman", filters.parseTimeToHuman);
Vue.filter("formatDate", filters.formatDate);
Vue.filter("titleCase", filters.titleCase);

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API_URL;
axios.defaults.headers.common = {
  Authorization: `Bearer ${store.getters.token}`,
  Accept: "Application/json",
};
axios.interceptors.response.use(
  (response) => {
    if (response.data.status && response.data.status === "success" && response.data.message)
      alerts.showMessage(response.data.message);
    if (response.data.status && response.data.status === "error") {
      alerts.showError(response);
      return Promise.reject(response);
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      // handle your errors here.
      alerts.showError(error.response);
      if (error.response.status === 401 && error.response.data.message) {
        if (
          error.response.data.message === "Gitlab access token expired" ||
          error.response.data.message === "Unauthenticated."
        ) {
          const url = error.request.responseURL;
          if (url.substring(url.lastIndexOf("/") + 1) !== "logout") {
            // will logout
            store.dispatch("logout");
            router.push("/auth/login");
          }
        }
      }
    }
    return Promise.reject(error);
  },
);

store.dispatch("fetchUserInfo");

Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.use(vueDebounce);

Vue.use(VueMq, {
  breakpoints: {
    sm: 639,
    md: 767,
    lg: 1023,
    xl: 1279,
  },
});

QuillConfigure(Quill);
Vue.use(VueQuillEditor);

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  keyName: "head",
});

Vue.mixin({
  computed: {
    ...Vuex.mapState("auth", {
      $user: "user",
    }),

    ...Vuex.mapGetters("auth", {
      $can: "can",
      $cannot: "cannot",
      $isA: "isA",
      $isNotA: "isNotA",
    }),
  },
  methods: {
    ...Vuex.mapActions("storage", {
      $uploadFile: "uploadFile",
      $finishFile: "finishFile",
      $uploadAndFinishFile: "uploadAndFinishFile",
      $abortFile: "abortFile",
      $downloadFile: "downloadFile",
    }),
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
