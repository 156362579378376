<template>
  <div class="relative flex items-center h-8">
    <div class="flex relative items-center h-5 w-5 p-0.5 justify-center">
      <input
        :id="label"
        type="checkbox"
        :checked="checked"
        :disabled="disabled"
        @change="onChange"
        class="form-checkbox relative z-fixed focus:ring-brandLight h-4 w-4 text-brand border-gray-300 rounded"
      />
    </div>
    <div class="ml-2 text-xs y flex-1">
      <label :for="label" class="font-bold 2xl:text-sm">{{ label }}</label>
      <span class="text-gray-500">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => "",
    },
    description: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    onChange(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<style scoped>
.form-checkbox {
  accent-color: #ff6700;
}
</style>
