<template>
  <div class="x gap-x-1 px-2 cursor-pointer" @click="sort">
    <icon-sort-up
      :color="direction === 'desc' ? 'rgb(255, 103, 0)' : ''"
      class="cursor-pointer"
      title="DESC"
      width="5"
      height="15"
    />
    <icon-sort-down
      :color="direction === 'asc' ? 'rgb(255, 103, 0)' : ''"
      class="cursor-pointer"
      title="ASC"
      width="5"
      height="15"
    />
  </div>
</template>

<script>
import IconSortUp from "../assets/icons/IconSortUp.vue";
import IconSortDown from "../assets/icons/IconSortDown.vue";

export default {
  components: {
    IconSortUp,
    IconSortDown,
  },
  props: {
    direction: String,
  },
  methods: {
    sort(val) {
      this.$emit("sort", val);
    },
  },
};
</script>
