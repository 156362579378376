<template>
  <main>
    <div class="w-full md:px-40 pt-5 y gap-y-5 items-center">
      <div class="w-full x gap-x-5 items-center">
        <div class="w-max x items-center text-gray-400">
          <router-link :to="{ name: 'PermissionsManager' }" class="pt-0.5">
            <i
              class="isax isax-arrow-left-3 cursor-pointer text-sm 2xl:text-2xl sm:text-xl hover:text-brand mr-2"
            />
          </router-link>
          <span
            class="whitespace-nowrap text-xs 2xl:text-xl sm:text-base"
            v-if="loading || !haveRequest"
          >
            Permissions Manager - {{ label }}
          </span>
          <span class="whitespace-nowrap text-xs 2xl:text-xl sm:text-base" v-else>
            Permission Request - {{ item.name }}
          </span>
        </div>
        <div class="flex-1 bg-gray-200" style="height: 1.5px"></div>
      </div>
      <div
        class="x w-full justify-end text-xs md:text-base"
        v-if="!loading && (haveRequest || isInvitation)"
      >
        <span v-if="haveRequest"> Request Date: {{ permissionRequest.created_at }} </span>
        <span v-else-if="item.inviter"> Created By: {{ item.inviter.name }} </span>
      </div>

      <div class="w-full" v-if="!loading">
        <div v-if="!haveRequest">
          <permission-form
            ref="form"
            v-model="item"
            :edit="true"
            style="overflow-y: hidden !important"
          />
          <div class="x w-full">
            <button-secondary @click.native="confirmDelete" class="bg-white" :color="'#F12B2C'"
              >Delete {{ label }}</button-secondary
            >
            <div class="x flex-1 items-end justify-end gap-x-4">
              <button-primary @click.native="submit" class="md:w-24">Save</button-primary>
              <router-link :to="{ name: 'PermissionsManager' }">
                <button-secondary class="text-xs md:w-24 bg-white" :color="'#000'"
                  >Cancel</button-secondary
                >
              </router-link>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="x w-full h-40 items-center justify-center gap-x-4">
            <button-primary @click.native="approve" class="md:w-24">Approve</button-primary>
            <button-secondary @click.native="deny" class="text-xs md:w-24 bg-white" :color="'#000'"
              >Deny</button-secondary
            >
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import alerts from "../utils/alerts";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import ButtonSecondary from "../components/ButtonSecondary.vue";
import PermissionForm from "../components/PermissionForm.vue";
import { isEditable } from "../utils/permissionsManager";

export default {
  components: { ButtonPrimary, ButtonSecondary, PermissionForm },
  data() {
    const item = {
      start_date: null,
      end_date: null,
      cost: 0,
      applicants: 0,
      last_checked_at: null,
      notes: null,
      platform_id: null,
      advertiser: null,
      cities: [],
      currency_id: null,
      country_id: null,
      state: null,
      positions: [],
      no_city: false,
    };

    return {
      item,
      errors: {},
      validator: null,
      loading: true,
      submitting: false,
    };
  },
  mounted() {
    this.fetchItem();
  },
  methods: {
    fetchItem() {
      this.loading = true;
      const route = this.isInvitation
        ? `/invitations/${this.$route.params.id}`
        : `/users/${this.$route.params.id}/permissions`;
      return this.axios
        .get(route)
        .then(async (resp) => {
          const user = resp.data;
          this.item = await this.parseUser({ user });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.go(-1);
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      this.submitting = true;
      const user = this.item;

      if (this.isInvitation) {
        this.updateInvitations({ invitation: user }).then((res) => {
          const { invitation } = res.data.data;
          this.item.updated_at = invitation.updated_at;
        });
      } else {
        this.updateUserPermissions({ user });
        this.updateUser({ user }).then((res) => {
          const usr = res.data.data;
          this.item.updated_at = usr.updated_at;
          this.fetchData(true);
        });
      }
    },
    async confirmDelete() {
      const confirmed = await alerts.showConfirm({
        title: "Delete User",
        message: `Are you sure you want to delete this ${this.label}?`,
      });
      if (!confirmed) return;

      const user = this.item;
      if (this.isInvitation) {
        this.deleteInvitations({ invitation: user });
      } else {
        this.deleteUser({ user }).then(() => {
          this.back();
        });
      }
    },
    changeState(approve = false) {
      this.submitting = true;
      this.axios
        .post(`permission-requests/${this.permissionRequest.id}/${approve ? "approve" : "deny"}`)
        .then(approve ? this.fetchItem : this.back)
        .finally(() => {
          this.submitting = false;
        });
    },
    approve() {
      this.changeState(true);
    },
    deny() {
      this.changeState(false);
    },
    ...mapActions("permissions", [
      "fetchData",
      "parseUser",
      "updateUserPermissions",
      "updateUser",
      "deleteUser",
    ]),
    ...mapActions("invitations", {
      updateInvitations: "update",
      deleteInvitations: "delete",
    }),
  },
  computed: {
    haveRequest() {
      return !isEditable(this.item);
    },
    permissionRequest() {
      return this.item.permission_request;
    },
    isInvitation() {
      return this.$route.name === "PermissionInvitation";
    },
    label() {
      return this.isInvitation ? "Invitation" : "Profile";
    },
  },
};
</script>

<style></style>
