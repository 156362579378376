<template>
  <data-table-header>
    <data-table-header-cell v-for="column in showedColumns" :key="column">
      <input
        placeholder="Filter this field..."
        type="text"
        class="w-full border-gray-300"
        style="background: white !important"
        v-if="column != 'ID'"
        v-debounce:200="(val) => setFilterColumn(val, column)"
      />
    </data-table-header-cell>
  </data-table-header>
</template>

<script>
import DataTableHeader from "./DataTableHeader.vue";
import DataTableHeaderCell from "./DataTableHeaderCell.vue";

export default {
  components: { DataTableHeader, DataTableHeaderCell },
  props: {
    allColumns: [Array, Object],
    showedColumns: [Array, Object],
  },
  data() {
    return {
      filters: {},
    };
  },
  methods: {
    filter() {
      this.$emit("filter", this.filters);
    },
    setFilterColumn(value, column) {
      const key = this.allColumns[column];

      this.setFilter(key, value);
    },
    setFilter(key, value) {
      if (value && value.trim(" ").length > 0) {
        this.filters[key] = value;
      } else {
        delete this.filters[key];
      }
      this.filter();
    },
    clearNotShowedColumns() {
      Object.keys(this.filters).forEach((key) => {
        const columnKey = this.getKeyByValue(this.allColumns, key);
        if (!this.showedColumns.includes(columnKey)) {
          delete this.filters[key];
        }
      });
      this.filter();
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },
  },
  watch: {
    showedColumns() {
      this.clearNotShowedColumns();
    },
  },
};
</script>

<style></style>
