<template>
  <div
    class="py-3 px-2 bg-orange text-white y gap-y-3 md:x gap-x-5 items-center md:rounded-lg xl:w-2/6 lg:w-4/6 w-full"
  >
    <p class="font-mulish font-bold">
      <i class="isax isax-info-circle text-base pr-1" />
      It seems like we’ve {{ number }} unwanted copies, you can
    </p>

    <div class="x gap-x-2">
      <button-primary
        v-if="!hideShow"
        @click.native="$emit('show')"
        style="background: white"
        class="bg-white text-orange font-semibold"
        >Show</button-primary
      >
      <button-secondary :color="''" v-if="!hideIngore" @click.native="$emit('ignore')"
        >Ignore</button-secondary
      >
    </div>
  </div>
</template>

<script>
import ButtonPrimary from "./ButtonPrimary.vue";
import ButtonSecondary from "./ButtonSecondary.vue";

export default {
  props: {
    number: Number,
    hideShow: Boolean,
    hideIngore: Boolean,
  },
  components: { ButtonPrimary, ButtonSecondary },
};
</script>

<style></style>
