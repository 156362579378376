<template>
  <div class="relative pb-2 sm:rounded-lg">
    <table class="w-full divide-y divide-transparent">
      <slot name="header" />
      <slot name="after-header" />
      <tbody class="divide-y divide-transparent">
        <slot name="body"></slot>
      </tbody>
      <tfoot v-if="noData">
        <tr>
          <td>
            <div class="pl-4 py-2 whitespace-nowrap">No results found</div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    noData: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style>
td:first-child {
  padding-left: 20px !important;
  padding-right: 0;
}
table tr td:not(:first-child) {
  @apply border-l;
}
table tr td {
  @apply border-b;
}
</style>
