<template>
  <div class="mx-auto w-full py-4 px-6 flex-1 y gap-y-2 overflow-y-scroll">
    <div class="y xl:x gap-x-6">
      <field-item :label="'Name'" :required="true">
        <template #input>
          <validated-input
            :errors="errors ? errors['name'] : []"
            :value="value.name"
            @input="(val) => update('name', val)"
          />
        </template>
      </field-item>

      <field-item :label="'To'">
        <template #input>
          <validated-input
            :errors="errors ? errors['to'] : []"
            :value="value.to"
            @input="(val) => update('to', val)"
          />
        </template>
      </field-item>
    </div>

    <div class="y xl:x gap-x-6">
      <field-item :label="'Subject'">
        <template #input>
          <validated-input
            :errors="errors ? errors['subject'] : []"
            :value="value.subject"
            @input="(val) => update('subject', val)"
          />
        </template>
      </field-item>

      <field-item :label="'CC'">
        <template #input>
          <validated-input
            :errors="errors ? errors['cc'] : []"
            :value="value.cc"
            @input="(val) => update('cc', val)"
          />
        </template>
      </field-item>
    </div>

    <field-item :label="'Message'">
      <template #input>
        <rich-text-editor v-model="value.message" />
      </template>
    </field-item>

    <slot name="before-attachments" />

    <drag-and-drop-file-selector
      :class="{ 'bg-white border-none shadow-md': edit }"
      @change="filesSelected"
    >
      <template #default="{ label }" v-if="edit">
        <attachments-list :attachments="value.attachments" :label="label">
          <div class="flex-1 x gap-2 flex-wrap h-full">
            <attachment
              class="w-64"
              v-for="(file, index) of value.attachments"
              :key="index"
              :title="file.name"
              :bytes="getFileSize(file)"
              :showDownload="edit && file.id"
              @download="downloadAttachment(file)"
              @preview="previewed = file"
              :canPreview="isAttachmentPreviewable(file)"
              :isPreviewing="previewed ? previewed.id === file.id : false"
              @delete="removeAttachment(file)"
            />
            <attachment
              class="w-64"
              v-for="file of uploadingAttachments"
              :key="file.key"
              :title="file.name"
              :bytes="getFileSize(file)"
              :uploading="true"
              :progress="file.progress"
              @delete="abortUploading(file)"
              :converting="file.progress === 100 && file.type === 'video'"
              v-show="file.uploading"
            />
          </div>
        </attachments-list>
      </template>
    </drag-and-drop-file-selector>

    <div class="x gap-2 flex-wrap md:px-3" v-if="!edit">
      <attachment
        class="w-full md:w-64"
        v-for="(file, index) of value.attachments"
        :key="index"
        :title="file.name"
        :bytes="getFileSize(file)"
        @delete="
          update(
            'attachments',
            value.attachments.filter((v, i) => {
              return index !== i;
            }),
          )
        "
      />
    </div>

    <attachment-preview class="pt-5" :attachment="previewed" :attachmentsPath="attachmentsPath" />
  </div>
</template>

<script>
import * as Validator from "validatorjs";
import FieldItem from "./FieldItem.vue";
import ValidatedInput from "./ValidatedInput.vue";
import DragAndDropFileSelector from "./DragAndDropFileSelector.vue";
import Attachment from "./Attachment.vue";
import AttachmentsList from "./AttachmentsList.vue";
import alerts from "../utils/alerts";
import {
  isFileSuported,
  getFileType,
  downloadAttachment,
  isAttachmentPreviewable,
  fileToUploadableFormat,
  uploadFiles,
} from "../utils/attachments";
import AttachmentPreview from "./AttachmentPreview.vue";
import RichTextEditor from "./RichTextEditor.vue";

export default {
  props: {
    value: {
      Object,
      default: () => ({}),
    },
    errors: {
      Object,
      default: () => ({}),
    },
    edit: Boolean,
  },
  data() {
    return {
      validator: null,
      previewed: null,
      uploadingAttachments: [],
    };
  },
  components: {
    FieldItem,
    ValidatedInput,
    DragAndDropFileSelector,
    Attachment,
    AttachmentsList,
    AttachmentPreview,
    RichTextEditor,
  },
  mounted() {
    if (this.edit) {
      const canBePreviewed = this.value.attachments.find(this.isAttachmentPreviewable);
      if (canBePreviewed) {
        this.previewed = canBePreviewed;
      }
    }
  },
  methods: {
    filesSelected(files) {
      const uploadingAttachments = Array.from(files)
        .filter((file) => {
          const isSuported = this.isFileSuported(file);
          if (!isSuported) {
            alerts.showMessage(
              `The file ${file.name} is not in supported format.`,
              file.name,
              true,
            );
          }
          return isSuported;
        })
        .map(fileToUploadableFormat);
      this.uploadingAttachments.push(...uploadingAttachments);
      const findWithKey = (key) => (a) => a.key === key;
      uploadFiles({
        uploadingFiles: this.uploadingAttachments,
        onFileProgress: (key, progress) => {
          this.uploadingAttachments.find(findWithKey(key)).progress = progress;
        },
        onFileSuccess: (key, multimedia) => {
          this.value.attachments.push(multimedia);
          this.update("attachments", this.value.attachments);
        },
        onFileFinish: (key) => {
          console.log("finished", key);
          this.uploadingAttachments.find(findWithKey(key)).uploading = false;
        },
      });
    },
    update(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    async validate() {
      this.validator = new Validator(this.value, {
        name: "required",
        cc: "email",
        to: "email",
      });

      if (this.validator.passes()) {
        this.errors = null;
      } else this.errors = this.validator.errors.all();

      return this.validator.passes();
    },
    downloadAttachment(attachment) {
      downloadAttachment(attachment, this.attachmentsPath);
    },
    isAttachmentPreviewable(attachment) {
      return isAttachmentPreviewable(attachment);
    },
    isFileSuported(file) {
      return isFileSuported(file);
    },
    getFileType(file) {
      return getFileType(file);
    },
    getFileSize(file) {
      return file.pivot?.size ?? file.size;
    },
    abort(multimedia) {
      return this.$abortFile(multimedia);
    },
    abortUploading(file) {
      file.token.cancel();
      Object.assign(file, { uploading: false });
    },
    async removeAttachment(attachment) {
      const confirmed = await alerts.showConfirm({
        title: "Delete Attachment",
        message: "Are you sure you want to delete this attachment?",
      });
      if (!confirmed) return;

      await this.abort(attachment);
      this.value.attachments = this.value.attachments.filter((a) => a.id !== attachment.id);
      this.update("attachments", this.value.attachments);
    },
  },
  computed: {
    attachmentsPath() {
      return `email-templates/${this.value.id}`;
    },
  },
};
</script>
