<template>
  <div class="cursor-pointer w-full x items-center">
    <input
      :checked="value"
      type="radio"
      class="h-3.5 w-3.5"
      :id="id"
      @input="$emit('input', !value)"
    />
    <div class="w-36 inline-block text-left">
      <label :for="id" class="ml-4 font-bold">{{ label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    label: String,
    value: Boolean,
  },
};
</script>

<style></style>
