<template>
  <pop-up-modal
    @close="$emit('close')"
    :width="$mq === 'sm' ? '100%' : '75%'"
    :height="$mq === 'sm' ? '100%' : '80%'"
  >
    <country-profile-form ref="form" v-model="item" />

    <template #actionButton>
      <button-primary @click.native="submit" :loading="submitting"> Save </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import { mapActions } from "vuex";
import ButtonPrimary from "./ButtonPrimary.vue";
import PopUpModal from "./PopUpModal.vue";
import CountryProfileForm from "./CountryProfileForm.vue";

export default {
  components: { PopUpModal, ButtonPrimary, CountryProfileForm },
  data() {
    const item = {
      country_id: null,
      cities: null,
      recruiters: null,
      importance: null,
    };

    return {
      item,
      submitting: false,
    };
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      await this.add();
      this.$emit("close");
    },
    async add() {
      this.submitting = true;

      return this.updateCountry({
        country: {
          id: this.item.country_id,
          ...this.item,
        },
        reload: true,
      }).finally(() => {
        this.submitting = false;
      });
    },
    ...mapActions("scout", ["fetchData", "updateCountry"]),
  },
};
</script>

<style></style>
