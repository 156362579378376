<template>
  <pop-up-modal
    @close="$emit('close')"
    :width="$mq === 'sm' ? '100%' : '75%'"
    :height="$mq === 'sm' ? '100%' : '80%'"
  >
    <permission-form ref="form" v-model="item" />

    <template #actionButton>
      <button-primary @click.native="submit" :loading="submitting"> Save </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions } from "vuex";
import ButtonPrimary from "./ButtonPrimary.vue";
import PermissionForm from "./PermissionForm.vue";
import PopUpModal from "./PopUpModal.vue";

export default {
  components: { PopUpModal, ButtonPrimary, PermissionForm },
  data() {
    const item = {
      name: null,
      email: null,
      gitlab_id: null,
      created_at: DateTime.now().toFormat("yyyy-LL-dd hh:mm"),
      updated_at: DateTime.now().toFormat("yyyy-LL-dd hh:mm"),
    };

    return {
      item,
      submitting: false,
    };
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      this.save().then(() => {
        this.$emit("close");
      });
    },
    async save() {
      this.submitting = true;

      return this.add({ invitation: this.item }).finally(() => {
        this.submitting = false;
      });
    },
    ...mapActions("invitations", ["add"]),
  },
};
</script>

<style></style>
