import { serialize } from "object-to-formdata";
import axios from "axios";
import getListStore from "./list";

const listStore = getListStore("email-templates", {
  order_by: null,
  order_direction: null,
  search: null,
  search_scope: [],
  only_active: true,
});

const parseData = (template) => {
  return {
    ...template,
    attachments: template.attachments.map((attachment) => ({
      id: attachment.id,
      size: attachment.pivot ? attachment.pivot.size : attachment.size,
    })),
  };
};

const store = {
  ...listStore,
  state: {
    ...listStore.state,
  },
  getters: {
    ...listStore.getters,
  },
  mutations: {
    ...listStore.mutations,
  },
  actions: {
    ...listStore.actions,
    async add({ dispatch }, template) {
      const data = parseData(template);
      return axios
        .post(
          `/email-templates`,
          serialize(data, {
            indices: true,
          }),
        )
        .then(() => {
          dispatch("fetchData", true);
        });
    },
    async update({ dispatch }, template, reload = false) {
      const data = parseData(template);
      return axios
        .post(
          `/email-templates/${template.id}?_method=PUT`,
          serialize(data, {
            indices: true,
          }),
        )
        .then(() => {
          if (reload) dispatch("fetchData", true);
        });
    },
    async delete(_, template) {
      return axios.delete(`/email-templates/${template.id}`);
    },
  },
};

export default store;
