<template>
  <div class="mx-auto w-full py-4 px-6 flex-1 y gap-y-2 overflow-y-scroll">
    <div class="y xl:x gap-x-6">
      <field-item :label="'Country'">
        <template #input>
          <validated-select :errors="errors ? errors['country_id'] : []">
            <v-select
              label="name"
              placeholder="Choose"
              :options="countries"
              :reduce="(company) => company.id"
              :value="value.country_id"
              @input="(val) => update('country_id', val)"
              class="selector md:flex-1"
            />
          </validated-select>
        </template>
      </field-item>

      <field-item :label="'Importances'">
        <template #input>
          <validated-select :errors="errors ? errors['importance'] : []">
            <country-importance-selector
              :errors="errors ? errors['importance'] : []"
              :value="value.importance"
              @input="(val) => update('importance', val)"
            />
          </validated-select>
        </template>
      </field-item>
    </div>

    <div class="y xl:x gap-x-6">
      <field-item :label="'Cities'">
        <template #input>
          <validated-input
            :errors="errors ? errors['cities'] : []"
            class="md:flex-1"
            :value="value.cities"
            @input="(val) => update('cities', val)"
          />
        </template>
      </field-item>

      <field-item :label="'Recruiters'">
        <template #input>
          <validated-input
            :errors="errors ? errors['recruiters'] : []"
            class="md:flex-1"
            :value="value.recruiters"
            @input="(val) => update('recruiters', val)"
          />
        </template>
      </field-item>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as Validator from "validatorjs";
import FieldItem from "./FieldItem.vue";
import ValidatedInput from "./ValidatedInput.vue";
import ValidatedSelect from "./ValidatedSelect.vue";
import CountryImportanceSelector from "./CountryImportanceSelector.vue";

export default {
  props: {
    value: {
      Object,
      default: () => ({}),
    },
    edit: Boolean,
  },
  data() {
    return {
      validator: null,
      errors: {},
    };
  },
  async mounted() {
    if (this.countries.length < 1) {
      this.fetchCountries();
    }
  },
  components: {
    FieldItem,
    ValidatedInput,
    ValidatedSelect,
    CountryImportanceSelector,
  },
  methods: {
    update(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    async validate() {
      this.validator = new Validator(this.value, {
        country_id: "required",
        importance: "required",
      });
      this.validator.setAttributeNames({ country_id: "Country" });

      if (this.validator.passes()) {
        this.errors = null;
      } else this.errors = this.validator.errors.all();

      return this.validator.passes();
    },
    ...mapActions("data", ["fetchCountries"]),
  },
  computed: {
    ...mapState("data", ["countries"]),
  },
};
</script>

<style></style>
