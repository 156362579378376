<template>
  <button
    class="px-3 py-2 text-sm rounded-md text-white capitalize bg-orange disabled:bg-opacity-70"
    :disabled="loading"
  >
    <slot v-if="loading" name="loading">
      <i class="isax isax-refresh text-xl inline-block rotate text-white" />
    </slot>
    <slot v-else class="inline-block" />
  </button>
</template>

<script lang="ts">
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
    loading: Boolean,
  },
};
</script>
