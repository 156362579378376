<template>
  <div
    class="relative w-full mt-5"
    :style="{ 'min-height': previewURL || loading ? '500px' : '0' }"
  >
    <div
      v-if="loading"
      class="x items-center justify-center absolute inset-0 bg-black bg-opacity-70 z-fixed"
    >
      <i class="isax isax-refresh text-3xl rotate text-white" />
    </div>
    <div class="w-full h-full x justify-center" v-if="previewURL">
      <vue-pdf-embed v-if="isAttachmentPDF" :source="previewURL" :width="548" :height="700" />
      <video
        width="548"
        height="700"
        controls
        :src="previewURL"
        v-else-if="isAttachmentVideo"
      ></video>
      <img v-else :src="previewURL" alt="" />
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { isAttachmentPDF, isAttachmentVideo, getAttachmentPreviewURL } from "../utils/attachments";

export default {
  components: {
    VuePdfEmbed,
  },
  props: {
    attachment: Object,
    attachmentsPath: String,
  },
  data() {
    return {
      loading: false,
      previewURL: null,
    };
  },
  computed: {
    isAttachmentPDF() {
      return isAttachmentPDF(this.attachment);
    },
    isAttachmentVideo() {
      return isAttachmentVideo(this.attachment);
    },
  },
  watch: {
    async attachment() {
      this.loading = true;
      this.previewURL = await getAttachmentPreviewURL(this.attachment, this.attachmentsPath);
      this.loading = false;
    },
  },
};
</script>

<style></style>
