<template>
  <main>
    <div class="y w-full gap-y-5 px-0 md:px-24">
      <div class="relative w-full y md:x gap-x-3 items-end justify-end">
        <div class="w-4/12 md:static absolute top-0 left-0 pb-0 md:pb-5 x gap-x-5 items-center">
          <router-link :to="{ name: 'AdsMap' }">
            <i class="isax isax-global text-gray-400 text-2xl cursor-pointer" title="Map view" />
          </router-link>

          <checkbox-input
            :label="'Show only active ads'"
            :checked="filters.only_active"
            @change="() => (filters.only_active = !filters.only_active)"
          />
        </div>
        <div class="w-full md:w-6/12 pt-3">
          <field-item :label="''" class="justify-self-end">
            <template #input>
              <div class="x border rounded-md bg-white items-center pr-3">
                <input
                  type="text"
                  v-debounce:200="(val) => (filters.search = val)"
                  :placeholder="'Search'"
                  class="shadow-none flex-1 border-none focus:outline-none"
                />
                <i class="isax isax-search-normal text-xl text-gray-400" />
              </div>
            </template>
          </field-item>
        </div>
        <div class="w-3/12 x justify-end md:static absolute top-0 right-0 pb-0 md:pb-3">
          <button-primary class="h-8 w-28" @click.native="showAddModal = true"
            >Add new</button-primary
          >
        </div>
      </div>

      <data-table class="mt-2 table-fixed">
        <template #header>
          <selectable-columns
            :showedColumns="showedColumns"
            :allColumns="allColumns"
            :setShowedColumns="setShowedColumns"
            :searchColumns="filters.search_scope"
            @updateSearchColumns="(columns) => (filters.search_scope = columns)"
            :orderBy="filters.order_by"
            :orderDirection="filters.order_direction"
            @updateOrder="
              (o) => {
                filters.order_by = o.orderBy;
                filters.order_direction = o.orderDirection;
              }
            "
            @updateShowedColumns="(val) => (showedColumns = val)"
          />
        </template>

        <template #body>
          <tr v-for="ad in ads" :key="ad.id" class="hover:bg-white cursor-pointer">
            <data-table-cell v-for="column in showedColumns" :key="column" class="bg-transparent">
              <router-link
                class="text-sm"
                v-if="column === 'ID'"
                :to="{ name: 'AdProfile', params: { id: ad.id } }"
                >{{ ad.id }}</router-link
              >

              <date-picker
                v-else-if="
                  ['start_date', 'end_date', 'last_checked_at'].includes(allColumns[column])
                "
                :value="getData(ad, column)"
                @input="(ev) => setData(ad, column, ev)"
                :clearable="false"
                valueType="format"
                format="YYYY-MM-DD"
                class="w-full table-date-picker"
              />
              <v-select
                v-else-if="allColumns[column] == 'cities'"
                placeholder="Choose"
                :options="cities"
                :taggable="true"
                class="inside-table-selector selector md:flex-1"
                label="name"
                multiple
                :value="ad.cities"
                :closeOnSelect="false"
                @input="(val) => setData(ad, column, val)"
              />
              <v-select
                v-else-if="allColumns[column] == 'advertiser'"
                placeholder="Choose"
                :options="advertisers"
                :taggable="true"
                class="inside-table-selector selector md:flex-1"
                label="name"
                :value="ad.advertiser"
                :closeOnSelect="false"
                @input="(val) => setData(ad, column, val)"
              />
              <v-select
                v-else-if="allColumns[column] == 'state'"
                placeholder="Choose"
                :options="adStates"
                class="inside-table-selector selector md:flex-1"
                :value="getData(ad, column)"
                :closeOnSelect="false"
                @input="(val) => setData(ad, column, val)"
              >
                <template #option="{ label }">
                  <span :style="{ color: getStateColor(label) }">{{ label }}</span>
                </template>
                <template #selected-option="{ label }">
                  <span :style="{ color: getStateColor(label) }">{{ label }}</span>
                </template>
              </v-select>
              <v-select
                v-else-if="allColumns[column] == 'positions'"
                :placeholder="positionPlaceholder(ad)"
                :options="positions"
                label="name"
                class="inside-table-selector selector md:flex-1"
                :value="getData(ad, column)"
                :closeOnSelect="false"
                :taggable="true"
                multiple
                @input="(val) => setData(ad, column, val)"
              />
              <v-select
                class="inside-table-selector selector"
                v-else-if="
                  ['platform.name', 'currency.name', 'country.name'].includes(allColumns[column])
                "
                placeholder="None"
                label="name"
                :reduce="(i) => i.id"
                :options="
                  allColumns[column] === 'platform.name'
                    ? platforms
                    : allColumns[column] === 'currency.name'
                    ? currencies
                    : allColumns[column] === 'country.name'
                    ? countries
                    : []
                "
                :value="
                  allColumns[column] === 'platform.name'
                    ? ad.platform_id
                    : allColumns[column] === 'currency.name'
                    ? ad.currency_id
                    : allColumns[column] === 'country.name'
                    ? ad.country_id
                    : null
                "
                @input="(ev) => setData(ad, column, ev)"
                :clearable="false"
              />
              <input
                v-else-if="allColumns[column] === 'recruits_count'"
                :value="getData(ad, column)"
                @change="(ev) => setData(ad, column, ev.target.value)"
                type="number"
                min="0"
                class="py-1 border-none"
                style="padding-right: 0 !important"
                :disabled="true"
              />
              <p v-else-if="allColumns[column] == 'cost'">
                <input
                  :value="getData(ad, column)"
                  @change="(ev) => setData(ad, column, ev.target.value)"
                  type="number"
                  min="0"
                  class="py-1 border-none text-right"
                  style="padding-right: 0 !important"
                />
                <span v-if="ad.cost">
                  {{
                    ad.currency && (ad.currency.code || ad.currency.symbol)
                      ? ad.currency.symbol
                        ? ad.currency.symbol
                        : ad.currency.code
                      : "€"
                  }}
                </span>
              </p>
              <!-- TODO: Add positions -->
              <input
                v-else
                :value="getData(ad, column)"
                @change="(ev) => setData(ad, column, ev.target.value)"
                class="py-1 border-none"
                :class="{
                  'hover:font-bold cursor-pointer whitespace-nowrap w-52': column === 'Name',
                }"
              />
            </data-table-cell>
            <data-table-cell>
              <i
                @click="confirmDeleteRecurit(ad)"
                class="isax isax-trash pl-2 cursor-pointer"
                title="delete"
              />
            </data-table-cell>
          </tr>
        </template>
      </data-table>
      <infinite-loader
        :disabled="settings.reachedEnd"
        :loading="settings.loading"
        @loadMore="fetchData"
      />
    </div>
    <transition name="popup">
      <ad-add-new v-if="showAddModal" @close="showAddModal = false" />
    </transition>
  </main>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import FieldItem from "../components/FieldItem.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import DataTable from "../components/DataTable.vue";
import DataTableCell from "../components/DataTableCell.vue";
import InfiniteLoader from "../components/InfiniteLoader.vue";
import SelectableColumns from "../components/SelectableColumns.vue";
import utils from "../utils/utils";
import "vue2-datepicker/index.css";
import CheckboxInput from "../components/CheckboxInput.vue";
import AdAddNew from "../components/AdAddNew.vue";

export default {
  components: {
    FieldItem,
    ButtonPrimary,
    DataTable,
    DataTableCell,
    InfiniteLoader,
    SelectableColumns,
    DatePicker,
    CheckboxInput,
    AdAddNew,
  },
  data() {
    return {
      showAddModal: false,
    };
  },
  mounted() {
    this.fetchData(true);
    this.fetchShowedColumns();
    if (this.platforms.length < 1) {
      this.fetchPlatforms();
    }
    if (this.currencies.length < 1) {
      this.fetchCurrencies();
    }
    if (this.countries.length < 1) {
      this.fetchCountries();
    }
    if (this.cities.length < 1) {
      this.fetchCities();
    }
    if (this.advertisers.length < 1) {
      this.fetchAdvertisers();
    }
    if (this.adStates.length < 1) {
      this.fetchAdStates();
    }
    this.fetchPositions();
  },
  methods: {
    setData(ad, column, value) {
      if (column === "ID") return;
      if (this.allColumns[column]) {
        let col = this.allColumns[column] ?? column;

        const colWithObject = col.split(".");
        if (colWithObject.length === 2) {
          col = `${colWithObject[0]}_id`;
        }
        this.setFromKey(ad, col, value);
        this.updateAd(ad);
      }
    },
    getData(recruit, column) {
      if (column === "ID") return recruit.id;
      return this.getFromKey(recruit, this.allColumns[column]);
    },
    getFromKey(data, key) {
      return this.fromKey(data, key);
    },
    setFromKey(data, key, val) {
      this.fromKey(data, key, val);
    },
    fromKey(data, key, set = null) {
      return utils.fromKey(data, key, set);
    },
    positionPlaceholder(ad) {
      if (ad.positions.length === 0) return "Add Position";
      let placeholder = ad.positions[0];
      if (ad.positions.length > 1) placeholder += `, (+${ad.positions.length - 1})`;
      return placeholder;
    },
    async updateAd(ad) {
      await this.$store.dispatch("ads/updateAd", ad);
    },
    getStateColor(state) {
      switch (state) {
        case "Active":
          return "#FF6700";
        case "Ended":
          return "#DB2A2A";
        case "Unfinished":
          return "#333333";
        default:
          return "#AAAAAA";
      }
    },
    ...mapActions("ads", ["fetchData"]),
    ...mapMutations("ads", { setAds: "setData" }),
    ...mapActions("adsColumns", ["fetchShowedColumns"]),
    ...mapMutations("adsColumns", ["setShowedColumns"]),
    ...mapActions("data", [
      "fetchPlatforms",
      "fetchCurrencies",
      "fetchCountries",
      "fetchAdStates",
      "fetchCities",
      "fetchAdvertisers",
      "fetchPositions",
    ]),
  },
  computed: {
    ads: {
      get() {
        return this.$store.state.ads.data;
      },
      set(val) {
        this.setAds(val);
      },
    },
    showedColumns: {
      get() {
        return this.$store.state.adsColumns.showedColumns;
      },
      set(val) {
        this.setShowedColumns(val);
      },
    },
    ...mapGetters("adsColumns", ["allColumns"]),
    ...mapGetters("ads", ["filters", "settings"]),
    ...mapState("data", [
      "platforms",
      "currencies",
      "countries",
      "adStates",
      "cities",
      "advertisers",
      "positions",
    ]),
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        if (Object.keys(val).length > 0) this.fetchData(true);
      },
    },
  },
};
</script>

<style></style>
