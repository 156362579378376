<template>
  <svg
    width="34"
    height="33"
    viewBox="0 0 34 33"
    fill="none"
    class="hover:opacity-60"
    xmlns="http://www.w3.org/2000/svg"
    title="Male"
  >
    <path
      d="M17 32.4654C25.8366 32.4654 33 25.4216 33 16.7327C33 8.04377 25.8366 1 17 1C8.16344 1 1 8.04377 1 16.7327C1 25.4216 8.16344 32.4654 17 32.4654Z"
      :style="{ stroke: active ? '#EB671B' : '#D4D4D4' }"
      stroke-miterlimit="10"
    />
    <path
      d="M22.1144 26.1406C21.8849 26.0298 21.6679 25.8929 21.4493 25.7613C20.9439 25.4577 20.7132 25.0163 20.732 24.431C20.7485 23.9267 20.7344 23.4216 20.732 22.9166C20.7327 22.8738 20.7422 22.8316 20.7599 22.7924C20.7775 22.7533 20.803 22.7181 20.8348 22.6889C21.5862 21.8917 22.1359 20.9315 22.4393 19.8862C22.4629 19.8048 22.4703 19.7153 22.5594 19.6655C22.9988 19.419 23.2621 19.0231 23.4368 18.5786C23.7024 17.9038 23.8747 17.2101 23.6946 16.4774C23.6435 16.25 23.5504 16.0336 23.4199 15.8392C23.3879 15.7968 23.3669 15.7474 23.3589 15.6952C23.3509 15.643 23.3561 15.5896 23.374 15.5398C23.6727 14.559 23.8517 13.5466 23.9072 12.5242C23.9783 11.2783 23.8492 10.051 23.226 8.92859C22.6971 7.9752 21.8762 7.3733 20.8121 7.09589C20.6114 7.04634 20.4282 6.94419 20.282 6.80034C19.7397 6.28371 19.0593 6.036 18.3283 5.90521C16.9223 5.65364 15.538 5.7802 14.1901 6.20886C12.2196 6.83583 10.9886 8.1658 10.4954 10.1443C10.2451 11.1475 10.2208 12.1634 10.3149 13.1901C10.3879 13.9791 10.5354 14.7535 10.6951 15.5271C10.7116 15.6066 10.7265 15.6814 10.6755 15.7586C10.3008 16.3007 10.2361 16.8991 10.3322 17.5334C10.4366 18.221 10.5986 18.9004 11.1448 19.3762C11.4666 19.6563 11.64 19.973 11.7762 20.3542C12.0831 21.2086 12.5599 21.9943 13.1786 22.6658C13.2665 22.7622 13.29 22.8649 13.303 22.9891C13.3646 23.5802 13.2904 24.1694 13.2912 24.7593C13.2912 25.0321 13.1868 25.2443 12.9996 25.4345C12.6253 25.8149 12.1607 26.0545 11.6769 26.2664C10.6269 26.7259 9.51876 27.038 8.45461 27.457C7.68161 27.7615 6.92861 28.106 6.23291 28.559C7.6428 29.9659 9.32315 31.0831 11.1762 31.8455C13.0293 32.6079 15.018 33.0003 17.0267 33C19.0355 32.9996 21.0241 32.6065 22.8769 31.8434C24.7296 31.0803 26.4096 29.9625 27.8189 28.5551C26.973 27.9741 26.0206 27.6071 25.0628 27.2506C24.0799 26.8818 23.0628 26.5986 22.1144 26.1406Z"
      :style="{ fill: active ? '#EB671B' : '#D4D4D4' }"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: Boolean,
  },
};
</script>

<style></style>
