<template>
  <div class="w-full h-full">
    <div class="world-map w-full h-full" :id="'map' + id"></div>

    <div
      class="bg-white absolute shadow-xl w-52 h-auto px-4 pt-8 pb-4 text-xs"
      style="min-height: 128px"
      :style="{ left: `${tipCoords.x}px`, top: `${tipCoords.y}px` }"
      v-if="selectedCountry"
    >
      <i
        @click="unselectCountry"
        class="absolute top-5 right-5 isax isax-close-circle cursor-pointer"
      ></i>
      <slot name="info-window" :selected="selectedCountry" />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { init } from "../utils/map";

window.jQuery = require("jquery");
require("jvectormap");

export default {
  props: {
    id: {
      type: [String, Number],
      default: () => new Date().getTime(),
    },
    data: {
      type: [Array, Object],
      default: () => [],
    },
    scales: {
      type: [Array, Object],
      default: () => ["#5AD55A", "#FEFE31", "#FE9852", "#FE9852", "#FE9852", "#D53131", "#973131"],
    },
    isPolynomial: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      selectedCountry: false,
      tipCoords: {
        x: 0,
        y: 0,
      },
      map: null,
      countries: new Map(),
    };
  },
  mounted() {
    init();
    let x = 0;
    let y = 0;
    // eslint-disable-next-line
    const map = $(`#map${this.id}`);
    map.empty();

    map.mouseover((e) => {
      x = e.pageX + 208 >= window.innerWidth ? window.innerWidth - 258 : e.pageX;
      y = e.pageY;
    });

    const changeSelectedCountry = (e, c) => {
      this.selectedCountry = c;
      this.tipCoords.x = x + 50;
      this.tipCoords.y = y;
    };

    const values = this.data;

    map.vectorMap({
      map: "world_mill_en",
      backgroundColor: "#F5F5F5",
      regionsSelectable: true,
      regionsSelectableOne: true,
      regionStyle: {
        selected: null,
      },
      series: {
        regions: [
          {
            values,
            scale: this.scales,
            normalizeFunction: this.isPolynomial ? "polynomial" : "linear",
          },
        ],
      },
      onRegionTipShow(e) {
        return e.preventDefault();
      },
      onRegionClick(e, code) {
        changeSelectedCountry(e, code);
      },
    });

    this.map = $(map.children(".jvectormap-container")[0]).data("mapObject");
  },
  methods: {
    unselectCountry() {
      this.selectedCountry = null;
      this.map.clearSelectedRegions();
    },
  },
};
</script>

<style>
.world-map >>> .jvectormap-zoomin {
  @apply bottom-0 !important;
}
.jvectormap-region {
  filter: drop-shadow(0px 0px #444);
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 30px;
  height: 30px;
  color: black;
  @apply flex items-center justify-center bg-white text-xs md:text-lg shadow-md hover:bg-opacity-20;
}
.jvectormap-zoomin {
  top: 60px;
}
.jvectormap-zoomout {
  top: 90px;
}
@media only screen and (min-width: 600px) {
  .jvectormap-zoomin {
    top: unset;
    bottom: 60px;
    left: unset;
    right: 30px;
  }
  .jvectormap-zoomout {
    top: unset;
    bottom: 30px;
    left: unset;
    right: 30px;
  }
}
.jvectormap-tip {
  @apply bg-white text-black shadow-xl w-52 h-32 p-4 py-8 text-xs transform;
}
</style>
