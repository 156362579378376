var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"y w-full gap-y-5 px-0 md:px-24"},[_c('div',{staticClass:"relative w-full y md:x gap-x-3 items-end justify-end"},[_c('div',{staticClass:"w-3/12 md:static absolute top-0 left-0 pb-0 md:pb-5"}),_c('div',{staticClass:"w-full md:w-6/12 pt-3"},[_c('field-item',{staticClass:"justify-self-end",attrs:{"label":''},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"x border rounded-md bg-white items-center pr-3"},[_c('input',{directives:[{name:"debounce",rawName:"v-debounce:200",value:(function (val) { return (_vm.filters.search = val); }),expression:"(val) => (filters.search = val)",arg:"200"}],staticClass:"shadow-none flex-1 border-none focus:outline-none",attrs:{"type":"text","placeholder":'Search'}}),_c('i',{staticClass:"isax isax-search-normal text-xl text-gray-400"})])]},proxy:true}])})],1),_c('div',{staticClass:"w-3/12 x justify-end md:static absolute top-0 right-0 pb-0 md:pb-3"},[_c('button-primary',{staticClass:"h-8 w-28",nativeOn:{"click":function($event){_vm.showAddInvitation = true}}},[_vm._v("Invite")])],1)]),_c('data-table',{staticClass:"mt-2 table-fixed",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('selectable-columns',{attrs:{"showedColumns":_vm.showedColumns,"allColumns":_vm.allColumns,"setShowedColumns":_vm.setShowedColumns,"searchColumns":_vm.filters.search_scope,"orderBy":_vm.filters.order_by,"orderDirection":_vm.filters.order_direction,"isSelectable":function (col) { return !_vm.permissionsColumns.includes(col); }},on:{"updateSearchColumns":function (columns) { return (_vm.filters.search_scope = columns); },"updateOrder":function (o) {
              _vm.filters.order_by = o.orderBy;
              _vm.filters.order_direction = o.orderDirection;
            },"updateShowedColumns":function (val) { return (_vm.showedColumns = val); }}})]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.invitations.concat( _vm.permissions)),function(user){return _c('tr',{key:_vm.isInvitation ? '-' + user.id : user.id,staticClass:"hover:bg-white cursor-pointer"},[_vm._l((_vm.showedColumns),function(column){return _c('data-table-cell',{key:column,staticClass:"bg-transparent",style:({
              color: !_vm.isEditable(user) ? '#bbbbbb' : '',
            })},[(column === 'ID' || column === 'Name')?_c('router-link',{staticClass:"text-sm",class:{
                'hover:font-bold cursor-pointer whitespace-nowrap w-52': column === 'Name',
              },attrs:{"to":{
                name: _vm.isInvitation(user) ? 'PermissionInvitation' : 'PermissionProfile',
                params: { id: user.id },
              }}},[_vm._v(_vm._s(_vm.getData(user, column))+" "),(column === 'Name' && _vm.isInvitation(user))?_c('div',{staticClass:"ml-1 rounded-lg px-1 py-0.5 text-xs inline-block bg-orange w-min text-white"},[_vm._v(" invitation ")]):_vm._e()]):(_vm.permissionsColumns.includes(_vm.allColumns[column]))?_c('toggle-button',{staticClass:"py-2",attrs:{"value":_vm.getData(user, column),"disabled":!_vm.isEditable(user)},on:{"input":function () { return _vm.setData(user, column, !_vm.getData(user, column)); }}}):(['created_at', 'updated_at'].includes(_vm.allColumns[column]))?_c('date-picker',{staticClass:"w-full table-date-picker",attrs:{"value":_vm.getData(user, column),"type":"datetime","valueType":"format","format":"YYYY-MM-DD HH:mm","clearable":false,"disabled":!_vm.isEditable(user)},on:{"input":function (val) { return _vm.setData(user, column, val); }}}):_c('input',{staticClass:"py-1 border-none",attrs:{"disabled":!_vm.isEditable(user)},domProps:{"value":_vm.getData(user, column)},on:{"change":function (ev) { return _vm.setData(user, column, ev.target.value); }}})],1)}),_c('data-table-cell',[_c('i',{staticClass:"isax isax-trash pl-2 cursor-pointer",attrs:{"title":"delete"},on:{"click":function($event){return _vm.confirmDelete(user)}}})])],2)})},proxy:true}])}),_c('infinite-loader',{attrs:{"disabled":_vm.settings.reachedEnd,"loading":_vm.settings.loading},on:{"loadMore":_vm.fetchData}})],1),_c('transition',{attrs:{"name":"popup"}},[(_vm.showAddInvitation)?_c('invitation-add-new',{staticClass:"absolute z-fixed bottom-5 right-5",on:{"close":function($event){_vm.showAddInvitation = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }