import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import alerts from "./modules/alert";
import toasts from "./modules/toasts";
import recruitmentManagerColumns from "./modules/recruitmentManagerColumns";
import countriesColumns from "./modules/countriesColumns";
import adsColumns from "./modules/adsColumns";
import data from "./modules/data";
import recruitsStore from "./modules/recruits";
import scout from "./modules/scout";
import auth from "./modules/auth";
import ads from "./modules/ads";
import storage from "./modules/storage";
import permissions from "./modules/permissions";
import permissionsColumns from "./modules/permissionsColumns";
import emailTemplates from "./modules/emailTemplates";
import emailTemplatesColumns from "./modules/emailTemplatesColumns";
import invitations from "./modules/invitations";
import recruitStatesLogs from "./modules/recruitStatesLogs";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    requestsCTS: new Map(),
    userFetched: false,
    userFetchPromise: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      this.dispatch("saveUser", user);
    },
    setToken(state, token) {
      state.token = token;
      this.dispatch("saveToken", token);
    },
    setRequestsCTS(state, { request, source }) {
      state.requestsCTS.set(request, source);
    },
    setUserFetched(state, userFetched) {
      state.userFetched = userFetched;
    },
    setUserFetchPromise(state, promise) {
      state.userFetchPromise = promise;
    },
  },
  getters: {
    user: (state) => state.user,
    statics: (state) => state.statics,
    token: (state) => state.token,
    isLoggedIn: (state) => state.token && state.token !== "null",
    haveHRAccess: (state) => state.user.accesses.includes("HR"),
    getTokenSourceByRequest: (state) => (token) => {
      return state.requestsCTS.get(token);
    },
    userFetchPromise: (state) => state.userFetchPromise,
  },
  actions: {
    fetchUserInfo({ commit }) {
      if (this.getters.isLoggedIn) {
        const user = localStorage.getItem("user");
        try {
          commit("setUser", JSON.parse(user));
        } catch (e) {
          //
        }
        const userFetchPromise = new Promise((resolve, reject) => {
          axios({ method: "get", url: "/user" })
            .then((resp) => {
              commit("setUser", resp.data);
              commit("setUserFetched", true);
              resolve(resp.data);
            })
            .catch((e) => reject(e));
        });

        commit("setUserFetchPromise", userFetchPromise);
      }
    },
    fetchSyncedUser({ state }) {
      return new Promise((resolve) => {
        if (state.userFetched) {
          resolve(state.user);
        } else {
          state.userFetchPromise.then((user) => resolve(user));
        }
      });
    },
    saveUser({ state }) {
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    logout({ commit }) {
      axios({ method: "post", url: "/auth/logout" });
      commit("setUser", "");
      commit("setToken", "");
    },
    fetchToken({ commit }) {
      const token = localStorage.getItem("accessToken");
      commit("setToken", token);
    },
    saveToken({ state }) {
      localStorage.setItem("accessToken", state.token);
    },
    deleteRecurit({ dispatch }, recruit) {
      return axios.delete(`/recruits/${recruit.id}`).then(() => {
        dispatch("recruits/fetchData", true);
      });
    },
    generateNewRequestCTS({ commit, getters }, request) {
      const perviouseCTS = getters.getTokenSourceByRequest(request);
      if (perviouseCTS) {
        perviouseCTS.cancel();
      }
      const currentCTS = axios.CancelToken.source();
      commit("setRequestsCTS", {
        request,
        source: currentCTS,
      });

      return currentCTS;
    },
  },
  modules: {
    alerts,
    toasts,
    recruitmentManagerColumns,
    countriesColumns,
    adsColumns,
    data,
    recruits: recruitsStore,
    scout,
    auth,
    ads,
    storage,
    permissions,
    permissionsColumns,
    emailTemplates,
    emailTemplatesColumns,
    invitations,
    recruitStatesLogs,
  },
});
