<template>
  <main>
    <div class="y w-full gap-y-5 px-0 md:px-24">
      <div class="relative w-full y md:x gap-x-3 items-end justify-end">
        <div
          class="w-4/12 md:static absolute top-0 left-0 pb-0 md:pb-5 x gap-x-5 items-center"
        ></div>
        <div class="w-full md:w-6/12 pt-3">
          <field-item :label="''" class="justify-self-end">
            <template #input>
              <div class="x border rounded-md bg-white items-center pr-3">
                <input
                  type="text"
                  v-debounce:200="(val) => (filters.search = val)"
                  :placeholder="'Search'"
                  class="shemailTemplateow-none flex-1 border-none focus:outline-none"
                />
                <i class="isax isax-search-normal text-xl text-gray-400" />
              </div>
            </template>
          </field-item>
        </div>
        <div class="w-3/12 x justify-end md:static absolute top-0 right-0 pb-0 md:pb-3">
          <button-primary class="h-8 w-28" @click.native="showEmailTemplatedModal = true"
            >Add new</button-primary
          >
        </div>
      </div>

      <data-table class="mt-2 table-fixed">
        <template #header>
          <selectable-columns
            :showedColumns="showedColumns"
            :allColumns="allColumns"
            :setShowedColumns="setShowedColumns"
            :searchColumns="filters.search_scope"
            @updateSearchColumns="(columns) => (filters.search_scope = columns)"
            :orderBy="filters.order_by"
            :orderDirection="filters.order_direction"
            @updateOrder="
              (o) => {
                filters.order_by = o.orderBy;
                filters.order_direction = o.orderDirection;
              }
            "
            @updateShowedColumns="(val) => (showedColumns = val)"
          />
        </template>

        <template #body>
          <tr
            v-for="emailTemplate in emailTemplates"
            :key="emailTemplate.id"
            class="hover:bg-white cursor-pointer"
          >
            <data-table-cell v-for="column in showedColumns" :key="column" class="bg-transparent">
              <router-link
                class="text-sm"
                v-if="column === 'ID' || column === 'Name'"
                :to="{ name: 'EmailTemplateProfile', params: { id: emailTemplate.id } }"
                :class="{
                  'hover:font-bold cursor-pointer whitespace-nowrap w-52': column === 'Name',
                }"
                >{{ getData(emailTemplate, column) }}</router-link
              >
              <input
                v-else
                :value="getData(emailTemplate, column)"
                @change="(ev) => setData(emailTemplate, column, ev.target.value)"
                class="py-1 border-none"
              />
            </data-table-cell>
            <data-table-cell>
              <i
                @click="confirmDelete(emailTemplate)"
                class="isax isax-trash pl-2 cursor-pointer"
                title="delete"
              />
            </data-table-cell>
          </tr>
        </template>
      </data-table>
      <infinite-loader
        :disabled="settings.reachedEnd"
        :loemailTemplateing="settings.loemailTemplateing"
        @loemailTemplateMore="fetchData"
      />
    </div>
    <transition name="popup">
      <email-template-add-new
        v-if="showEmailTemplatedModal"
        @close="showEmailTemplatedModal = false"
      />
    </transition>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import FieldItem from "../components/FieldItem.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import DataTable from "../components/DataTable.vue";
import DataTableCell from "../components/DataTableCell.vue";
import InfiniteLoader from "../components/InfiniteLoader.vue";
import SelectableColumns from "../components/SelectableColumns.vue";
import utils from "../utils/utils";
import alerts from "../utils/alerts";
import EmailTemplateAddNew from "../components/EmailTemplateAddNew.vue";

export default {
  components: {
    FieldItem,
    ButtonPrimary,
    DataTable,
    DataTableCell,
    InfiniteLoader,
    SelectableColumns,
    EmailTemplateAddNew,
  },
  data() {
    return {
      showEmailTemplatedModal: false,
    };
  },
  mounted() {
    this.fetchData(true);
    this.fetchShowedColumns();
  },
  methods: {
    setData(emailTemplate, column, value) {
      if (column === "ID") return;
      if (this.allColumns[column]) {
        let col = this.allColumns[column] ?? column;

        const colWithObject = col.split(".");
        if (colWithObject.length === 2) {
          col = `${colWithObject[0]}_id`;
        }
        this.setFromKey(emailTemplate, col, value);
        this.update(emailTemplate);
      }
    },
    getData(recruit, column) {
      if (column === "ID") return recruit.id;
      return this.getFromKey(recruit, this.allColumns[column]);
    },
    getFromKey(data, key) {
      return this.fromKey(data, key);
    },
    setFromKey(data, key, val) {
      this.fromKey(data, key, val);
    },
    fromKey(data, key, set = null) {
      return utils.fromKey(data, key, set);
    },
    async update(emailTemplate) {
      await this.$store.dispatch("emailTemplates/update", emailTemplate);
    },
    async confirmDelete(template) {
      const confirmed = await alerts.showConfirm({
        title: "Delete Email Template",
        message: "Are you sure you want to delete this email template?",
      });
      if (!confirmed) return;

      this.delete(template).then(() => {
        this.fetchData(true);
      });
    },
    ...mapActions("emailTemplates", ["fetchData", "delete"]),
    ...mapMutations("emailTemplates", { setEmailTemplates: "setData" }),
    ...mapActions("emailTemplatesColumns", ["fetchShowedColumns"]),
    ...mapMutations("emailTemplatesColumns", ["setShowedColumns"]),
  },
  computed: {
    emailTemplates: {
      get() {
        return this.$store.state.emailTemplates.data;
      },
      set(val) {
        this.setEmailTemplates(val);
      },
    },
    showedColumns: {
      get() {
        return this.$store.state.emailTemplatesColumns.showedColumns;
      },
      set(val) {
        this.setShowedColumns(val);
      },
    },
    ...mapGetters("emailTemplatesColumns", ["allColumns"]),
    ...mapGetters("emailTemplates", ["filters", "settings"]),
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        if (Object.keys(val).length > 0) this.fetchData(true);
      },
    },
  },
};
</script>

<style></style>
