import axios from "axios";

const data = [
  { name: "countries", path: "/countries" },
  { name: "positions", path: "/positions" },
  { name: "employmentTypes", path: "/employment-type" },
  { name: "recuritStates", path: "/recurit-position-state" },
  { name: "currencies", path: "/currencies" },
  { name: "languages", path: "/languages" },
  { name: "education", path: "/education" },
  { name: "experiences", path: "/experiences" },
  { name: "stacks", path: "/stacks" },
  { name: "contactHolders", path: "/contact-holders" },
  { name: "platforms", path: "/platforms" },
  { name: "cities", path: "/cities" },
  { name: "advertisers", path: "/advertisers" },
  { name: "adStates", path: "/ad-states" },
  { name: "permissions", path: "/permissions" },
  { name: "emailTemplates", path: "/email-templates" },
  { name: "ads", path: "/ads/all" },
];

const getState = () => {
  const states = {};
  data.forEach((item) => {
    states[item.name] = [];
  });
  return states;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getMutations = () => {
  const mutations = {};
  data.forEach((item) => {
    mutations[`set${capitalizeFirstLetter(item.name)}`] = (state, value) => {
      state[item.name] = value;
    };
  });
  return mutations;
};

const getActions = () => {
  const mutations = {};
  data.forEach((item) => {
    mutations[`fetch${capitalizeFirstLetter(item.name)}`] = ({ commit }) => {
      return axios.get(item.path).then((resp) => {
        commit(`set${capitalizeFirstLetter(item.name)}`, resp.data);
      });
    };
  });
  return mutations;
};

const store = {
  namespaced: true,
  state: {
    ...getState(),
  },
  getters: {
    abilities: (state) => state.permissions?.abilities ?? [],
    roles: (state) => state.permissions?.roles ?? [],
  },
  mutations: {
    ...getMutations(),
  },
  actions: {
    ...getActions(),
    fetchEmailTemplates({ commit }) {
      const emailTemplates = [];
      const getPage = (page = 1) => {
        return new Promise((resolve) => {
          axios.get(`email-templates?page=${page}`).then((resp) => {
            const respData = resp.data;
            emailTemplates.push(...respData.data);
            if (respData.meta.last_page === page) {
              resolve(emailTemplates);
            } else {
              getPage(page + 1).then((d) => resolve(d));
            }
          });
        });
      };
      getPage().then((d) => {
        commit(`setEmailTemplates`, d);
      });
    },
  },
};

export default store;
