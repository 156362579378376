<template>
  <main>
    <div class="w-full md:px-40 pt-5 y gap-y-5 items-center">
      <div class="w-full x gap-x-5 items-center">
        <div class="w-max x items-center text-gray-400">
          <router-link :to="{ name: 'EmailTemplates' }" class="pt-0.5">
            <i
              class="isax isax-arrow-left-3 cursor-pointer text-sm 2xl:text-2xl sm:text-xl hover:text-brand mr-2"
            />
          </router-link>
          <span class="whitespace-nowrap text-xs 2xl:text-xl sm:text-base">
            Email Sender - Template
          </span>
        </div>
        <div class="flex-1 bg-gray-200" style="height: 1.5px"></div>
      </div>

      <div class="w-full" v-if="!loading">
        <email-template-form
          ref="form"
          v-model="item"
          :edit="true"
          style="overflow-y: hidden !important"
        >
          <template #before-attachments>
            <div class="x w-full">
              <button-secondary @click.native="confirmDelete" class="bg-white" :color="'#F12B2C'"
                >Delete Profile</button-secondary
              >

              <div class="x flex-1 items-end justify-end gap-x-4">
                <button-primary @click.native="submit" :loading="submitting" class="md:w-24"
                  >Save</button-primary
                >
                <router-link :to="{ name: 'EmailTemplates' }">
                  <button-secondary class="text-xs md:w-24 bg-white" :color="'#000'"
                    >Cancel</button-secondary
                  >
                </router-link>
              </div>
            </div>
          </template>
        </email-template-form>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import alerts from "../utils/alerts";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import ButtonSecondary from "../components/ButtonSecondary.vue";
import EmailTemplateForm from "../components/EmailTemplateForm.vue";

export default {
  components: { ButtonPrimary, ButtonSecondary, EmailTemplateForm },
  data() {
    const item = {
      name: null,
      cc: null,
      to: null,
      subject: null,
      message: null,
      attachments: [],
    };

    return {
      item,
      loading: true,
      submitting: false,
    };
  },
  mounted() {
    this.fetchTemplate();
  },
  methods: {
    fetchTemplate() {
      return this.axios
        .get(`/email-templates/${this.$route.params.id}`)
        .then((resp) => {
          this.item = resp.data;
          Object.assign(this.item, {
            deleted_attachments: [],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.go(-1);
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      this.submitting = true;
      this.update(this.item).finally(() => {
        this.submitting = false;
      });
    },
    async confirmDelete() {
      const confirmed = await alerts.showConfirm({
        title: "Delete Email Template",
        message: "Are you sure you want to delete this Email Template?",
      });
      if (!confirmed) return;

      this.delete(this.item).then(() => {
        this.back();
      });
    },
    ...mapActions("emailTemplates", ["fetchData", "update", "delete"]),
  },
};
</script>

<style></style>
