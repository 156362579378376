<template>
  <div class="mx-auto w-full py-4 px-6 flex-1 y gap-y-2 overflow-y-scroll">
    <div class="y xl:x gap-x-6">
      <field-item :label="'Name'" :required="true">
        <template #input>
          <validated-input
            :errors="errors ? errors['name'] : []"
            class="md:flex-1"
            :value="value.name"
            @input="(val) => update('name', val)"
          />
        </template>
      </field-item>

      <div class="y xl:x gap-x-6 flex-1">
        <field-item :label="'Email'">
          <template #input>
            <validated-input
              :errors="errors ? errors['email'] : []"
              class="md:flex-1"
              :value="value.email"
              @input="(val) => update('email', val)"
            />
          </template>
        </field-item>

        <field-item :label="'Gitlab ID'">
          <template #input>
            <validated-input
              :errors="errors ? errors['gitlab_id'] : []"
              class="md:flex-1"
              :value="value.gitlab_id"
              @input="(val) => update('gitlab_id', val)"
            />
          </template>
        </field-item>
      </div>
    </div>

    <div class="y xl:x gap-x-6">
      <field-item :label="'Created At'" :required="true">
        <template #input>
          <date-picker
            :clearable="false"
            type="datetime"
            valueType="format"
            format="YYYY-MM-DD HH:mm"
            class="w-full disabled:bg-gray-100"
            :value="value.created_at"
            @input="(val) => update('created_at', val)"
          />
        </template>
      </field-item>

      <field-item :label="'Updated At'">
        <template #input>
          <date-picker
            :clearable="false"
            type="datetime"
            valueType="format"
            format="YYYY-MM-DD HH:mm"
            class="w-full disabled:bg-gray-100"
            :value="value.updated_at"
            @input="(val) => update('updated_at', val)"
          />
        </template>
      </field-item>
    </div>

    <div v-for="(part, index) in ['Roles', 'Permissions']" :key="index">
      <h1 class="text-lg font-bold my-3">{{ part }}</h1>
      <div class="grid grid-cols-1 md:grid-cols-3">
        <field-item
          v-for="(field, index) in part === 'Roles' ? rolesColumns : abilitiesColumns"
          :key="index"
          :label="part === 'Roles' ? toTitleCase(field) : getAbilityName(field)"
        >
          <template #input>
            <toggle-button
              :value="value[field]"
              @input="(val) => update(field, val)"
              class="py-2"
            />
          </template>
        </field-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import * as Validator from "validatorjs";
import FieldItem from "./FieldItem.vue";
import ValidatedInput from "./ValidatedInput.vue";
import "vue2-datepicker/index.css";
import ToggleButton from "./ToggleButton.vue";

export default {
  props: {
    value: {
      Object,
      default: () => ({}),
    },
    errors: {
      Object,
      default: () => ({}),
    },
    edit: Boolean,
  },
  data() {
    return {
      validator: null,
    };
  },
  components: {
    DatePicker,
    FieldItem,
    ValidatedInput,
    ToggleButton,
  },
  mounted() {
    if (this.abilities.length === 0 || this.roles.length === 0) {
      this.fetchPermissions();
    }
  },
  methods: {
    preventEmpty(e) {
      if (e.target.value === "") {
        e.target.value = 0;
      }
    },
    update(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    async validate() {
      this.validator = new Validator(this.value, {
        name: "required",
        email: "required",
        gitlab_id: "required",
      });

      if (this.validator.passes()) {
        this.errors = null;
      } else this.errors = this.validator.errors.all();

      return this.validator.passes();
    },
    getAbilityName(a) {
      const abilities = {
        view_recruits: "View Recruits",
        update_own_recruits: "Update / Delete own Recruits",
        add_recruits: "Add Recruits",
        update_others_recruits: "Update / Delete other's Recruits",
        send_email: "Send Email",
        create_email_template: "Create Email Template",
      };

      return abilities[a];
    },
    toTitleCase(s) {
      return s
        .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
        .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);
    },
    ...mapActions("data", ["fetchPermissions"]),
  },
  computed: {
    ...mapGetters("data", ["abilities", "roles"]),
    ...mapGetters("permissions", ["permissionsColumns", "abilitiesColumns", "rolesColumns"]),
  },
};
</script>

<style></style>
