<template>
  <div class="y xl:x gap-y-2 gap-x-2 w-full" style="min-height: 100px">
    <div class="w-full md:w-3/12 h-full y">
      <h1 class="font-bold text-xl">Attachments</h1>
      <p v-if="attachments.length > 0">{{ attachments.length }} Files . {{ totalSize }}</p>
      <label :for="label">
        <div
          class="x justify-center md:justify-start items-center gap-x-1 pt-3 cursor-pointer"
          style="color: #253dd9"
        >
          <i class="isax isax-export text-lg" />
          <span>Upload more files</span>
        </div>
      </label>
    </div>
    <slot />
  </div>
</template>

<script>
import utils from "../utils/utils";

export default {
  props: {
    label: String,
    attachments: Array,
  },

  computed: {
    totalSize() {
      const bytes = this.attachments.reduce(function (a, b) {
        return a + (b.pivot?.size ?? b.size);
      }, 0);
      return utils.getSize(bytes);
    },
  },
};
</script>

<style></style>
