<template>
  <pop-up-modal
    @close="$emit('close')"
    :width="$mq === 'sm' ? '100%' : '75%'"
    :height="$mq === 'sm' ? '100%' : '80%'"
  >
    <ad-form ref="form" v-model="item" />

    <template #actionButton>
      <button-primary @click.native="submit" :loading="submitting"> Save </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import { mapActions } from "vuex";
import AdForm from "./AdForm.vue";
import ButtonPrimary from "./ButtonPrimary.vue";
import PopUpModal from "./PopUpModal.vue";

export default {
  components: { PopUpModal, ButtonPrimary, AdForm },
  data() {
    const item = {
      start_date: null,
      end_date: null,
      cost: 0,
      last_checked_at: null,
      notes: null,
      platform_id: null,
      advertiser: null,
      cities: [],
      currency_id: null,
      country_id: null,
      state: null,
      positions: [],
      no_city: false,
    };

    return {
      item,
      submitting: false,
    };
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      await this.add();
      this.$emit("close");
    },
    async add() {
      this.submitting = true;

      return this.addAd(this.item).finally(() => {
        this.submitting = false;
      });
    },
    ...mapActions("ads", ["fetchData", "addAd"]),
  },
};
</script>

<style></style>
