import Storage from "../../utils/storage";

export default {
  namespaced: true,

  getters: {
    token: (state, getters, rootState, rootGetters) => rootGetters.token,
    remoteStorage: (state, getters) => new Storage(getters.token, process.env.VUE_APP_STORAGE_URL),
  },
  actions: {
    uploadFile: ({ getters }, { file, type, onUploadProgress, token }) =>
      getters.remoteStorage.upload(file, type, onUploadProgress, token),
    uploadAndFinishFile: ({ getters }, { file, type, onUploadProgress, token }) =>
      getters.remoteStorage.uploadAndFinish(file, type, onUploadProgress, token),
    finishFile: ({ getters }, { id, type }) => getters.remoteStorage.finish(id, type),
    abortFile: ({ getters }, { id, type }) => getters.remoteStorage.abort(id, type),
    downloadFile: ({ getters }, { id, type }) => getters.remoteStorage.download(id, type),
  },
};
