<template>
  <div class="y gap-y-2">
    <drag-and-drop-file-selector
      :class="{ 'bg-white border-none shadow-md': edit }"
      @change="filesSelected"
      :id="'recruit-attachments222'"
    >
      <template #default="{ label }">
        <attachments-list :attachments="value" :label="label" v-if="edit">
          <div class="flex-1 x gap-2 flex-wrap h-full">
            <attachment
              class="w-64"
              v-for="(file, index) of value"
              :key="index"
              :title="file.name"
              :bytes="getFileSize(file)"
              :showDownload="file.id !== null"
              @download="downloadAttachment(file)"
              :canPreview="edit && isAttachmentPreviewable(file)"
              @preview="previewed = file"
              :isPreviewing="previewed ? previewed.id === file.id : false"
              @delete="removeAttachment(file)"
            />
            <attachment
              class="w-64"
              v-for="file of uploadingAttachments"
              :key="file.key"
              :title="file.name"
              :bytes="file.size"
              :uploading="true"
              :progress="file.progress"
              @delete="abortUploading(file)"
              :converting="file.progress === 100 && file.type === 'video'"
              v-show="file.uploading"
            />
          </div>
        </attachments-list>
      </template>
    </drag-and-drop-file-selector>

    <div class="x gap-2 flex-wrap md:px-3" v-if="!edit">
      <attachment
        class="w-full md:w-64"
        v-for="(file, index) of value"
        :key="index"
        :title="file.name"
        :bytes="file.size"
        @delete="removeAttachment(file)"
      />
      <attachment
        class="w-64"
        v-for="file of uploadingAttachments"
        :key="file.key"
        :title="file.name"
        :bytes="file.size"
        :uploading="file.uploading"
        :progress="file.progress"
        :converting="file.progress === 100 && file.type === 'video'"
        @delete="abortUploading(file)"
        v-show="file.uploading"
      />
    </div>
    <attachment-preview class="pt-5" :attachment="previewed" />
  </div>
</template>

<script>
import Attachment from "./Attachment.vue";
import AttachmentsList from "./AttachmentsList.vue";
import DragAndDropFileSelector from "./DragAndDropFileSelector.vue";
import {
  isFileSuported,
  downloadAttachment,
  isAttachmentPreviewable,
  fileToUploadableFormat,
  uploadFiles,
} from "../utils/attachments";
import alerts from "../utils/alerts";
import AttachmentPreview from "./AttachmentPreview.vue";

export default {
  components: { DragAndDropFileSelector, AttachmentsList, Attachment, AttachmentPreview },
  props: {
    value: Array,
    edit: Boolean,
  },
  data() {
    return {
      uploadingAttachments: [],
      previewed: null,
    };
  },
  mounted() {
    if (this.edit) {
      const canBePreviewed = this.value.find(this.isAttachmentPreviewable);
      if (canBePreviewed) {
        this.previewed = canBePreviewed;
      }
    }
  },
  methods: {
    update(files) {
      this.$emit("input", files);
    },
    isFileSuported(file) {
      return isFileSuported(file);
    },
    getFileSize(file) {
      return file.pivot?.size ?? file.size;
    },
    async downloadAttachment(attachment) {
      downloadAttachment(attachment);
    },
    isAttachmentPreviewable(attachment) {
      return isAttachmentPreviewable(attachment);
    },
    async removeAttachment(attachment) {
      const confirmed = await alerts.showConfirm({
        title: "Delete Attachment",
        message: "Are you sure you want to delete this attachment?",
      });
      if (!confirmed) return;

      await this.abort(attachment);
      this.update(this.value.filter((a) => a.id !== attachment.id));
    },
    abort(multimedia) {
      return this.$abortFile(multimedia);
    },
    abortUploading(file) {
      file.token.cancel();
      Object.assign(file, { uploading: false });
    },
    async filesSelected(files) {
      const uploadingAttachments = Array.from(files)
        .filter((file) => {
          const isSuported = this.isFileSuported(file);
          if (!isSuported) {
            alerts.showMessage(
              `The file ${file.name} is not in supported format.`,
              file.name,
              true,
            );
          }
          return isSuported;
        })
        .map(fileToUploadableFormat);
      this.uploadingAttachments.push(...uploadingAttachments);
      const findWithKey = (key) => (a) => a.key === key;
      uploadFiles({
        uploadingFiles: this.uploadingAttachments,
        onFileProgress: (key, progress) => {
          this.uploadingAttachments.find(findWithKey(key)).progress = progress;
        },
        onFileSuccess: (key, multimedia) => {
          this.update([...this.value, multimedia]);
        },
        onFileFinish: (key) => {
          this.uploadingAttachments.find(findWithKey(key)).uploading = false;
          this.uploadingAttachments.splice(
            this.uploadingAttachments.findIndex(findWithKey(key)),
            1,
          );
        },
      });
    },
  },
};
</script>

<style></style>
