import axios from "axios";

const getColumnsStore = (storageKey, apiPath, modulePath) => ({
  namespaced: true,
  state: {
    allColumns: [],
    showedColumns: [],
  },
  getters: {
    allColumns: (state) => state.allColumns,
    showedColumns: (state) => {
      return state.showedColumns;
    },
  },
  mutations: {
    setAllColumns(state, allColumns) {
      state.allColumns = allColumns;
    },
    setShowedColumns(state, showedColumns) {
      state.showedColumns = showedColumns;
      this.dispatch(`${modulePath}/saveShowedColumns`);
    },
    setShowedColumnsFromStorage(state, showedColumns) {
      const columns = Object.keys(showedColumns)
        .filter((col) => {
          return state.allColumns[col] || col === "ID";
        })
        .sort((a, b) => showedColumns[a] - showedColumns[b]);
      state.showedColumns = columns;

      this.dispatch(`${modulePath}/saveShowedColumns`);
    },
  },
  actions: {
    fetchColumns({ commit }) {
      return axios.get(apiPath).then((response) => {
        commit("setAllColumns", response.data);
      });
    },
    async fetchShowedColumns({ commit, dispatch, state }) {
      await dispatch("fetchColumns");
      const showedColumns = localStorage.getItem(storageKey);
      try {
        const columns = JSON.parse(showedColumns);
        if (Object.keys(columns).length > 0) commit("setShowedColumnsFromStorage", columns);
        else throw Error;
      } catch (e) {
        console.log(e);

        let index = 0;
        const arr = Object.keys(state.allColumns);
        const columns = ["ID", ...arr.filter((c) => c !== "ID")].slice(0, 7).reduce((obj, item) => {
          index += 1;
          return Object.assign(obj, { [item]: index });
        }, {});

        commit("setShowedColumnsFromStorage", columns);
      }
    },
    saveShowedColumns({ state }) {
      const columns = {};
      state.showedColumns.forEach((col, index) => {
        columns[col] = index;
      });
      localStorage.setItem(storageKey, JSON.stringify(columns));
    },
  },
});

export default getColumnsStore;
