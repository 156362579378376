import axios from "axios";
import getListStore from "./list";

const listStore = getListStore("recruits", {
  order_by: null,
  order_direction: null,
  search: null,
  search_scope: [],
  filters: [],
  only_active: true,
});

const store = {
  ...listStore,
  state: {
    ...listStore.state,
    checkingCopies: false,
    copies: [],
  },
  getters: {
    ...listStore.getters,
    checkingCopies: (state) => state.checkingCopies,
    copies: (state) => state.copies,
  },
  mutations: {
    ...listStore.mutations,
    setCheckingCopies(state, checkingCopies) {
      state.checkingCopies = checkingCopies;
    },
    setCopies(state, copies) {
      state.copies = copies;
    },
  },
  actions: {
    ...listStore.actions,
    async checkCopies({ commit, dispatch }, recruit) {
      commit("setCheckingCopies", true);

      const { token: cancelToken } = await dispatch("generateNewRequestCTS", "checkCopies", {
        root: true,
      });
      return axios
        .post(
          "/recruits/check-copies",
          {
            ...recruit,
          },
          {
            cancelToken,
          },
        )
        .then((resp) => {
          commit("setCheckingCopies", false);
          commit("setCopies", resp.data.data);
        });
    },
  },
};

export default store;
