<template>
  <main>
    <div class="y md:x gap-x-5 w-10/12">
      <div
        v-if="country"
        class="y pl-5 md:pl-20 w-full md:w-8/12 gap-y-5 pt-16 m-auto"
        style="min-height: 80vh"
      >
        <router-link :to="{ name: 'Scout' }">
          <div
            class="x gap-x-4 text-2xl items-center cursor-pointer text-gray-400 hover:text-brand"
          >
            <i class="isax isax-arrow-left-3" />
            <span>Go Back</span>
          </div>
        </router-link>

        <div class="y py-8 gap-y-3 font-light" style="color: #222222">
          <p class="x gap-x-3">
            <b class="font-bold">Country:</b> <span> {{ country.name }}</span>
          </p>
          <p class="x gap-x-3">
            <b class="font-bold">Cities:</b> <span> {{ country.cities }}</span>
          </p>
          <p class="x gap-x-3">
            <b class="font-bold">Recruiters:</b>
            <span> {{ country.recruiters }}</span>
          </p>
          <p class="x gap-x-3">
            <b class="font-bold">Average Initial Desired Salary:</b>
            <span> {{ roundNumber(country.average_initial_desired_salary) }} EUR / hour</span>
          </p>
          <p class="x gap-x-3">
            <b class="font-bold">Importance:</b>
            <span :style="{ color: importanceColor }"> {{ country.importance }}</span>
          </p>
          <div class="x gap-x-6">
            <!-- TODO: sync it with the production module -->
            <!-- <p class="x gap-x-2 font-bold items-center">
              <span>Employees:</span> <span> 0</span>
              <i class="isax isax-arrow-down-1 text-lg" />
            </p> -->
            <p class="x gap-x-2 items-center text-orange">
              <span>Applicants:</span> <span> {{ country.applicants }}</span>
              <i class="isax isax-arrow-up-2 text-lg" />
            </p>
          </div>
        </div>

        <div class="x gap-x-4 w-full">
          <department-button
            class="flex-1"
            v-for="(department, index) in departments"
            :key="index"
            :active="isDepartmentSelected(department)"
            @click.native="
              selectedDepartment = isDepartmentSelected(department) ? null : department
            "
          >
            {{ department.name }}
          </department-button>
        </div>

        <div class="x gap-x-10 justify-end w-full pr-5">
          <div
            @click="sortDirection = 'asc'"
            class="x items-center justify-center gap-x-2 cursor-pointer"
            :class="{ 'text-orange': sortDirection === 'asc' }"
          >
            <i class="isax isax-arrow-swap-horizontal text-base" />
            <span class="text-xs">A to Z</span>
          </div>

          <div
            @click="sortDirection = 'desc'"
            class="x items-center justify-center gap-x-2 cursor-pointer"
            :class="{ 'text-orange': sortDirection === 'desc' }"
          >
            <i class="isax isax-arrow-swap-horizontal text-base" />
            <span class="text-xs">Z to A</span>
          </div>
        </div>

        <div class="x gap-x-4 w-full pt-5">
          <div class="h-full w-40 y gap-y-3" v-if="selectedDepartment">
            <radio-button
              v-for="sector in selectedDepartment.sectors"
              :key="sector.id"
              :id="'s-' + sector.id"
              :label="sector.name"
              :value="selectedSector && selectedSector.id === sector.id"
              @input="selectedSector = sector"
            />
            <radio-button
              :id="'showAll'"
              :label="'Show All'"
              @input="selectedSector = null"
              :value="selectedSector === null"
            />
          </div>

          <div class="flex-1">
            <div class="grid grid-cols-4 gap-x-4 min-w-max">
              <div
                class="x gap-x-2 grid-element py-1.5 pl-2"
                v-for="recruit in recrutis"
                :key="recruit.id"
              >
                <p class="flex-1 y gap-y-1">
                  <b>{{ recruit.name }}</b>
                  <span class="text-xs" style="color: #444444">{{
                    recruit.positions[0].name
                  }}</span>
                </p>
                <router-link :to="{ name: 'Profile', params: { id: recruit.id } }">
                  <span class="underline" style="font-size: 8px; color: #253dd9"
                    >Show Applicant</span
                  >
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="country"
        class="w-full pb-5 md:w-4/12 y font-medium gap-y-5 items-center justify-end"
        style="height: 90vh"
      >
        <country-s-v-g :code="country.code" :color="importanceColor" />

        <div class="w-full y gap-y-2.5" style="padding-left: 28%">
          <b class="text-lg" :style="{ color: importanceColor }">{{ country.name }}</b>
          <p>Capital: {{ country.capital }}</p>
          <p>Population: {{ convertToInternationalCurrencySystem(country.population) }}</p>
          <p>Area: {{ convertToInternationalCurrencySystem(country.area) }} km2</p>
          <p>GDP per capital: {{ roundNumber(country.gdp_per_capital) }}$</p>
          <p>Official Language: {{ country.offical_language }}</p>
          <p>Currency: {{ country.currency }}</p>
          <p>Time-zone: {{ country.time_zone }}</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import CountrySVG from "../components/CountrySVG.vue";
import DepartmentButton from "../components/DepartmentButton.vue";
import RadioButton from "../components/RadioButton.vue";
import countryUtils from "../utils/countries";

export default {
  components: {
    DepartmentButton,
    RadioButton,
    CountrySVG,
  },
  data() {
    return {
      country: null,
      departments: [],
      recrutis: [],
      selectedDepartment: null,
      selectedSector: null,
      sortDirection: "desc",
    };
  },
  async mounted() {
    await this.fetchCountry(this.$route.params.id);
    this.fetchDepartments();
  },
  methods: {
    fetchCountry(id) {
      return this.axios.get(`/countries/${id}`).then((resp) => {
        this.country = resp.data.country;
      });
    },
    fetchDepartments() {
      return this.axios.get(`/departments`).then((resp) => {
        this.departments = resp.data;
        this.fetchRecruits();
      });
    },
    fetchRecruits() {
      return this.axios
        .get(`/recruits/countries/${this.country.id}`, {
          params: {
            department_id: this.selectedDepartment?.id,
            sector_id: this.selectedSector?.id,
            sort_direction: this.sortDirection,
          },
        })
        .then((resp) => {
          this.recrutis = resp.data;
        });
    },
    isDepartmentSelected(dep) {
      return this.selectedDepartment && this.selectedDepartment.id === dep.id;
    },
    convertToInternationalCurrencySystem(labelValue) {
      if (Math.abs(Number(labelValue)) >= 1.0e9)
        return `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(2)}billion`;
      if (Math.abs(Number(labelValue)) >= 1.0e6)
        return `${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(2)}million`;
      if (Math.abs(Number(labelValue)) >= 1.0e3)
        return `${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(2)}thousand`;
      return Math.abs(Number(labelValue));
    },
    roundNumber(number) {
      if (!number) return null;
      return Math.round(number);
    },
  },
  computed: {
    importanceColor() {
      if (!this.country) return null;
      return countryUtils.getCountryProfileColor(this.country.importance);
    },
  },
  watch: {
    selectedDepartment() {
      this.fetchRecruits();
    },
    selectedSector() {
      this.fetchRecruits();
    },
    sortDirection() {
      this.fetchRecruits();
    },
  },
};
</script>

<style scoped>
.grid >>> .grid-element {
  border-left: 1px solid #e5e7eb;
}
</style>
