var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto w-full py-4 px-6 flex-1 y gap-y-2 overflow-y-scroll"},[_c('div',{staticClass:"y xl:x gap-x-6"},[_c('field-item',{attrs:{"label":'Name',"required":true},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('validated-input',{attrs:{"errors":_vm.errors ? _vm.errors['name'] : [],"value":_vm.value.name},on:{"input":function (val) { return _vm.update('name', val); }}})]},proxy:true}])}),_c('field-item',{attrs:{"label":'To'},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('validated-input',{attrs:{"errors":_vm.errors ? _vm.errors['to'] : [],"value":_vm.value.to},on:{"input":function (val) { return _vm.update('to', val); }}})]},proxy:true}])})],1),_c('div',{staticClass:"y xl:x gap-x-6"},[_c('field-item',{attrs:{"label":'Subject'},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('validated-input',{attrs:{"errors":_vm.errors ? _vm.errors['subject'] : [],"value":_vm.value.subject},on:{"input":function (val) { return _vm.update('subject', val); }}})]},proxy:true}])}),_c('field-item',{attrs:{"label":'CC'},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('validated-input',{attrs:{"errors":_vm.errors ? _vm.errors['cc'] : [],"value":_vm.value.cc},on:{"input":function (val) { return _vm.update('cc', val); }}})]},proxy:true}])})],1),_c('field-item',{attrs:{"label":'Message'},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('rich-text-editor',{model:{value:(_vm.value.message),callback:function ($$v) {_vm.$set(_vm.value, "message", $$v)},expression:"value.message"}})]},proxy:true}])}),_vm._t("before-attachments"),_c('drag-and-drop-file-selector',{class:{ 'bg-white border-none shadow-md': _vm.edit },on:{"change":_vm.filesSelected},scopedSlots:_vm._u([(_vm.edit)?{key:"default",fn:function(ref){
var label = ref.label;
return [_c('attachments-list',{attrs:{"attachments":_vm.value.attachments,"label":label}},[_c('div',{staticClass:"flex-1 x gap-2 flex-wrap h-full"},[_vm._l((_vm.value.attachments),function(file,index){return _c('attachment',{key:index,staticClass:"w-64",attrs:{"title":file.name,"bytes":_vm.getFileSize(file),"showDownload":_vm.edit && file.id,"canPreview":_vm.isAttachmentPreviewable(file),"isPreviewing":_vm.previewed ? _vm.previewed.id === file.id : false},on:{"download":function($event){return _vm.downloadAttachment(file)},"preview":function($event){_vm.previewed = file},"delete":function($event){return _vm.removeAttachment(file)}}})}),_vm._l((_vm.uploadingAttachments),function(file){return _c('attachment',{directives:[{name:"show",rawName:"v-show",value:(file.uploading),expression:"file.uploading"}],key:file.key,staticClass:"w-64",attrs:{"title":file.name,"bytes":_vm.getFileSize(file),"uploading":true,"progress":file.progress,"converting":file.progress === 100 && file.type === 'video'},on:{"delete":function($event){return _vm.abortUploading(file)}}})})],2)])]}}:null],null,true)}),(!_vm.edit)?_c('div',{staticClass:"x gap-2 flex-wrap md:px-3"},_vm._l((_vm.value.attachments),function(file,index){return _c('attachment',{key:index,staticClass:"w-full md:w-64",attrs:{"title":file.name,"bytes":_vm.getFileSize(file)},on:{"delete":function($event){_vm.update(
          'attachments',
          _vm.value.attachments.filter(function (v, i) {
            return index !== i;
          })
        )}}})}),1):_vm._e(),_c('attachment-preview',{staticClass:"pt-5",attrs:{"attachment":_vm.previewed,"attachmentsPath":_vm.attachmentsPath}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }