import axios from "axios";
import { saveAs } from "file-saver";
import store from "../store/index";

export async function getAttachmentFile(attachment) {
  const response = await store.dispatch("storage/downloadFile", attachment);
  const blob = new Blob([response.data]);
  return blob;
}

export async function downloadAttachment(attachment) {
  const blob = await getAttachmentFile(attachment);
  saveAs(blob, attachment.name);
}

export async function getAttachmentPreviewURL(attachment) {
  const blob = await getAttachmentFile(attachment);
  const previewedURL = window.URL.createObjectURL(blob);
  return previewedURL;
}

function isImage(name) {
  return ["jpg", "png", "jpeg", "gif"].includes(name.split(".").pop().toLowerCase());
}

function isVideo(name) {
  return ["mp4", "webm", "mov", "wmv", "flv", "avi", "mkv", "m4v", "3gp", "3g2"].includes(
    name.split(".").pop().toLowerCase(),
  );
}

function isDocument(name) {
  return ["doc", "docx", "pdf", "xls", "xlsx", "ods", "ppt", "pptx", "txt"].includes(
    name.split(".").pop().toLowerCase(),
  );
}

export function isAttachmentPDF(attachment) {
  return attachment.name.split(".").pop().toLowerCase() === "pdf";
}

export function isAttachmentImage(attachment) {
  return isImage(attachment.name);
}

export function isAttachmentVideo(attachment) {
  return isVideo(attachment.name);
}

export function getFileType(file) {
  if (isImage(file.name)) {
    return "image";
  }

  if (isVideo(file.name)) {
    return "video";
  }

  if (file.type.includes("audio")) {
    return "audio";
  }

  if (isDocument(file.name)) {
    return "document";
  }

  return null;
}

export function isFileSuported(file) {
  return getFileType(file) !== null;
}

export function isAttachmentPreviewable(attachment) {
  return (
    isAttachmentPDF(attachment) || isAttachmentImage(attachment) || isAttachmentVideo(attachment)
  );
}

export function fileToUploadableFormat(file) {
  const token = axios.CancelToken.source();
  const key = `${new Date().getTime()}-${file.name}`;
  const type = getFileType(file);
  const uploadingFile = {
    file,
    name: file.name,
    size: file.size,
    uploading: true,
    progress: 0,
    key,
    token,
    type,
  };
  return uploadingFile;
}

export function uploadFiles({ uploadingFiles, onFileProgress, onFileSuccess, onFileFinish }) {
  const promises = uploadingFiles.map((uploadingFile) => {
    return new Promise((resolve) => {
      store
        .dispatch("storage/uploadAndFinishFile", {
          file: uploadingFile.file,
          type: uploadingFile.type,
          onUploadProgress: (e) => {
            const progress = (e.loaded / e.total) * 100;
            onFileProgress(uploadingFile.key, progress);
          },
          token: uploadingFile.token,
        })
        .then((multimedia) => {
          Object.assign(multimedia, {
            size: uploadingFile.size,
          });
          onFileSuccess(uploadingFile.key, multimedia);
          resolve();
        })
        .finally(() => {
          onFileFinish(uploadingFile.key);
        });
    });
  });
  return Promise.all(promises);
}
