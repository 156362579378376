<template>
  <div class="y items-center w-full">
    <main class="y p-3 s-6 max-w-screen-lg w-full h-full">
      <div class="container d-flex justify-content-center">
        <form class="y items-center">
          <h2 class="mb-4 mt-2 text-center" style="font-size: 24px">
            Please sign in to use the site
          </h2>
          <button-primary
            @click.native="redirectToGitlabLogin()"
            class="w-96 disabled:opacity-75 x gap-x-2.5 items-center justify-center"
            type="button"
            style="background: #fc6d26"
            :disabled="authenticating"
          >
            <img
              src="../assets/gitlab-logo.png"
              alt="Gitlab logo"
              class="d-inline-block"
              style="height: 42px"
            />
            <span class="d-inline-block ml-3">Sign in with Gitlab</span>
          </button-primary>
        </form>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import ButtonPrimary from "../components/ButtonPrimary.vue";

export default {
  components: { ButtonPrimary },
  name: "Login",
  data() {
    return {
      breadcrumbLinks: [{ name: "WebOrigo", url: "/auth/login" }],
      authenticating: false,
    };
  },
  mounted() {
    if (this.$route.query.code) {
      this.authenticateWithGitlab(this.$route.query.code);
    }
  },
  methods: {
    redirectToGitlabLogin() {
      const baseUrl = process.env.VUE_APP_BACKEND_URL;
      return window.location.replace(`${baseUrl}/auth/gitlab/redirect`);
    },

    async authenticateWithGitlab(code) {
      this.authenticating = true;
      try {
        const { data } = (
          await this.axios.get(`/auth/gitlab/callback`, {
            params: {
              code,
            },
          })
        ).data;

        this.$store.commit("setUser", data.user);
        this.$store.commit("setToken", data.token);
        this.axios.defaults.headers.common = {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "Application/json",
          Accept: "Application/json",
        };
        this.$router.push({ name: "Home" });
      } catch (e) {
        //
      } finally {
        this.authenticating = false;
      }
    },
  },
};
</script>
<style></style>
