<template>
  <div
    class="group h-20 x gap-x-2 bg-orange rounded-md pl-5 transition-colors"
    :style="{
      'background-image': uploading
        ? 'linear-gradient(90deg, rgba(255,103,0,1) 0%, rgba(255,255,255,1) ' + progress + '%)'
        : 'none',
    }"
    :class="{ 'bg-orange': !uploading, 'text-white': !uploading || progress > 75 }"
  >
    <div class="y gap-y-3 w-10/12 justify-center flex-1">
      <p class="font-semibold">{{ title }}</p>
      <span v-if="converting">Converting...</span>
      <span class="font-extralight text-xs">{{ size }}</span>
    </div>
    <div
      class="y gap-y-2 h-full items-end px-2 rounded-md justify-center w-4/12 text-xl"
      :class="{ 'bg-orange': !uploading }"
    >
      <i
        v-if="showDownload && canPreview"
        @click="isPreviewing ? null : $emit('preview')"
        class="isax isax-search-zoom-in cursor-pointer transition-all transform hover:scale-125"
        title="preview"
        :class="{ 'opacity-50': isPreviewing }"
      />
      <i
        v-if="showDownload"
        @click="$emit('download')"
        class="isax isax-arrow-down-2 cursor-pointer transition-all transform hover:scale-125"
        title="download"
      />
      <i
        @click="$emit('delete')"
        class="isax isax-minus-cirlce cursor-pointer transition-all transform hover:scale-125"
        title="delete"
      />
    </div>
  </div>
</template>

<script>
import utils from "../utils/utils";

export default {
  props: {
    title: String,
    bytes: Number,
    showDownload: Boolean,
    canPreview: Boolean,
    isPreviewing: Boolean,
    uploading: Boolean,
    progress: Number,
    converting: Boolean,
  },
  computed: {
    size() {
      const { bytes } = this;
      return utils.getSize(bytes);
    },
  },
};
</script>

<style></style>
