<template>
  <pop-up-modal
    @close="$emit('close')"
    :width="$mq === 'sm' ? '100%' : '75%'"
    :height="$mq === 'sm' ? '100%' : '80%'"
  >
    <recruit-form :errors="errors" v-model="item" />

    <template #actionButton>
      <button-primary @click.native="submit" :loading="submitting"> Save </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapMutations } from "vuex";
import { serialize } from "object-to-formdata";
import * as Validator from "validatorjs";
import ButtonPrimary from "./ButtonPrimary.vue";
import PopUpModal from "./PopUpModal.vue";
import RecruitForm from "./RecruitForm.vue";

export default {
  components: {
    ButtonPrimary,
    PopUpModal,
    RecruitForm,
  },
  data() {
    const item = {
      gender: true,
      position: {},
      positions: [],
      platform: null,
      stacks: [],
      experience: null,
      desired_salary: null,
      referral: null,
      education: null,
      week_capacity: null,
      employment_type: null,
      languages: null,
      notes: null,
      start_on: null,
      application_date: DateTime.now().toFormat("yyyy-LL-dd"),
      currency_id: null,
      experience_id: null,
      education_id: null,
      platform_id: null,
      attachments: [],
      haveAge: true,
      residenceCountrySameAsOrigin: true,
      states: new Map(),
    };

    return {
      item,
      errors: {},
      validator: null,
      submitting: false,
    };
  },
  mounted() {
    this.setCopies([]);
  },
  methods: {
    async submit() {
      this.validator = new Validator(this.item, {
        name: "required",
        positions: "required",
      });
      console.log("item", this.item);
      if (this.validator.passes()) {
        this.errors = null;
        await this.add();
        this.$emit("close");
      } else this.errors = this.validator.errors.all();
    },
    add() {
      const attachments = this.item.attachments.map((attachment) => ({
        id: attachment.id,
        size: attachment.size,
      }));
      const data = {
        ...this.item,
        age: this.item.haveAge ? this.item.age : null,
        gender: this.item.gender ? "m" : "f",
        residence_country_id: this.item.residenceCountrySameAsOrigin
          ? this.item.origin_country_id
          : this.item.residence_country_id,
        positions: this.item.positions.map((p) => ({
          name: p,
          states: this.item.states.get(p),
        })),
        attachments,
      };

      this.submitting = true;

      return this.axios
        .post(
          "/recruits",
          serialize(data, {
            indices: true,
          }),
        )
        .then(() => {
          this.fetchData(true);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    ...mapMutations("recruits", ["setCopies"]),
    ...mapActions("recruits", ["fetchData", "checkCopies"]),
  },
  watch: {
    item: {
      handler(newVal, oldVal) {
        const { name, age, gender } = this.item;

        if (!name && !age && !this.item.origin_country_id && !this.item.residence_country_id) {
          return;
        }

        if (
          newVal.name === oldVal.name &&
          newVal.age === oldVal.age &&
          newVal.origin_country_id === oldVal.origin_country_id &&
          newVal.residence_country_id === oldVal.residence_country_id
        ) {
          return;
        }

        this.checkCopies({
          name,
          age,
          gender: gender ? "m" : "f",
          origin_country_id: this.item.origin_country_id,
          residence_country_id: this.item.residence_country_id,
          residence_same_as_origin: this.item.residence_same_as_origin,
        });
      },
      deep: true,
    },
  },
};
</script>

<style></style>
