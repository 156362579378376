import Bouncer from "../../utils/bouncer";

export default {
  namespaced: true,

  getters: {
    user: (state, getters, rootState, rootGetters) => rootGetters.user,
    bouncer: (state, getters) => new Bouncer(getters.user),
    can: (state, getters) => getters.bouncer.can.bind(getters.bouncer),
    cannot: (state, getters) => getters.bouncer.cannot.bind(getters.bouncer),
    isA: (state, getters) => getters.bouncer.isA.bind(getters.bouncer),
    isNotA: (state, getters) => getters.bouncer.isNotA.bind(getters.bouncer),
  },
};
