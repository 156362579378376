function parseObjectToParams(obj) {
  const nullRemoved = { ...obj };
  Object.keys(nullRemoved).forEach(
    (k) => (nullRemoved[k] === null || nullRemoved[k] === "") && delete nullRemoved[k],
  );
  return new URLSearchParams(nullRemoved).toString();
}

function getSize(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 0);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
}

function fromKey(data, key, set = null) {
  const keys = key.split(".");
  if (keys.length === 1) {
    if (set !== null) {
      Object.assign(data, { [keys[0]]: set });
      return null;
    }
    return data[keys[0]];
  }

  const restKeys = keys.slice(1).join(".");
  if (!data[keys[0]]) {
    if (set !== null) {
      Object.assign(data, { [keys[0]]: {} });
    } else return null;
  }
  return this.fromKey(data[keys[0]], restKeys, set);
}

function getFromKey(data, key) {
  return fromKey(data, key);
}

function setFromKey(data, key, val) {
  return fromKey(data, key, val);
}

export default {
  parseObjectToParams,
  getSize,
  fromKey,
  getFromKey,
  setFromKey,
};
