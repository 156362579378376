import axios from "axios";

export default class RemoteStorage {
  constructor(token, baseURL) {
    this.token = token;
    this.baseURL = baseURL;
    this.axios = axios.create({
      baseURL,
    });
  }

  upload(file, type, onUploadProgress, token) {
    const types = ["image", "audio", "document", "video"];
    if (!types.includes(type)) throw new Error("Invalid type");
    const formData = new FormData();
    formData.append(type, file, file.name);
    return this.axios.post(`upload/${type}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.token}`,
      },
      onUploadProgress,
      cancelToken: token?.token,
    });
  }

  finish(id, type) {
    const formData = new FormData();
    return this.axios.post(`upload/${type}/${id}/finish`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  }

  uploadAndFinish(file, type, onUploadProgress, token) {
    return new Promise((resolve, reject) => {
      this.upload(file, type, onUploadProgress, token)
        .then((resp) => {
          this.finish(resp.data.name, type)
            .then((response) => {
              const { multimedia } = response.data;
              resolve(multimedia);
            })
            .catch((e) => reject(e));
        })
        .catch((e) => reject(e));
    });
  }

  abort(id, type) {
    const formData = new FormData();
    return this.axios.post(`upload/${type}/${id}/abort`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  }

  download(id, type) {
    return this.axios.get(`download/${type}/${id}`, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: "arraybuffer",
    });
  }
}
