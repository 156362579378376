<template>
  <div class="w-full h-full y gap-y-5 md:x">
    <div class="w-full md:w-4/12 px-4 md:px-0">
      <div class="w-full h-full y items-start md:items-center gap-y-4 pt-3 md:pt-16">
        <router-link :to="{ name: 'Scout' }">
          <div
            class="x gap-x-4 pb-10 text-2xl items-center justify-center cursor-pointer text-gray-400 hover:text-brand"
          >
            <i class="isax isax-arrow-left-3" />
            <span>Go Back</span>
          </div>
        </router-link>

        <div
          class="x gap-x-5 items-center"
          v-for="(color, index) in Object.keys(colors)"
          :key="index"
          v-show="index !== 3"
        >
          <div class="w-16 h-7 rounded-sm" :style="{ background: colors[color] }"></div>
          <p style="color: #909090" class="w-20">{{ color }}</p>
        </div>
      </div>
    </div>

    <div class="flex-1 w-full md:h-full">
      <world-map :data="mapData" :scales="colors" v-if="!loading" :isPolynomial="false">
        <template #info-window="{ selected }">
          <div class="y">
            <p><b>Country:</b> {{ getCountryFromCode(selected).name }}</p>
            <p><b>Cities:</b> {{ getCountryFromCode(selected).cities }}</p>
            <p><b>Applicants:</b> {{ getCountryFromCode(selected).applicants }}</p>
            <!-- TODO: sync it with the production module -->
            <!-- <p><b>Employees:</b> 0</p> -->
            <p v-if="getCountryFromCode(selected).importance">
              <b>Importance:</b>
              <span :style="{ color: getColor(selected) }">
                {{ getCountryFromCode(selected).importance }}</span
              >
            </p>
            <br />
            <router-link
              :to="{ name: 'ScoutCountryProfile', params: { id: getCountryFromCode(selected).id } }"
              class="text-orange cursor-pointer"
              >View</router-link
            >
          </div>
        </template>
      </world-map>
    </div>
  </div>
</template>

<script>
import WorldMap from "../components/WorldMap.vue";

export default {
  components: {
    WorldMap,
  },
  data() {
    return {
      mapData: {},
      countries: new Map(),
      loading: true,
      colors: {
        Primary: "#EB671B",
        Secondary: "#117C00",
        Other: "#5A83D5",
        Null: "#ffffff",
      },
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    getData() {
      return this.axios.get("/statistics/scout-map").then((resp) => {
        const { data } = resp.data;
        this.countries = new Map(data.map((c) => [c.code, c]));
        this.mapData = data.reduce(
          (obj, item) =>
            Object.assign(obj, {
              [item.code]: item.importance ?? "Null",
            }),
          {},
        );
        this.loading = false;
      });
    },
    getCountryFromCode(code) {
      return this.countries.get(code);
    },
    getColor(code) {
      const color = this.mapData[code];
      return this.colors[color ?? "Null"];
    },
  },
};
</script>
