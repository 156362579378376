<template>
  <div class="relative" :class="{ 'error-selector': isError }">
    <slot />
    <input-error-message v-for="(error, index) of errors" v-bind:key="index">
      {{ error }}
    </input-error-message>
    <div class="absolute right-1 text-right pr-2.5 top-2.5 bg-white w-10">
      <error-icon class="" v-if="isError" />
    </div>
  </div>
</template>

<script lang="ts">
import ErrorIcon from "./ErrorIcon.vue";
import InputErrorMessage from "./InputErrorMessage.vue";

export default {
  components: { InputErrorMessage, ErrorIcon },
  props: {
    error: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isError() {
      return this.error != null || this.errors.length > 0;
    },
  },
};
</script>
