import axios from "axios";
import { parseIntance, getActiveFrom, getChangesMessages } from "../../utils/permissionsManager";

const store = {
  namespaced: true,
  state: {
    data: [],
    loading: false,
  },
  getters: {
    data: (state) => state.data,
    loading: (state) => state.loading,
  },
  mutations: {
    setData(state, data) {
      state.data = data;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    fetchData({ commit }) {
      commit("setLoading", true);
      return axios
        .get("invitations")
        .then((resp) => {
          const { data } = resp;
          commit(
            "setData",
            data.map((i) => ({ ...parseIntance(i), is_invitation: true })),
          );
        })
        .catch(() => {
          commit("setLoading", false);
        });
    },
    add({ rootGetters, dispatch }, { invitation }) {
      const allAbs = rootGetters["data/abilities"];
      const allRoles = rootGetters["data/roles"];

      const { abilities, roles } = getActiveFrom(invitation, allAbs, allRoles);

      return axios
        .post(`invitations`, {
          ...invitation,
          abilities,
          roles,
        })
        .then((resp) => {
          dispatch("fetchData");
          return resp;
        });
    },
    update({ rootGetters, dispatch }, { invitation }) {
      const allAbs = rootGetters["data/abilities"];
      const allRoles = rootGetters["data/roles"];

      const { abilities, roles } = getActiveFrom(invitation, allAbs, allRoles);

      return axios
        .put(`invitations/${invitation.id}`, {
          ...invitation,
          abilities,
          roles,
        })
        .then((resp) => {
          const { changes } = resp.data.data;
          const messages = getChangesMessages(allAbs, allRoles, changes);
          for (let i = 0; i < messages.length; i += 1) {
            const message = messages[i];
            dispatch("showToast", { message, type: "success" }, { root: true });
          }
          dispatch("fetchData");
          return resp;
        });
    },
    delete({ dispatch }, { invitation }) {
      return axios.delete(`invitations/${invitation.id}`).then(() => {
        dispatch("fetchData");
      });
    },
  },
};

export default store;
