<template>
  <pop-up-modal
    @close="$emit('close')"
    :width="$mq === 'sm' ? '100%' : '75%'"
    :height="$mq === 'sm' ? '100%' : '80%'"
  >
    <email-template-form :edit="true" ref="form" v-model="item" />

    <template #actionButton>
      <button-primary @click.native="submit" :loading="submitting"> Save </button-primary>
    </template>
  </pop-up-modal>
</template>

<script>
import { mapActions } from "vuex";
import EmailTemplateForm from "./EmailTemplateForm.vue";
import ButtonPrimary from "./ButtonPrimary.vue";
import PopUpModal from "./PopUpModal.vue";

export default {
  components: { PopUpModal, ButtonPrimary, EmailTemplateForm },
  data() {
    const item = {
      name: null,
      cc: null,
      to: null,
      subject: null,
      message: null,
      attachments: [],
    };

    return {
      item,
      submitting: false,
    };
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      await this.save();
      this.$emit("close");
    },
    async save() {
      this.submitting = true;

      return this.add(this.item).finally(() => {
        this.submitting = false;
      });
    },
    ...mapActions("emailTemplates", ["fetchData", "add"]),
  },
};
</script>

<style></style>
