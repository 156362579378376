<template>
  <div class="y justify-start items-start relative flex-wrap min-h-full validated-input">
    {{ error }}
    <input
      v-if="!isTextarea"
      class="validated-input block w-full px-3 py-2 sm:text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-brand focus:border-brand shadow-sm disabled:bg-gray-100"
      :style="{
        'border-color': isError ? 'rgb(239 68 68) !important' : 'rgba(212, 212, 212) !important',
      }"
      :class="classes"
      :type="type"
      :min="min"
      :max="max"
      :placeholder="placeholder"
      v-bind:value="value"
      v-on:input="debounce ? null : updateValue($event.target.value)"
      :disabled="disabled"
      v-debounce:200="(val) => (debounce ? updateValue(val) : null)"
    />
    <textarea
      v-else
      class="validated-input block w-full px-3 py-2 sm:text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-brand focus:border-brand h-24 disabled:bg-gray-100"
      :style="{
        'border-color': isError ? 'rgb(239 68 68) !important' : 'rgba(212, 212, 212) !important',
      }"
      :class="classes"
      type="text"
      :placeholder="placeholder"
      v-bind:value="value"
      v-on:input="updateValue($event.target.value)"
      :disabled="disabled"
    ></textarea>
    <input-error-message v-if="error != null">{{ error }}</input-error-message>
    <input-error-message v-for="(error, index) of errors" v-bind:key="index">{{
      error
    }}</input-error-message>
    <error-icon class="absolute right-2.5 top-2.5" v-if="isError" />
  </div>
</template>

<script lang="ts">
import ErrorIcon from "./ErrorIcon.vue";
import InputErrorMessage from "./InputErrorMessage.vue";

export default {
  components: { InputErrorMessage, ErrorIcon },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    error: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    isTextarea: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    classes: {
      type: String,
      default: () => "",
    },
    debounce: Boolean,
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
  computed: {
    isError() {
      if (this.error) return true;
      return this.errors && this.errors.length > 0;
    },
  },
};
</script>
