<template>
  <v-select
    class="selector"
    :options="recuritStates"
    :value="value"
    :disabled="disabled"
    @input="(val) => $emit('input', val)"
    :taggable="true"
    multiple
    :placeholder="placeholder"
  >
    <template #selected-option-container="{ option, deselect, disabled }">
      <div class="state-selected-item text-sm relative">
        <state-span
          class="hover:line-through cursor-not-allowed"
          @click.native="disabled ? null : deselect(option.label)"
          :label="option.label"
        />
      </div>
    </template>
    <template #option="{ label }">
      <state-span :label="label" />
    </template>
  </v-select>
</template>

<script>
import { mapState } from "vuex";
import StateSpan from "./StateSpan.vue";

export default {
  components: { StateSpan },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    placeholder: String,
  },
  computed: {
    ...mapState("data", ["recuritStates"]),
  },
};
</script>

<style></style>
