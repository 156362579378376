<template>
  <main>
    <div class="y w-full gap-y-5 px-0 md:px-24">
      <div class="relative w-full y md:x gap-x-3 items-end justify-end">
        <div class="w-3/12 md:static absolute top-0 left-0 pb-0 md:pb-5">
          <router-link :to="{ name: 'ScoutMap' }">
            <i class="isax isax-global text-gray-400 text-2xl cursor-pointer" title="Map view" />
          </router-link>
        </div>
        <div class="w-full md:w-6/12 pt-3">
          <field-item :label="''" class="justify-self-end">
            <template #input>
              <div class="x border rounded-md bg-white items-center pr-3">
                <input
                  type="text"
                  v-debounce:200="(val) => (filters.search = val)"
                  :placeholder="'Search'"
                  class="shadow-none flex-1 border-none focus:outline-none"
                />
                <i class="isax isax-search-normal text-xl text-gray-400" />
              </div>
            </template>
          </field-item>
        </div>
        <div class="w-3/12 x justify-end md:static absolute top-0 right-0 pb-0 md:pb-3">
          <button-primary @click.native="showAddModal = true" class="h-8 w-28"
            >Add new</button-primary
          >
        </div>
      </div>

      <data-table class="mt-2 table-fixed">
        <template #header>
          <selectable-columns
            :showedColumns="showedColumns"
            :allColumns="allColumns"
            :setShowedColumns="setShowedColumns"
            :searchColumns="filters.search_scope"
            @updateSearchColumns="(columns) => (filters.search_scope = columns)"
            :orderBy="filters.order_by"
            :orderDirection="filters.order_direction"
            @updateOrder="
              (o) => {
                filters.order_by = o.orderBy;
                filters.order_direction = o.orderDirection;
              }
            "
            @updateShowedColumns="(val) => (showedColumns = val)"
          />
        </template>

        <template #body>
          <tr v-for="country in countries" :key="country.id" class="hover:bg-white cursor-pointer">
            <data-table-cell v-for="column in showedColumns" :key="column" class="bg-transparent">
              <router-link
                class="text-sm"
                v-if="column === 'ID'"
                :to="{ name: 'ScoutCountryProfile', params: { id: country.id } }"
                >{{ country.id }}</router-link
              >
              <country-importance-selector
                v-else-if="allColumns[column] === 'importance'"
                :value="getData(country, column)"
                @input="(val) => setData(country, column, val)"
                class="inside-table-selector"
              />
              <input
                v-else-if="allColumns[column] === 'cities'"
                :value="getData(country, column)"
                @change="(ev) => setData(country, column, ev.target.value)"
                class="py-1 border-none"
              />
              <input
                v-else
                :value="getData(country, column)"
                @change="(ev) => setData(country, column, ev.target.value)"
                :disabled="true"
                class="py-1 border-none"
                :class="{
                  'hover:font-bold cursor-pointer whitespace-nowrap w-52': column === 'Name',
                }"
              />
            </data-table-cell>
            <data-table-cell>
              <i
                @click="confirmDelete(country)"
                class="isax isax-trash pl-2 cursor-pointer"
                title="delete"
              />
            </data-table-cell>
          </tr>
        </template>
      </data-table>
      <infinite-loader
        :disabled="settings.reachedEnd"
        :loading="settings.loading"
        @loadMore="fetchData"
      />
    </div>
    <transition name="popup">
      <ad-add-new-country-profile v-if="showAddModal" @close="showAddModal = false" />
      <alert-merge class="absolute z-fixed bottom-5 right-5" />
    </transition>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import FieldItem from "../components/FieldItem.vue";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import DataTable from "../components/DataTable.vue";
import DataTableCell from "../components/DataTableCell.vue";
import InfiniteLoader from "../components/InfiniteLoader.vue";
import SelectableColumns from "../components/SelectableColumns.vue";
import utils from "../utils/utils";
import CountryImportanceSelector from "../components/CountryImportanceSelector.vue";
import AdAddNewCountryProfile from "../components/AdAddNewCountryProfile.vue";
import alerts from "../utils/alerts";

export default {
  components: {
    FieldItem,
    ButtonPrimary,
    DataTable,
    DataTableCell,
    InfiniteLoader,
    SelectableColumns,
    CountryImportanceSelector,
    AdAddNewCountryProfile,
  },
  data() {
    return {
      showAddModal: false,
      orderBy: null,
      orderDirection: null,
      searchScope: [],
      importances: ["Primary", "Secondary", "Other"],
    };
  },
  mounted() {
    this.fetchData(true);
    this.fetchShowedColumns();
  },
  methods: {
    setData(country, column, value) {
      if (column === "ID") return;
      if (this.allColumns[column]) {
        const col = this.allColumns[column] ?? column;
        this.setFromKey(country, col, value);
        this.updateCountry(country);
      }
    },
    getData(recruit, column) {
      if (column === "ID") return recruit.id;
      return this.getFromKey(recruit, this.allColumns[column]);
    },
    getFromKey(data, key) {
      return this.fromKey(data, key);
    },
    setFromKey(data, key, val) {
      this.fromKey(data, key, val);
    },
    fromKey(data, key, set = null) {
      return utils.fromKey(data, key, set);
    },
    async updateCountry(country) {
      await this.$store.dispatch("scout/updateCountry", { country });
    },
    async confirmDelete(country) {
      const confirmed = await alerts.showConfirm({
        title: "Delete Country",
        message: "Are you sure you want to delete this country?",
      });
      if (!confirmed) return;

      this.deleteCountry(country);
    },
    ...mapActions("scout", ["fetchData", "deleteCountry"]),
    ...mapActions("countriesColumns", ["fetchShowedColumns"]),
    ...mapMutations("countriesColumns", ["setShowedColumns"]),
  },
  computed: {
    countries: {
      get() {
        return this.$store.state.scout.data;
      },
      // set(val) {
      //   // this.setShowedColumns(val);
      // },
    },
    showedColumns: {
      get() {
        return this.$store.state.countriesColumns.showedColumns;
      },
      set(val) {
        this.setShowedColumns(val);
      },
    },
    ...mapGetters("countriesColumns", ["allColumns"]),
    ...mapGetters("scout", ["filters", "settings"]),
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        if (Object.keys(val).length > 0) this.fetchData(true);
      },
    },
  },
};
</script>

<style></style>
