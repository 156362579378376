<template>
  <div
    class="m-3 flex justify-center px-6 py-8 border-2 border-gray-300 border-dashed rounded-md"
    :class="{ 'bg-gray-100': isDragging }"
    @dragover="onDragover"
    @dragleave="onDragleave"
    @drop="onDrop"
  >
    <input
      :id="id"
      ref="attachments"
      multiple
      :name="id"
      type="file"
      class="sr-only hidden"
      @change="changed"
    />
    <slot :label="id">
      <div class="y gap-y-2 items-center justify-center" stlye="color: rgba(75, 85, 99, 1);">
        <i class="isax isax-gallery-add text-4xl text-gray-400" />
        <div class="flex text-sm">
          <label
            :for="id"
            class="relative cursor-pointer rounded-md font-medium text-brand focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 ring-brand"
          >
            <span>Upload a file</span>
          </label>
          <p class="pl-1">or drag and drop</p>
        </div>
        <p class="text-xs">PNG, JPG, GIF up to 10MB</p>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    multipart: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: () => "file-upload",
    },
  },
  data() {
    return {
      isDragging: false,
      endingDragging: null,
      attachments: [],
    };
  },
  methods: {
    changed() {
      this.$emit("change", this.$refs.attachments.files);
    },
    onDragover(ev) {
      if (this.endingDragging) clearTimeout(this.endingDragging);
      this.isDragging = true;
      ev.preventDefault();
    },
    onDragleave() {
      this.endingDragging = setTimeout(() => {
        this.isDragging = false;
      }, 200);
    },
    onDrop(event) {
      event.preventDefault();
      this.$refs.attachments.files = event.dataTransfer.files;
      this.onDragleave();
      this.changed();
    },
    select() {
      this.$refs.attachments.click();
    },
  },
};
</script>
