import { DateTime } from "luxon";

function getTimeAgo(time) {
  return DateTime.fromISO(time).setLocale("en").toRelative();
}
function parseTimeToHuman(time) {
  const hms = time.split(":");
  let timeForHuman = "";

  if (hms[0] > 0) {
    timeForHuman += `${Number(hms[0])}h `;
  }
  if (hms[1] > 0) {
    timeForHuman += `${Number(hms[1])}m`;
  }

  return timeForHuman;
}

function formatDate(date) {
  if (!date) return "UNKOWN";
  return DateTime.fromISO(date).toFormat("yyyy-LL-dd");
}

function titleCaseWord(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function titleCase(string) {
  return string.split(" ").map(titleCaseWord).join(" ");
}

export default {
  getTimeAgo,
  parseTimeToHuman,
  formatDate,
  titleCase,
};
