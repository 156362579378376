import axios from "axios";
import getListStore from "./list";
import {
  parseIntance,
  roles,
  abilities,
  getActiveFrom,
  getChangesMessages,
} from "../../utils/permissionsManager";

const listStore = getListStore("users/permissions", {
  order_by: null,
  order_direction: null,
  search: null,
  search_scope: [],
});

const store = {
  ...listStore,
  getters: {
    ...listStore.getters,
    abilitiesColumns() {
      return Object.keys(abilities);
    },
    rolesColumns() {
      return roles;
    },
    permissionsColumns(state, getters) {
      const { abilitiesColumns, rolesColumns } = getters;
      const permissionsColumns = [...abilitiesColumns, ...rolesColumns];

      return permissionsColumns;
    },
  },
  actions: {
    ...listStore.actions,
    updateUserPermissions({ rootGetters, dispatch }, { user }) {
      const allAbs = rootGetters["data/abilities"];
      const allRoles = rootGetters["data/roles"];

      const { abilities: activeAbilities, roles: activeRoles } = getActiveFrom(
        user,
        allAbs,
        allRoles,
      );

      return axios
        .put(`users/${user.id}/permissions`, {
          abilities: activeAbilities,
          roles: activeRoles,
        })
        .then((resp) => {
          const { changes } = resp.data.data;
          const messages = getChangesMessages(allAbs, allRoles, changes);
          for (let i = 0; i < messages.length; i += 1) {
            const message = messages[i];
            dispatch("showToast", { message, type: "success" }, { root: true });
          }
        });
    },
    updateUser(_, { user }) {
      return axios.put(`users/${user.id}`, {
        name: user.name,
        email: user.email,
        gitlab_id: user.gitlab_id,
        updated_at: user.updated_at,
        created_at: user.created_at,
      });
    },
    deleteUser(_, { user }) {
      return axios.delete(`users/${user.id}`);
    },
    fetchData({ commit, state }, reset = false) {
      return new Promise((resolve, reject) => {
        listStore.actions
          .fetchData({ commit, state }, reset)
          .then(() => {
            const data = state.data.map(parseIntance);
            commit("setData", data);
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    parseUser(context, { user }) {
      return new Promise((resolve) => resolve(parseIntance(user)));
    },
  },
};

export default store;
