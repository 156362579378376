<template>
  <div class="relative w-full h-full y items-center justify-center gap-y-5 md:x">
    <div class="absolute top-0 left-0 z-10 w-full md:w-4/12 px-4 md:px-0">
      <div class="w-full h-full x md:y items-start justify-between md:items-center pt-3 md:pt-16">
        <router-link :to="{ name: 'Ads' }">
          <div
            class="x gap-x-4 pb-10 text-2xl items-center justify-center cursor-pointer text-gray-400 hover:text-brand"
          >
            <i class="isax isax-arrow-left-3" />
            <span>Go Back</span>
          </div>
        </router-link>

        <checkbox-input
          :label="'Show only active countries'"
          :checked="onlyActive"
          @change="() => (onlyActive = !onlyActive)"
        />
      </div>
    </div>

    <div class="w-full h-full">
      <world-map :data="mapData" v-if="!loading">
        <template #info-window="{ selected }">
          <div class="y">
            <p><b>Country:</b> {{ getCountryFromCode(selected).name }}</p>
            <p><b>Cities:</b> {{ getCountryFromCode(selected).cities }}</p>
            <p><b>Ads active:</b> {{ getCountryFromCode(selected).total_active_ads }}</p>
            <p><b>Total number of ads:</b> {{ getCountryFromCode(selected).total_ads }}</p>
            <p><b>Applicants:</b> {{ getCountryFromCode(selected).total_applicants }}</p>
            <p class="overflow-ellipsis overflow-hidden whitespace-nowrap">
              <b>Recruits:</b> {{ getCountryFromCode(selected).recruits }}
            </p>
            <p><b>Importance:</b> {{ getCountryFromCode(selected).importance }}</p>
            <br />
            <b class="text-orange cursor-pointer">View</b>
          </div>
        </template>
      </world-map>
    </div>
  </div>
</template>

<script>
import CheckboxInput from "../components/CheckboxInput.vue";
import WorldMap from "../components/WorldMap.vue";

export default {
  components: {
    WorldMap,
    CheckboxInput,
  },
  data() {
    return {
      statisticsData: [],
      mapData: [],
      countries: new Map(),
      loading: true,
      onlyActive: false,
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    getData() {
      return this.axios.get("/statistics/ads-map").then((resp) => {
        this.statisticsData = resp.data.data;
        this.filterMapData();
        this.countries = new Map(this.statisticsData.map((c) => [c.code, c]));
        this.loading = false;
      });
    },
    filterMapData() {
      this.mapData = this.statisticsData
        .filter((c) => (this.onlyActive ? c.total_active_ads > 0 : c.total_ads > 0))
        .reduce(
          (obj, item) =>
            Object.assign(obj, {
              [item.code]: this.onlyActive ? item.total_active_ads : item.total_ads,
            }),
          {},
        );
    },
    getCountryFromCode(code) {
      return this.countries.get(code);
    },
  },
  watch: {
    onlyActive() {
      this.loading = true;
      this.filterMapData();
      this.$nextTick(() => {
        this.loading = false;
      });
    },
  },
};
</script>
