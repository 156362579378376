<template>
  <span :style="{ color: stateColor }">{{ label }}</span>
</template>

<script>
export default {
  props: {
    label: String,
  },
  computed: {
    stateColor() {
      switch (this.label) {
        case "Waiting For selection":
          return "#db00ff";
        case "In process":
          return "#117c00";
        case "To be scheduled":
          return "#6b007c";
        case "Scheduled":
          return "#0da98d";
        case "Hired":
          return "#FF6700";
        default:
          break;
      }

      if (
        [
          "Awaiting cover letter",
          "Awaiting decision",
          "Awaiting task",
          "Awaiting response",
        ].includes(this.label)
      )
        return "#0019ff";

      if (["Interview task", "Interview", "Final Interview"].includes(this.label)) return "#333333";

      if (["Rejected", "Rejected for inaction", "Withdrawn by candidate"].includes(this.label))
        return "#FF0000";

      return "#AAAAAA";
    },
  },
};
</script>
