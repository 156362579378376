<template>
  <th
    scope="col"
    class="px-1.5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  >
    <slot>
      {{ title }}
    </slot>
  </th>
</template>

<script lang="ts">
export default {
  props: {
    title: {
      type: String,
      default: () => "",
    },
  },
};
</script>
