import Bouncer from "./bouncer";

export const abilities = {
  view_recruits: "hr-view-recruits",
  update_own_recruits: "hr-update-own-recruits",
  add_recruits: "hr-add-recruits",
  update_others_recruits: "hr-update-others-recruits",
  send_email: "hr-send-email",
  create_email_template: "hr-create-email-template",
};

export const roles = ["hr", "scout", "ads_manager", "permission_manager"];

export function havePendingRequest(user) {
  if (!user.permission_request) return false;
  return user.permission_request.pending;
}

export function isEditable(user) {
  return !havePendingRequest(user);
}

export function parseIntance(user) {
  const bouncer = new Bouncer({
    ...user,
    abilities: user.abilities.map((ability) => {
      return {
        ...ability,
        forbidden: ability.pivot.forbidden,
      };
    }),
  });

  const parsedUser = {
    ...user,
  };

  for (let i = 0; i < Object.keys(abilities).length; i += 1) {
    const key = Object.keys(abilities)[i];
    const name = abilities[key];
    parsedUser[key] = bouncer.can(name);
  }

  for (let i = 0; i < roles.length; i += 1) {
    const role = roles[i];
    parsedUser[role] = bouncer.isA(role);
  }

  return parsedUser;
}

export function getActiveFrom(user, allAbs, allRoles) {
  const activeAbilitesKeys = Object.keys(abilities).filter((abilityKey) => user[abilityKey]);
  const activeAbilities = activeAbilitesKeys
    .map((abilityKey) => abilities[abilityKey])
    .map((ability) => allAbs.find((a) => a.name === ability)?.id);

  const activeRoles = roles
    .filter((role) => user[role])
    .map((role) => allRoles.find((r) => r.name === role)?.id);

  return {
    abilities: activeAbilities,
    roles: activeRoles,
  };
}

const showChangesMessage = ({ name, label, status }) => {
  const message = `${name} ${label} has been ${status}`;
  return message;
};

const showPermissionMessage = ({ name, granted = true }) => {
  const label = "permission";
  const status = granted ? "granted" : "denied";
  return showChangesMessage({ name, label, status });
};

const showRoleMessage = ({ name, granted = true }) => {
  const label = "role";
  const status = granted ? "granted" : "revoked";
  return showChangesMessage({ name, label, status });
};

export function getChangesMessages(allAbs, allRoles, changes) {
  const getRoleTitle = (id) => allRoles.find((r) => r.id === id).title;
  const getAbilityTitle = (id) => allAbs.find((a) => a.id === id).title;
  const parseDetached = (detached) => Object.values(detached).map((a) => parseInt(a, 0));

  const changesWithTitles = {
    roles: {
      attached: changes.roles.attached.map(getRoleTitle),
      detached: parseDetached(changes.roles.detached).map(getRoleTitle),
    },
    abilities: {
      attached: changes.abilities.attached.map(getAbilityTitle),
      detached: parseDetached(changes.abilities.detached).map(getAbilityTitle),
    },
  };

  const showStatusMessages = (status, granted = true, isAbility = true) => {
    const messages = [];
    for (let i = 0; i < status.length; i += 1) {
      const name = status[i];
      if (isAbility) {
        messages.push(showPermissionMessage({ name, granted }));
      } else {
        messages.push(showRoleMessage({ name, granted }));
      }
    }
    return messages;
  };

  const showAllStatusMessages = (object, isAbility = true) => {
    const { attached, detached } = object;
    return [
      ...showStatusMessages(attached, true, isAbility),
      ...showStatusMessages(detached, false, isAbility),
    ];
  };

  return [
    ...showAllStatusMessages(changesWithTitles.abilities),
    ...showAllStatusMessages(changesWithTitles.roles, false),
  ];
}
