<template>
  <div class="mx-auto w-full py-4 px-6 flex-1 y gap-y-2 overflow-y-scroll">
    <div class="y xl:x gap-x-6">
      <field-item :label="'Name'" :required="true">
        <template #input>
          <validated-input
            :value="value.name"
            @input="(val) => update('name', val)"
            :errors="errors ? errors['name'] : []"
            class="md:flex-1"
          />
        </template>
      </field-item>

      <field-item :label="'Country of origin'">
        <template #input>
          <country-selector
            :value="value.origin_country_id"
            @input="(val) => update('origin_country_id', val)"
          />
        </template>
      </field-item>
    </div>

    <div class="y xl:x gap-x-6">
      <div class="y xl:x md:flex-1 gap-x-4">
        <div class="y gap-y-1 flex-1 mb-2">
          <field-item :label="'Age'" class="md:flex-1">
            <template #input>
              <validated-input
                :errors="errors ? errors['age'] : []"
                :type="'number'"
                :min="18"
                class="md:flex-1"
                :disabled="!value.haveAge"
                :value="value.age"
                @input="(val) => update('age', val)"
              />
            </template>
          </field-item>

          <checkbox-input
            :checked="!value.haveAge"
            :value="value.age"
            @change="(val) => update('haveAge', !value.haveAge)"
            :label="'Age unknown'"
          />
        </div>

        <div class="md:flex-1 x gap-x-2 justify-center items-start pt-5">
          <div
            @click="update('gender', true)"
            class="px-7 py-6 bg-white rounded-lg shadow-lg cursor-pointer hover:bg-opacity-80"
            title="Male"
          >
            <icon-male :active="value.gender" />
          </div>
          <div
            @click="update('gender', false)"
            class="px-7 py-6 bg-white rounded-lg shadow-lg cursor-pointer hover:bg-opacity-80"
            title="Female"
          >
            <icon-female :active="!value.gender" />
          </div>
        </div>
      </div>

      <div class="y gap-y-1 flex-1 mb-2">
        <field-item :label="'Country of residence'">
          <template #input>
            <validated-select :errors="errors ? errors['residence_country_id'] : []">
              <v-select
                label="name"
                placeholder="Choose"
                :options="countries"
                :reduce="(company) => company.id"
                class="selector md:flex-1"
                :disabled="value.residenceCountrySameAsOrigin"
                :value="value.residence_country_id"
                @input="(val) => update('residence_country_id', val)"
              />
            </validated-select>
          </template>
        </field-item>

        <checkbox-input
          :checked="value.residenceCountrySameAsOrigin"
          :value="value.residenceCountrySameAsOrigin"
          @change="
            (val) => update('residenceCountrySameAsOrigin', !value.residenceCountrySameAsOrigin)
          "
          :label="'Country of residence is the same as country of origin'"
        />
      </div>
    </div>

    <div class="y xl:x gap-x-6">
      <field-item :label="'Email'">
        <template #input>
          <validated-input
            :value="value.email"
            @input="(val) => update('email', val)"
            :errors="errors ? errors['email'] : []"
            class="md:flex-1"
          />
        </template>
      </field-item>

      <field-item :label="'Phone'">
        <template #input>
          <validated-input
            :value="value.phone"
            @input="(val) => update('phone', val)"
            :errors="errors ? errors['phone'] : []"
            class="md:flex-1"
          />
        </template>
      </field-item>
    </div>

    <div class="y xl:x gap-x-6">
      <div class="y xl:x gap-x-2 flex-1">
        <field-item :label="'Desired salary'">
          <template #input>
            <validated-input
              :errors="errors ? errors['desired_salary'] : []"
              class="md:flex-1"
              :type="'number'"
              :min="1"
              :value="value.desired_salary"
              @input="(val) => update('desired_salary', val)"
            />
          </template>
        </field-item>

        <field-item :label="'Currency'">
          <template #input>
            <v-select
              label="name"
              placeholder="Choose"
              :options="currencies"
              :reduce="(c) => c.id"
              class="selector md:flex-1"
              :value="value.currency_id"
              @input="(val) => update('currency_id', val)"
            />
          </template>
        </field-item>
      </div>

      <field-item :label="'Highest education'">
        <template #input>
          <v-select
            label="name"
            placeholder="Choose"
            :options="education"
            :reduce="(c) => c.id"
            class="selector md:flex-1"
            :value="value.education_id"
            @input="(val) => update('education_id', val)"
          />
        </template>
      </field-item>
    </div>

    <div class="y xl:x gap-x-6">
      <field-item :label="'Applied position'" :required="true">
        <template #input>
          <validated-select :errors="errors ? errors['positions'] : []">
            <v-select
              placeholder="Choose"
              :options="positions"
              class="selector md:flex-1"
              multiple
              :value="value.positions"
              @input="(val) => update('positions', val)"
            >
              <template #selected-option-container="{ option, deselect, disabled }">
                <div
                  @click="selectPosition(option.label)"
                  class="vs__selected cursor-pointer"
                  :class="{ 'bg-orange text-white': isPositionSelected(option.label) }"
                  title="Select"
                  aria-label="select"
                >
                  {{ option.label }}
                  <button
                    :disabled="disabled"
                    @click="
                      $event.stopPropagation();
                      $event.preventDefault();
                      deselect(option.label);
                      selectedPosition === option.label ? (selectedPosition = null) : null;
                    "
                    type="button"
                    :title="'Deselect ' + option.label"
                    :aria-label="'Deselect ' + option.label"
                    class="vs__deselect"
                  >
                    <i class="isax isax-close-circle" />
                  </button>
                </div>
              </template>
            </v-select>
          </validated-select>
        </template>
      </field-item>

      <field-item :label="'Languages spoken'">
        <template #input>
          <validated-select :errors="errors ? errors['languages'] : []">
            <v-select
              class="selector md:flex-1"
              :options="languages"
              :multiple="true"
              label="name"
              :reduce="(l) => l.id"
              :value="value.languages"
              :closeOnSelect="false"
              @input="(val) => update('languages', val)"
            />
          </validated-select>
        </template>
      </field-item>
    </div>

    <div class="y xl:x gap-x-6">
      <div class="y xl:x gap-x-6 flex-1">
        <field-item :label="'Weekly capacity'">
          <template #input>
            <validated-input
              :errors="errors ? errors['week_capacity'] : []"
              class="md:flex-1"
              :type="'number'"
              :min="1"
              :value="value.week_capacity"
              @input="(val) => update('week_capacity', val)"
            />
          </template>
        </field-item>

        <field-item :label="'Available to start from'">
          <template #input>
            <date-picker
              :clearable="true"
              valueType="format"
              format="YYYY-MM-DD"
              class="w-full disabled:bg-gray-100"
              :value="value.start_on"
              @input="(val) => update('start_on', val)"
            ></date-picker>
          </template>
        </field-item>
      </div>

      <div class="y xl:x gap-x-6 flex-1">
        <field-item :label="'Application Date'">
          <template #input>
            <date-picker
              :clearable="true"
              valueType="format"
              format="YYYY-MM-DD"
              class="w-full disabled:bg-gray-100"
              :value="value.application_date"
              @input="(val) => update('application_date', val)"
            ></date-picker>
          </template>
        </field-item>

        <field-item :label="'Platform'">
          <template #input>
            <v-select
              label="name"
              placeholder="Choose"
              :options="platforms"
              :reduce="(c) => c.id"
              class="selector md:flex-1"
              :value="value.platform_id"
              @input="(val) => update('platform_id', val)"
            />
          </template>
        </field-item>
      </div>
    </div>

    <div class="y xl:x gap-x-6">
      <field-item :label="'Preferred type of employment'">
        <template #input>
          <validated-select :errors="errors ? errors['employment_type_id'] : []">
            <v-select
              placeholder="Choose"
              :options="employmentTypes"
              label="name"
              :reduce="(t) => t.id"
              class="selector md:flex-1"
              :value="value.employment_type_id"
              @input="(val) => update('employment_type_id', val)"
            />
          </validated-select>
        </template>
      </field-item>

      <div class="y md:x flex-1 gap-x-2">
        <field-item :label="'Referral'">
          <template #input>
            <validated-input
              :errors="errors ? errors['referral'] : []"
              class="md:flex-1"
              :value="value.referral"
              @input="(val) => update('referral', val)"
            />
          </template>
        </field-item>

        <field-item :label="'Ad'">
          <template #input>
            <v-select
              label="name"
              placeholder="Choose"
              :options="ads"
              :reduce="(a) => a.id"
              class="selector md:flex-1"
              :value="value.ad_id"
              @input="(val) => update('ad_id', val)"
            />
          </template>
        </field-item>
      </div>
    </div>

    <div class="y xl:x gap-x-6">
      <field-item :label="'Stacks'" style="max-width: 100%">
        <template #input>
          <v-select
            placeholder="Choose"
            :options="stacks"
            :taggable="true"
            class="selector md:flex-1"
            multiple
            :value="value.stacks"
            :closeOnSelect="false"
            @input="(val) => update('stacks', val)"
          />
        </template>
      </field-item>

      <field-item :label="'Experience'">
        <template #input>
          <v-select
            label="name"
            placeholder="Choose"
            :options="experiences"
            :reduce="(c) => c.id"
            class="selector md:flex-1"
            :value="value.experience_id"
            @input="(val) => update('experience_id', val)"
          />
        </template>
      </field-item>
    </div>

    <div class="y xl:x gap-x-6">
      <div class="y xl:x gap-x-6 flex-1">
        <field-item :label="'Contact Holder'">
          <template #input>
            <v-select
              label="name"
              placeholder="Choose"
              :options="contactHolders"
              :reduce="(c) => c.id"
              class="selector md:flex-1"
              :value="value.contact_holder_id"
              @input="(val) => update('contact_holder_id', val)"
              :clearable="false"
            />
          </template>
        </field-item>

        <field-item :label="'Updated at'" v-if="edit">
          <template #input>
            <date-picker
              :clearable="false"
              type="datetime"
              valueType="format"
              format="YYYY-MM-DD HH:mm"
              class="w-full disabled:bg-gray-100"
              :value="value.updated_at"
              @input="(val) => update('updated_at', val)"
            />
          </template>
        </field-item>
      </div>

      <field-item :label="'State'">
        <template #input>
          <state-selector
            :disabled="!selectedPosition && value.positions.length !== 1"
            :value="selectedStates"
            :options="recuritStates"
            @input="stateChanged"
            :placeholder="!selectedPosition ? 'None' : 'No state set'"
          />
        </template>
      </field-item>
    </div>

    <field-item :label="'Notes'">
      <template #input>
        <validated-input
          :isTextarea="true"
          :value="value.notes"
          @input="(val) => update('notes', val)"
        />
      </template>
    </field-item>

    <slot name="before-attachments" />

    <recruit-attachments
      v-if="!edit"
      :value="value.attachments"
      @input="(v) => update('attachments', v)"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import * as Validator from "validatorjs";
import FieldItem from "./FieldItem.vue";
import ValidatedInput from "./ValidatedInput.vue";
import ValidatedSelect from "./ValidatedSelect.vue";
import CheckboxInput from "./CheckboxInput.vue";
import "vue2-datepicker/index.css";
import IconMale from "../assets/icons/IconMale.vue";
import IconFemale from "../assets/icons/IconFemale.vue";
import StateSelector from "./StateSelector.vue";
import CountrySelector from "./CountrySelector.vue";
import RecruitAttachments from "./RecruitAttachments.vue";

export default {
  props: {
    value: {
      Object,
      default: () => ({}),
    },
    errors: {
      Object,
      default: () => ({}),
    },
    edit: Boolean,
  },
  data() {
    return {
      validator: null,
      selectedPosition: null,
    };
  },
  mounted() {
    if (this.countries.length < 1) this.fetchCountries();
    this.fetchCurrencies();
    if (this.education.length < 1) this.fetchEducation();
    if (this.stacks.length < 1) this.fetchStacks();
    if (this.contactHolders.length < 1) {
      this.fetchContactHolders().then(this.setDefaultContactHolder);
    } else {
      this.setDefaultContactHolder();
    }
    if (this.employmentTypes.length < 1) this.fetchEmploymentTypes();
    if (this.recuritStates.length < 1) this.fetchRecuritStates();
    if (this.ads.length < 1) this.fetchAds();
    this.fetchPositions();
    if (this.platforms.length < 1) {
      this.fetchPlatforms().then(this.setDefaultPlatform);
    } else {
      this.setDefaultPlatform();
    }
  },
  components: {
    DatePicker,
    FieldItem,
    ValidatedInput,
    ValidatedSelect,
    CheckboxInput,
    IconMale,
    IconFemale,
    StateSelector,
    CountrySelector,
    RecruitAttachments,
  },
  methods: {
    getPositionIndex(label) {
      const idx = this.value.positions.findIndex((p) => p === label);
      return idx;
    },
    isPositionSelected(label) {
      return label === this.selectedPosition;
    },
    selectPosition(label) {
      if (this.isPositionSelected(label)) {
        this.selectedPosition = null;
        return;
      }
      this.selectedPosition = label;
    },
    stateChanged(value) {
      const map = new Map(this.value.states);
      map.set(this.selectedPosition ?? this.value.positions[0], value);
      this.update("states", map);
    },
    setDefaultContactHolder() {
      if (this.edit) return;
      const contactHolder = this.contactHolders.find((c) => c.id === 1);
      this.update("contact_holder_id", contactHolder.id);
    },
    setDefaultPlatform() {
      if (this.edit) return;
      const platform = this.platforms.find((s) => s.name === "LinkedIn");
      this.value.platform_id = platform.id;
      this.update("platform_id", platform?.id);
    },
    async fetchCurrencies() {
      if (this.currencies.length < 1) await this.$store.dispatch("data/fetchCurrencies");
      return new Promise((resolve) => {
        if (!this.value.currency_id) {
          const euroId = this.currencies.find((c) => c.code === "EUR")?.id;
          this.update("currency_id", euroId);
        }
        resolve();
      });
    },
    update(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    async validate() {
      this.validator = new Validator(this.value, {
        name: "required",
        positions: "required",
      });

      if (this.validator.passes()) {
        this.errors = null;
      } else this.errors = this.validator.errors.all();

      return this.validator.passes();
    },
    ...mapActions("data", [
      "fetchCountries",
      "fetchPositions",
      "fetchLanguages",
      "fetchEducation",
      "fetchPlatforms",
      "fetchExperiences",
      "fetchStacks",
      "fetchEmploymentTypes",
      "fetchContactHolders",
      "fetchRecuritStates",
      "fetchAds",
    ]),
  },
  computed: {
    selectedStates() {
      if (this.selectedPosition === null) {
        if (this.value.positions.length === 1)
          return this.value.states.get(this.value.positions[0]);
        return [];
      }

      return this.value.states.get(this.selectedPosition);
    },
    ...mapState("data", [
      "countries",
      "positions",
      "currencies",
      "languages",
      "education",
      "platforms",
      "experiences",
      "stacks",
      "employmentTypes",
      "contactHolders",
      "recuritStates",
      "ads",
    ]),
  },
};
</script>

<style></style>
