<template>
  <div class="y h-screen">
    <WebOrigoERPNavbar
      class="h-14 md:h-16 z-modal-backdrop"
      :user="user"
      :userMenuItems="userMenuItems"
      @logout="logout"
    />
    <div class="x flex-1 w-full" style="height: calc(100vh - 4rem)">
      <WebOrigoERPSidebar :mainIcon="'isax-people'" :items="sidebarItems" />
      <div
        class="y flex-1 overflow-y-scroll flex-grow 1-grow pb-2 overflow-x-hidden text-black"
        style="background: #f5f5f5"
      >
        <router-view />
        <WebOrigoERPFooter class="bg-white h-auto md:h-28 self-end" />

        <div class="fixed top-12 right-12 w-auto h-auto z-10">
          <transition-group name="toasts" tag="ul">
            <toast
              v-for="toast of toasts"
              v-bind:key="toast.key"
              :type="toast.type"
              :message="toast.message"
            />
          </transition-group>
          <transition name="popup">
            <alert
              v-for="alert of alerts"
              v-bind:key="alert.key"
              :title="alert.title"
              :message="alert.message"
              :onConfirm="() => alert.onConfirm()"
              :onCancel="() => alert.onCancel()"
              :alertKey="alert.key"
            />
          </transition>
        </div>
      </div>
    </div>
    <div
      class="absolute left-0 right-0 top-0 h-1/2 z-0 bg-brand"
      style="
        z-index: -1
        background-repeat: no-repeat;
        background-position-x: initial;
        background-position-y: initial;
      "
      :style="{
        'background-image': 'url(' + require('@/assets/bg-orange.jpg') + ')',
        'background-size': 'cover',
      }"
    ></div>
  </div>
</template>

<script lang="ts">
import { WebOrigoERPNavbar } from "@weborigo/erp-navbar";
import { WebOrigoERPSidebar } from "@weborigo/erp-sidebar";
import { WebOrigoERPFooter } from "@weborigo/erp-footer";
import Toast from "./components/Toast.vue";
import Alert from "./components/Alert.vue";

export default {
  name: "App",
  components: {
    WebOrigoERPNavbar,
    WebOrigoERPSidebar,
    WebOrigoERPFooter,
    Toast,
    Alert,
  },
  metaInfo: {
    title: "Default App Title",
    titleTemplate: "%s | vue-meta Example App",
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/auth/login");
    },
  },
  computed: {
    toasts() {
      return this.$store.state.toasts.toasts;
    },
    alerts() {
      return this.$store.state.alerts.alerts;
    },
    user() {
      return this.$store.state.user;
    },
    userMenuItems() {
      const menu = [];

      if (this.$isA("permission_manager")) {
        menu.push({
          text: "Permissions Manager",
          icon: "isax-user-cirlce-add",
          route: "/permissions",
        });
      }

      return menu;
    },
    sidebarItems() {
      const menu = [];
      if (this.$isA("hr")) {
        menu.push({
          title: "Recruitment Manger",
          icon: "isax-profile-2user",
          route: "/",
        });
      }

      if (this.$isA("scout")) {
        menu.push({
          title: "Scout",
          icon: "isax-map",
          route: "/scout",
        });
      }

      if (this.$isA("ads_manager")) {
        menu.push({
          title: "Ads",
          icon: "isax-global-search",
          route: "/ads",
        });
      }

      if (this.$can("hr-create-email-template")) {
        menu.push({
          title: "Email Templates",
          icon: "isax-direct",
          route: "/email-templates",
        });
      }

      return menu;
    },
  },
};
</script>

<style>
body {
  font-size: 0.875rem;
  overflow: hidden !important;
  overflow-y: hidden !important;
}

#app {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

a:hover {
  color: inherit;
  text-decoration: none;
}
</style>
