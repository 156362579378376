<template>
  <div
    class="transition-all duration-200"
    :class="{ 'w-screen h-screen fixed inset-0  z-modal': !disabled, relative: disabled }"
  >
    <div
      v-if="!disabled"
      @click="close"
      class="popup-backdrop fixed inset-0 bg-white bg-opacity-90 transition-all"
      aria-hidden="true"
    ></div>
    <div
      class="modal-content border-none bg-white rounded-lg y overflow-visible items-center w-full pb-5 transition-all duration-200"
      :style="`width: ${width}; height: ${height}`"
      :class="{
        'absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 shadow-xl':
          !disabled,
      }"
    >
      <i
        class="isax isax-close-circle text-xl absolute top-0 right-5 cursor-pointer"
        @click="close"
      />
      <slot name="default" />
      <div
        class="w-full py-2 px-7 rounded-b-lg border-gray-200 mt-5 x gap-x-3 justify-between md:justify-end"
        :style="{ textAlign }"
        v-if="!hideTooltip && !disabled"
      >
        <slot name="actionButton" />
        <button @click="close" class="px-3 py-2 text-sm rounded-lg border text-black">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: () => "40%",
    },
    height: {
      type: String,
      default: () => "66.66%",
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: String,
      default: "right",
      validator: (value) => ["right", "left", "center"].indexOf(value) > -1,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-content {
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 768px) {
  .modal-content {
    width: 100% !important;
    border-radius: 0 !important;
  }
}
</style>
