<template>
  <div class="relative w-full h-full y items-center justify-center gap-y-5 md:x">
    <div class="absolute top-0 left-0 z-10 w-full md:w-4/12 px-4 md:px-0">
      <div class="w-full h-full y items-start md:items-center gap-y-4 pt-3 md:pt-16">
        <router-link :to="{ name: 'Home' }">
          <div
            class="x gap-x-4 pb-10 text-2xl items-center justify-center cursor-pointer text-gray-400 hover:text-brand"
          >
            <i class="isax isax-arrow-left-3" />
            <span>Go Back</span>
          </div>
        </router-link>
      </div>
    </div>

    <div class="w-full h-full">
      <world-map :data="mapData" v-if="!loading">
        <template #info-window="{ selected }">
          <div class="y">
            <p><b>Country:</b> {{ getCountryFromCode(selected).name }}</p>
            <p><b>Applicants:</b> {{ getCountryFromCode(selected).applicants }}</p>
            <p><b>Employees:</b> 0</p>
            <br />
            <b class="text-orange cursor-pointer">View</b>
          </div>
        </template>
      </world-map>
    </div>
  </div>
</template>

<script>
import WorldMap from "../components/WorldMap.vue";

export default {
  components: {
    WorldMap,
  },
  data() {
    return {
      mapData: [],
      countries: new Map(),
      loading: true,
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    getData() {
      return this.axios.get("/statistics/globe-map").then((resp) => {
        this.mapData = resp.data.data
          .filter((c) => c.applicants > 0)
          .reduce((obj, item) => Object.assign(obj, { [item.code]: item.applicants }), {});
        this.countries = new Map(resp.data.data.map((c) => [c.code, c]));
        this.loading = false;
      });
    },
    getCountryFromCode(code) {
      return this.countries.get(code);
    },
  },
};
</script>
