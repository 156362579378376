<template>
  <div class="y gap-y-2">
    <div class="y md:x gap-2">
      <field-item :label="'Send Email'">
        <template #input>
          <validated-input
            :errors="errors ? errors['email'] : []"
            v-model="value.email"
            @input="(val) => update('email', val)"
            :disabled="loading"
          />
        </template>
      </field-item>

      <field-item>
        <template #input>
          <v-select
            class="selector"
            placeholder="Select a template"
            :disabled="loading"
            :options="emailTemplates"
            label="name"
            v-model="selectedEmailTemplate"
          />
        </template>
      </field-item>
    </div>

    <field-item :label="'CC'">
      <template #input>
        <validated-input
          :errors="errors ? errors['cc'] : []"
          class="w-6/12"
          v-model="value.cc"
          @input="(val) => update('cc', val)"
          :disabled="loading"
        />
      </template>
    </field-item>

    <field-item :label="'Subject'">
      <template #input>
        <validated-input
          :errors="errors ? errors['subject'] : []"
          v-model="value.subject"
          @input="(val) => update('subject', val)"
          class="w-6/12"
          :disabled="loading"
        />
      </template>
    </field-item>

    <field-item :label="'Message'">
      <template #input>
        <rich-text-editor v-model="value.message" />
      </template>
    </field-item>

    <drag-and-drop-file-selector class="bg-white border-none shadow-md" @change="filesSelected">
      <template #default="{ label }">
        <div class="y xl:x gap-y-2 gap-x-2 w-full" style="min-height: 100px">
          <div class="w-full md:w-3/12 h-full y">
            <h1 class="font-bold text-xl">Attachments</h1>
            <p v-if="value.attachments.length > 0">
              {{ value.attachments.length }} Files . {{ totalSize }}
            </p>
            <label :for="label">
              <div
                class="x justify-center md:justify-start items-center gap-x-1 pt-3 cursor-pointer"
                style="color: #253dd9"
              >
                <i class="isax isax-export text-lg" />
                <span>Upload more files</span>
              </div>
            </label>
          </div>
          <div class="flex-1 x gap-2 flex-wrap h-full">
            <attachment
              class="w-64"
              v-for="(file, index) of value.attachments"
              :key="index"
              :title="file.name"
              :bytes="file.size"
              @delete="
                update(
                  'attachments',
                  value.attachments.filter((v, i) => {
                    return index !== i;
                  }),
                )
              "
            />
          </div>
        </div>
      </template>
    </drag-and-drop-file-selector>
  </div>
</template>

<script>
import * as Validator from "validatorjs";
import { mapActions, mapState } from "vuex";
import utils from "../utils/utils";
import FieldItem from "./FieldItem.vue";
import ValidatedInput from "./ValidatedInput.vue";
import DragAndDropFileSelector from "./DragAndDropFileSelector.vue";
import Attachment from "./Attachment.vue";
import RichTextEditor from "./RichTextEditor.vue";

export default {
  components: {
    FieldItem,
    ValidatedInput,
    DragAndDropFileSelector,
    Attachment,
    RichTextEditor,
  },
  props: {
    value: Object,
    loading: Boolean,
  },
  data() {
    return {
      errors: null,
      validator: null,
      selectedEmailTemplate: null,
    };
  },
  mounted() {
    if (this.emailTemplates.length < 1) {
      this.fetchEmailTemplates();
    }
  },
  methods: {
    update(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    async validate() {
      this.validator = new Validator(this.value, {
        email: "required|string|email",
        cc: "string|email",
        subject: "required|string",
        message: "required|string",
      });

      if (this.validator.passes()) {
        this.errors = null;
      } else this.errors = this.validator.errors.all();

      return this.validator.passes();
    },
    filesSelected(files) {
      this.update("attachments", [...this.value.attachments, ...files]);
    },
    setEmailTemplateValues() {
      const keys = ["message", "subject", "to", "cc"];

      keys.forEach((key) => {
        const value = this.selectedEmailTemplate[key];
        if (value) {
          const valueKey = key === "to" ? "email" : key;
          console.log(valueKey, value);
          this.value[valueKey] = value;
          this.update(valueKey, value);
        }
      });

      const { attachments } = this.selectedEmailTemplate;
      if (attachments.length > 0) {
        this.value.attachments.push(...attachments);
        this.update("attachments", this.value.attachments);
      }
    },
    ...mapActions("data", ["fetchEmailTemplates"]),
  },
  computed: {
    totalSize() {
      const bytes = this.value.attachments.reduce(function (a, b) {
        return a + b.size;
      }, 0);
      return utils.getSize(bytes);
    },
    ...mapState("data", ["emailTemplates"]),
  },
  watch: {
    selectedEmailTemplate: {
      handler(val) {
        if (val) {
          this.setEmailTemplateValues();
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
